'use client';

import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom'; // Import useLocation
import {ReactComponent as Logo} from './logo.svg';
export const navigation = [
  { name: 'Onze kernwaarden', href: '/kernwaarden' },
  { name: 'Wie we zijn', href: '/wie-we-zijn' },
  { name: 'Diensten', href: '/diensten' },
  { name: 'Onderzoek', href: '/onderzoek' },
  {name: 'Werken bij', href: '/vacatures'},
  { name: 'Contact', href: '/contact' },
];

const mobileNavigation = [
    {name: 'Triple Control', href: '/'}, ...navigation
];

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation(); // Get the current path

  return (
    <header className="bg-white flex-none py-3">
      <div className="container">
      <nav aria-label="Global" className="flex items-center justify-between py-3">
        <div className="flex gap justify-center md:justify-between">
          <a className="brand block w-[195px]" href="/" title="Triple Control">
            <Logo/>
          </a>
        </div>
        <div className="flex custom:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden custom:flex custom:gap-x-12 mt-[9px]">
          {navigation.map((item) => (
            item.name === 'Contact' ? (
              <a
                key={item.name}
                href={item.href}
                className="hidden py-2 px-2 md:inline-block font-medium rounded hover:shadow text-white bg-ocean-800 hover:bg-ocean-900 flex items-center"
              >
                {item.name === "Contact" ? "Kennismaken?" : item.name}
              </a>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className={`text leading-6 ${
                  pathname === item.href || pathname.includes(item.href) || (pathname.includes('c-hub') && item.name === 'Diensten')  ? 'text-forest-500' : 'text-gray-700'
                } hover:text-forest-500 flex items-center font-medium`}
              >
                {item.name}
              </a>
            )
          ))}
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="custom:hidden">
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {mobileNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50 ${
                      pathname === item.href ? 'font-bold' : ''
                    }`}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      </div>
    </header>
  );
}
