import React from 'react';
import PageIntro from '../PageIntro';
import QuoteQuestionBlock from '../home/QuoteQuestionBlock';
function Advies() {
    return (
        <main>
            <PageIntro
                title="GRC advies"
                subtitle="Onze mensen helpen je verder"
                breadcrumbs={[
                    { href: "/diensten", title: "Diensten" },
                    { href: "/diensten/advies", title: "GRC advies" }
                ]}
            />
        <div className="container">
            <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 md:pt-12 pt-8">
                De business draaiende houden, voldoen aan geldende wet- en regelgeving, en tegelijkertijd je risico's moeten beheersen en goede governance waarborgen. Dat is geen eenvoudige opgave.
                </p>
                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">
                Heb je te maken met vragen als:
                </h3>
                <ol className="list-decimal list-inside pl-4">
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                        Hoe zorgen we ervoor dat we voldoen aan de geldende regelgeving zonder dat dit ons werk vertraagt?
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                        Hoe beheersen we risico's zonder dat we elke dag brandjes moeten blussen?
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                        Hoe zetten we een governance-structuur op die aansluit bij onze bedrijfsdoelen en niet aanvoelt als een bureaucratische laag?
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                        Hoe kunnen we compliance, risk en governance pragmatisch en werkbaar maken zonder dat dit ons innovatievermogen of de klantgerichtheid schaadt?
                    </li>
                </ol>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 md:pt-12 pt-8">
                Of je nu worstelt met één of meerdere van deze vragen, wij staan klaar om samen te kijken naar wat er nodig is om jouw organisatie in control te krijgen en te houden.
                </p>
                <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Waarom Triple Control?</h3>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                Het terrein van <em>compliance</em>, <em>risicomanagement</em> en <em>governance</em> lijkt soms een mijnenveld. De regels en richtlijnen zijn helder, maar de uitdaging zit hem in de vertaling naar de praktijk. Het simpelweg implementeren van standaardoplossingen leidt vaak tot zenuwachtige medewerkers, stroperige processen of onnodig hoge kosten. Wij helpen je om:
                </p>
                <ul className="pl-4">
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">De regelgeving en normen te vertalen naar een werkbare aanpak. We zorgen ervoor dat compliance niet iets is waar je tegenaan hikt, maar een logisch onderdeel van je dagelijkse processen.</span>
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">Risico's te beheersen met een systematische, maar pragmatische aanpak. Wij geloven niet in oplossingen die jouw organisatie onnodig verzwaren. We vermijden daarom overbeveiliging of overdreven risicomijdend gedrag. In plaats daarvan richten we ons op wat er écht toe doet.</span>
                    </li>
                    <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
                        <i className="fa-solid fa-arrow-right-long"></i> <span className="pl-4">Een governance-structuur te ontwikkelen die bijdraagt aan jouw doelen. Een goede governance betekent niet meer regels, maar duidelijke afspraken, overzicht en verantwoordelijkheid. In plaats van regels blindelings op te volgen, gaan we uit van wat wérkt voor jouw organisatie.</span>
                    </li>
                </ul>
                <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 md:pt-12 pt-8">
                Onze kracht ligt in het begrijpen van de unieke uitdagingen van verschillende sectoren. Of je nu een startup bent die snel wil groeien of een gevestigde organisatie die grip wil houden op steeds complexere eisen: samen ontdekken we hoe we je verder kunnen helpen.
                </p>
                <ol className="list list-inside pl-4">
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500">Risicomanagement</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Informatiebeveiliging</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Audits</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Management review</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Compliance</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Governance</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Privacy</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Operational Excellence</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">Onderzoek en analyse</li>
                    <li className="leading-6 md:leading-9 md:text-lg text-blue-500 ">ISO 27001, 9001, 18295</li>
                </ol>
            </div>

        </div>
        <QuoteQuestionBlock title="We maken het niet ingewikkelder dan het is"  quote={'"80% van in control zijn, is opschrijven wat je doet en doen wat je opschrijft."'} author="Bas de Kwant" authorImage="/images/photo-bas-de-kwant.jpg" bg="ocean" ctaText='Kennismaken' />
        </main>
    );
}

export default Advies;