import React from 'react';
import PageIntro from '../PageIntro';
import QuoteQuestionBlock from '../home/QuoteQuestionBlock';
function Workshops() {
    return (
        <main className="flex-grow">
        <PageIntro
            title="Workshops"
            subtitle="Diensten"
            breadcrumbs={[
                { href: "/diensten", title: "Diensten" },
                { href: "/diensten/workshops", title: "Workshops" }
            ]}
        />
        <div className="container">
            <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">
            <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">
            Workshops over governance, management reviews, de rol van de security officer, risico management, risico ownership, secure development, security en privacy by design
            </h3>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
          We vinden het belangrijk dat een workshop niet een uurtje lekker achterover leunen is.
          Daarom verkennen we in een intake altijd de context van jouw organisatie. We zijn bedreven
          in ons inleven wat voor jou relevant is, wat voor jou speelt. Een workshop maken we daardoor
          altijd interactief, toegepast en met voorbeelden uit jouw praktijk in plaats van een theoretische
          benadering uit een boekje.
          </p>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
            Onze workshops kunnen voor verschillend publiek: directies, (lijn) management, een IT-team, software developers, de afdeling informatiebeveiliging & privacy, de gemeenteraad, projectleiders, enzoverder. We bespreken altijd wat het huidige uitgangsniveau is. Dan kunnen we de invulling zo doen dat er voor iedereen iets zinvols te leren is. Ons doel: de blik van alle aanwezigen verscherpen en ervoor zorgen dat nog lang na de workshop de deelnemers zichzelf en hun collega's de juiste vragen gaan stellen.
          </p>
            <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
            Een workshop duurt tussen de 3 en 4 uur. Alle workshops zijn altijd op (uw) locatie. Voor de meeste workshops hebben we logische follow-up workshops voor verdere verdieping.
            </p>


          <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-4">
            Security & risk managment gaat beter als je het juiste gesprek voert, wil je leren hoe je dat in jouw organisatie doet?
            </h3>
            <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
          Workshop onderwerpen kunnen (combinaties van onder meer) de volgende onderwerpen zijn:
          </p>
            <ul className="list-decimal list-inside pl-4">
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                <strong>Gezamenlijke nulmeting volwassenheidsniveau.</strong> Waar staan we vandaag? <br/>
                Hoeveel ruimte zit er tussen theorie en praktijk? Wat is de kwaliteit van de naleving?<br/>
                Is ons beleid actueel? Zouden we blij zijn als we deze regels zouden kunnen volgen? <br/>
                Is ons beleid proportioneel?
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                <strong>De management review.</strong> Hoe kunnen we die verbeteren? Welke informatie moet het management eigenlijk krijgen? Hoe kan de security office dat gaan regelen? Is er meer uit de huidige situatie te halen? Kunnen we van deze review een echt stuur instrument maken om continue te verbeteren?
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                    <strong>Risico ownership.</strong> Tactieken om risico owners te activeren, een zinvolle dialoog met ze aan te gaan. We leren hoe je ervoor kunt zorgen dat risico owners die dicht bij het vuur zitten enorm veel waarde kunnen toevoegen als je helder communiceert wat je van elkaar verwacht. Tools om risico owners in hun kracht te zetten.
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                    <strong>Incident is manifest risico.</strong> Wat gebeurt er in jouw organisatie als het incident is gesloten? Sluit je incident management op je risico management aan? Leren terugkijken wat een incident nou eigenlijk zegt over je beleid, processen en controls. Dit structureel in je incident proces meenemen heeft een enorm rendement.
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                    <strong>Secure development, security en privacy by design.</strong> We hebben experts in software security die jarenlang op het hoogste security niveau software hebben ontwikkeld en onderhouden.
                </li>
            </ul>
          <a href="/contact" className="py-3 sm:mt-4  px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">
              Plan een workshop
            </a>
            </div>
        </div>
        <QuoteQuestionBlock
        title="Risico management toegankelijk maken"
        quote={"Het is eindeloos makkelijker om je collega's voldoende te leren over risico management, dan dat het is om zodanig veel begrip te krijgen over alles wat iedereen doet dat je in je eentje zinvol de risico's kunt beheersen."}
        author="Bas de Kwant"
        cta={false}
        authorImage={true}
        />
     </main>
    );
}

export default Workshops;