import React from 'react';
import PageIntro from './PageIntro';
import Layers from './home/Layers';
function WerkenBij() {
  return (
    <main className="flex-grow">
      <PageIntro title="Toe aan een nieuwe stap?"
      subtitle="Kom ons team versterken"/>
      <div className="container">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4 pt-8">
            Bij Triple Control geloven we in de kracht van samenwerken en het delen van kennis en mindset om organisaties naar een hoger niveau te tillen.Bij ons draait het niet alleen om het naleven van normen, maar om het actief bijdragen aan groei en succes door middel van effectieve informatiebeveiliging en risicomanagement. Wij geloven dat grip op risico's en informatie hand in hand gaat met de bloei van organisaties. Onze missie is om informatiebeveiliging, compliance en risicomanagement begrijpelijk, flexibel en waardevol te maken, en dat zien we bij onze opdrachtgevers enorm renderen!
          </p>
          <p className="md:text-lg leading-6 md:leading-9 text-gray-600 py-4">
            Bij Triple Control zijn we niet bang om direct te zijn, we zijn eerlijk zonder bot te zijn, en we geloven in een bottom-up aanpak waarbij het begrijpen van de business value centraal staat. Dit is wat we zoeken in onze mensen, maar ook wat we ze continue leren.
          </p>
          <p className="md:text-lg leading-6 md:leading-9 text-gray-600 py-4">
            Triple Control is een jonge, platte organisatie die volop aan het groeien is. We zoeken mensen die zich aangesproken voelen door deze dynamiek en willen bijdragen aan onze groei, waarbij jouw carrière alle kanten op kan gaan, zover je zelf wilt. Bij ons krijg je de vrijheid om te ontdekken, te ondernemen en echt mee te bouwen aan iets groots. We zijn flexibel, werken vanuit vertrouwen, en vinden de juiste balans tussen samenwerken en zelfstandigheid. Bouw je met ons mee?
          </p>
        </div>
        <h3 className="text-2xl font-medium text-grey-600 pb-4">Open posities</h3>
        <i class="fa-solid fa-caret-right pr-2"> </i><a href="/vacatures/consultant" className="text-forest-500 hover:text-forest-600 font-medium">Consultant</a>
      </div>
      <div className="pb-12 md:pb-20">
      <Layers />
      </div>
    </main>
  )
};

export default WerkenBij;
