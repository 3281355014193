import React from 'react';
import PageIntro from '../PageIntro';

function BioNis2() {
    return (
        <main className="flex-grow">
        <PageIntro
            title="BIO / NIS 2 implementatie"
            subtitle="Samen voor een veilige overheid"
            breadcrumbs={[
                { href: "/diensten", title: "Diensten" },
                { href: "/diensten/bio-nis2", title: "BIO / NIS 2 implementatie" }
            ]}
            subText="Sta je voor de uitdaging om NIS2 of BIO te implementeren? Of om eraan te blijven voldoen? Triple Control staat klaar om je organisatie hierin te ondersteunen."
        />
         <div className="container">
        <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">
            <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Aantoonbaar in control over je informatie, assets en processen</h3>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">Het behalen van NIS2 of BIO-compliance is in de basis een kwestie van voldoen aan de norm. De richtlijnen vertellen je duidelijk wat er moet gebeuren, maar je wilt het ook zo effectief mogelijk doen. Alles dichttimmeren is makkelijk. Maar risicomanagement naadloos integreren in de cultuur van jouw organisatie, zonder dat de werkbaarheid verloren gaat? Dat is onze passie. We begrijpen het spanningsveld tussen het volledig dichtschroeven van beveiliging en het bieden van voldoende flexibiliteit. Daarnaast spreken we de taal van beleidsmakers, IT'ers, juristen én de werkvloer. Het verbinden van deze werelden is cruciaal om NIS2 succesvol te implementeren. Dat is precies waar wij het verschil maken voor onze overheidsklanten.
          </p>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">Zit je met één of meerdere van deze vragen?</p>
          <ol className="list-decimal list-inside pl-4">
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Hoe zorgen we ervoor dat we voldoen aan de NIS2-richtlijn? We staan nog aan basis!
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Hoe ontwerpen we een security framework dat niet het innovatieve of dienstverlenende karakter van onze organisatie verstoort?
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Hoe zorgen we ervoor dat de NIS2-vereisten niet leiden tot een afname van werkplezier of een te strikte cultuur?
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Hoe creëren we een wendbare organisatie die wendbaar en flexibel blijft, zonder dat we continu 'nee' moeten zeggen vanuit compliance-oogpunt?
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Hoe organiseren we onze securitystructuur zó dat het werkt voor de (overheids)organisatie van nu én morgen?
            </li>
          </ol>
          <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">Samen beantwoorden we al deze vragen</h3>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">Onze geloofsregels:</p>
          <ol className="list-decimal list-inside pl-4">
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Innovatie en security zijn geen natuurlijke vijanden. Integendeel, ze kunnen elkaar juist versterken.</li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">Er is een manier om je security/privacy officer, klant, burger, auditor, medewerker en stakeholder blij te maken met één soort gedrag. Het draait om het creëren van een cultuur die iedereen omarmt.</li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">De juiste cultuur komt voort uit de juiste structuur. Information security is niet alleen een vereiste, het kan je nieuwe operational excellence zijn.</li>
            <div className="mt-8">
            <a href="/kernwaarden" className="py-3 px-6 inline-block font-bold rounded hover:shadow text-gray-700 bg-slate-200 hover:bg-slate-300">
              Meer over de kernwaarden
            </a>
            <a href="/contact" className="py-3 sm:mt-4 md:mx-4 px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">
              Neem contact op over BIO / NIS2
            </a>
          </div>
          </ol>
          </div>
          </div>
        </main>
    );
}

export default BioNis2;