import React from 'react'

function VisonBlocks() {
  return (
    <section className="home-content-blocks py-12 md:py-20">
      <div className="container">
        <div className="grid gap-3 md:grid-cols-3 md:gap-x-[160px] gap-y-16 md:gap-y-[85px]">
          <div className="flex flex-col text-left relative">
            <div className="text-6xl font-medium text-gray-600 opacity-50 mb-4 text-left md:text-center">
              1.
            </div>
            <h2 className="text-blue-500 font-medium text-lg md:text-2xl relative z-10">
              Innovatie en compliance zijn niet elkaars vijanden
            </h2>
            <p className='pt-2 leading-6 md:leading-9 md:text-lg text-gray-600 relative z-10'>
              Het dilemma een werkbare wereld of een veilige wereld is vals.
            </p>
          </div>

          <div className="flex flex-col text-left relative">
            <div className="text-6xl font-medium text-gray-600 opacity-50 mb-4 text-left md:text-center">
              2.
            </div>
            <h2 className="text-blue-500 font-medium text-lg md:text-2xl relative z-10">
              Het kan lekker werken voor iedereen en veilig zijn
            </h2>
            <p className='pt-2 leading-6 md:leading-9 md:text-lg text-gray-600 relative z-10'>
              Er is een manier om je security/privacy officer, klant, auditor, medewerker, etc. blij te maken met een hetzelfde gedrag.
            </p>
          </div>

          <div className="flex flex-col text-left relative">
            <div className="text-6xl font-medium text-gray-600 opacity-50 mb-4 text-left md:text-center">
              3.
            </div>
            <h2 className="text-blue-500 font-medium text-lg md:text-2xl relative z-10">
              Een gezonde cultuur komt voort uit de juiste structuur
            </h2>

            <p className='pt-2 leading-6 md:leading-9 md:text-lg text-gray-600 relative z-10'>
              Met de Triple Control methode bouw je structureel aan risico management als een gezamelijke, gedragen verantwoordelijkheid.
            </p>
            <p className='pt-2 leading-6 md:leading-9 md:text-lg text-gray-600 relative z-10'>
              Cultuur verandert niet doordat je een powerpoint hebt bijgewoond.
            </p>
          </div>



        </div>
      </div>
    </section>
  );
}

export default VisonBlocks;
