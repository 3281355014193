import React from 'react';
import PageIntro from './PageIntro';
import PodcastComponent from './PodcastComponent';
import QuoteQuestionBlock from './home/QuoteQuestionBlock';
import ServicesComponent from './diensten/ServicesComponent';
import Layers from  './home/Layers';

function Diensten() {
  return (
    <main>
      <PageIntro title="Triple Control"
      subtitle="Partner op de Road to Control&trade; van jouw organisatie"
      breadcrumbs={[
        { href: "/diensten", title: "Diensten" }
      ]}
      />
      <div className="hidden md:block">
        <Layers cta={false} title="Uit de juiste structuur volgt de juiste cultuur" />
      </div>
      <div className="py-8 md:py-12">
      <ServicesComponent />
      </div>
      <PodcastComponent photo="/images/photo-bas-de-kwant.jpg" />
      <QuoteQuestionBlock
        title="Risico management toegankelijk maken"
        quote={"Het is eindeloos makkelijker om je collega's voldoende te leren over risico management, dan dat het is om zodanig veel begrip te krijgen over alles wat iedereen doet dat je in je eentje zinvol de risico's kunt beheersen."}
        author="Bas de Kwant"
        authorImage={false}
        cta={false}
        />
    </main>
  )
};

export default Diensten;