import React from 'react';
import { InlineWidget } from "react-calendly";
import PageIntro from './PageIntro';

function Contact() {
  return (
    <main>
      <div class="flex flex-col min-h-screen">
      <div className="flex-grow">
      <PageIntro title="Contact"
      subtitle="We maken graag kennis met jouw organisatie"
      subText="Plan een tijdslot voor een introductiegesprek via MS Teams."/>

      <section className="block">
        <div className="container">
          <div className="flex justify-left pt-12 lg:pt-20">
            <InlineWidget
              url="https://calendly.com/bdk-triplec/60min?hide_event_type_details=1&hide_gdpr_banner=1"
              styles={{
                height: '600px',
                overflow: 'hidden',
                paddingTop: '0px',
                marginTop: '0px',
              }}
            />
          </div>
        </div>
        </section>
        </div>
     <section className="bg-ocean-50 h-full py-12 md:py-16 flex-grow min-h-[30vh]">
        <div className="container h-full">
        <h2 className="text-3xl md:text-4xl font-black mb-6">Liever direct contact?</h2>
          <p className="text-lg">
            Bel ons tijdens kantooruren op <a href="tel:+31858200903" className="text-forest-500 hover:text-forest-600 font-medium">+31 (0)85—820 09 03</a><br />
            Mail ons op <a href="mailto:info@triple-control.com" className="text-forest-500 hover:text-forest-600 font-medium">info@triple-control.com</a><br />

          </p>
        </div>
      </section>
      </div>
    </main>
  );
};

export default Contact;
