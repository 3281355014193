import React from 'react';

function Layers({cta = true, title = 'The road to control'}) {
  console.log("The value of cta is:");
  console.log(cta);
  return (
    <section className="pt-12 md:pt-20">
    <div className="container">
      <div className="grid md:grid-cols-2 items-center">
        <div>
          <img className="w-10/12 mx-auto" src="/images/graphic-stack.svg" alt="Triple Control - Graphic Stack" />
        </div>

        <div className="flex flex-col gap-12 text-left">
          <h3 className="font-black text-2xl title-with-break" dangerouslySetInnerHTML={{ __html: title }}></h3>

          <div class="flex flex-col gap-4 text-lg">
            <ul>
              <li className="flex gap-4 py-1"><i className="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>Opschrijven wat je doet</li>
              <li className="flex gap-4 py-1"><i className="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>Maak het risico gebaseerd (een duidelijk waarom voor de regels)</li>
              <li className="flex gap-4 py-1"><i className="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>De wereld en je organisatie staan niet stil, organiseer systematisch aandacht om ontwikkelingen te managen</li>
              <li className="flex gap-4 py-1"><i className="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>365/365 klaar voor een audit, alle stakeholders tevreden, jouw wereld accuraat in beeld.</li>
            </ul>
          </div>
            {cta && (
              <div>
                <a href="/contact" className="py-3 px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">Contact</a>
              </div>
            )}


        </div>
      </div>
    </div>
  </section>
  );
}

export default Layers
