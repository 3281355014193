import React from 'react';

function ControlCanvas() {
    return (
        <section class="bg-ocean-900 py-12 md:py-20 mb-10 lg:mb-20">
        <div className="container">
          <div className="grid md:grid-cols-2">
            <div className="md:order-2">
              <img className="w-10/12 mx-auto" src="/images/graphic-squares.svg" alt="Triple Control - Graphic Squares" />
            </div>

            <div className="flex flex-col gap-12 text-left">
              <h3 className="text-white title-with-break font-medium text-xl md:text-2xl">
                <strong>Bepalen waar je staat is makkelijker dan je denkt</strong>
                <br/>
                Doe het volgende gedachte-experiment
              </h3>

              <div className="text-white flex flex-col gap-4 text-lg">
                <p><strong>Vraag je (bijvoorbeeld over je omgang met wachtwoorden) af</strong></p>
                <ul>
                  <li class="flex gap-4 py-1"><i class="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>Als het altijd zo zou gaan, ben ik dan blij?</li>
                  <li class="flex gap-4 py-1"><i class="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>Gaat het zoals we hebben opgeschreven?</li>
                  <li class="flex gap-4 py-1"><i class="fa-sharp fa-solid fa-check text-corn-400" aria-hidden="true"></i>Kortom: heb ik goed beleid en volgen we dat?</li>
                </ul>
                <p>En als het antwoord nee is, of je twijfelt, waarom eigenlijk?</p>
              </div>
              <div>
              <a href="/contact" className="py-3 px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">Hier met ons over sparren?</a>
            </div>

            </div>
          </div>
        </div>
      </section>
    );
}

  export default ControlCanvas;