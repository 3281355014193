import React from 'react';
import PageIntro from './PageIntro';

function AlgemeneVoorwaarden() {
  return (
    <main className="flex-grow">
      <PageIntro title="Algemene voorwaarden" />
    <div className="container">
    <div className="mx-auto max-w-4xl lg:mx-0 py-12 lg:pb-24">
      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 1 - Definities</h2>
      <p className="leading-6 text-gray-600">
        1. De Kwant Consultants B.V. een naar Nederlands recht opgerichte besloten vennootschap met beperkte aansprakelijkheid gevestigd te Oranjewoud, onder KvK-nummer 92060137, opererend onder maar niet beperkt tot de handelsnaam Triple Control, wordt in deze algemene voorwaarden aangeduid als Dienstverlener.
      </p>
      <p className="leading-6 text-gray-600">
        2. De wederpartij van Dienstverlener wordt in deze algemene voorwaarden aangeduid als Opdrachtgever.
      </p>
      <p className="leading-6 text-gray-600">
        3. Partijen zijn Dienstverlener en Opdrachtgever samen.
      </p>
      <p className="leading-6 text-gray-600">
        4. Met de Overeenkomst wordt bedoeld de algemene voorwaarden, de Opdrachtbevestiging en de Overeenkomst tot dienstverlening tussen partijen tezamen met eventuele andere documenten (de “Aanvullende Voorwaarden”) die op de verhouding tussen Dienstverlener en Opdrachtgever van toepassing zijn en waar nadrukkelijk in de Opdrachtbevestiging naar wordt verwezen, waaronder maar niet beperkt de Overeenkomst van Opdracht.
      </p>
      <p className="leading-6 text-gray-600">
        5. Onder Diensten worden verstaan de ingevolge de Overeenkomst voor de Opdrachtgever te verrichten werkzaamheden en/of te verlenen diensten door De Kwant Consultants B.V.
      </p>
      <p className="leading-6 text-gray-600">
        6. Onder Opdrachtbevestiging wordt verstaan een mondelinge, schriftelijke dan wel elektronische bevestiging waarin de Diensten zijn omschreven al dan niet zoals nader omschreven in een Overeenkomst van Opdracht tussen partijen.
      </p>
      <p className="leading-6 text-gray-600">
        7. Onder Overeenkomst van Opdracht wordt het document verstaan waarin de te verrichten Diensten en geldende tarieven tussen partijen eventueel nader zijn omschreven.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 2 - Toepasselijkheid algemene voorwaarden</h2>
      <p className="leading-6 text-gray-600">
        1. Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen, werkzaamheden, overeenkomsten en leveringen van diensten of goederen door of namens Dienstverlener.
      </p>
      <p className="leading-6 text-gray-600">
        2. Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én schriftelijk door partijen is overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        3. De overeenkomst bevat voor Dienstverlener steeds inspanningsverplichtingen, geen resultaatsverplichtingen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 3 - Betaling</h2>
      <p className="leading-6 text-gray-600">
        1. Facturen moeten binnen 30 dagen na factuurdatum betaald zijn, tenzij partijen hierover schriftelijk andere afspraken hebben gemaakt of op de declaratie een andere betaaltermijn vermeld is.
      </p>
      <p className="leading-6 text-gray-600">
        2. Betalingen geschieden zonder enig beroep op opschorting of verrekening door overschrijving van het verschuldigde bedrag op het door Dienstverlener opgegeven bankrekeningnummer.
      </p>
      <p className="leading-6 text-gray-600">
        3. Betaalt Opdrachtgever een factuur niet binnen de overeengekomen termijn, dan is hij van rechtswege, zonder dat daarvoor enige aanmaning nodig is, in verzuim. Vanaf dat moment is Dienstverlener gerechtigd de verplichtingen op te schorten totdat Opdrachtgever aan zijn betalingsverplichtingen heeft voldaan.
      </p>
      <p className="leading-6 text-gray-600">
        4. Blijft Opdrachtgever in gebreke, dan zal Dienstverlener tot invordering overgaan. De kosten met betrekking tot die invordering komen voor rekening van de Opdrachtgever. Wanneer Opdrachtgever in verzuim is, is hij naast de hoofdsom tevens wettelijke (handels) rente, buitengerechtelijke incassokosten en overige schade verschuldigd aan Dienstverlener. De incassokosten worden berekend aan de hand van het Besluit vergoeding voor buitengerechtelijke incassokosten.
      </p>
      <p className="leading-6 text-gray-600">
        5. In geval van liquidatie, faillissement, beslag of surseance van betaling van de Opdrachtgever zijn de vorderingen van Dienstverlener op de Opdrachtgever onmiddellijk opeisbaar.
      </p>
      <p className="leading-6 text-gray-600">
        6. Weigert Opdrachtgever zijn medewerking aan de uitvoering van de opdracht door Dienstverlener, dan is hij nog steeds verplicht de afgesproken prijs aan Dienstverlener te betalen.
      </p>
      <p className="leading-6 text-gray-600">
        7. Niet-betaling van enige factuur op de vervaldag heeft tevens de onmiddellijke opeisbaarheid van alle andere eventueel op Opdrachtgever bestaande vorderingen ten gevolge.
      </p>
      <p className="leading-6 text-gray-600">
        8. Indien de financiële positie of het betalingsgedrag van Opdrachtgever daartoe aanleiding geeft, is Dienstverlener gerechtigd te verlangen dat Opdrachtgever geheel of gedeeltelijk vooruit betaalt en/of (aanvullende) zekerheid stelt in een door Dienstverlener te bepalen vorm. Indien Opdrachtgever nalaat uitvoering te geven aan het in de eerste volzin bepaalde, is Dienstverlener gerechtigd al haar werkzaamheden ten behoeve van Opdrachtgever per onmiddellijke ingang op te schorten, zonder daarmee op enige wijze jegens Opdrachtgever schadeplichtig te worden.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 4 - Aanbiedingen en offertes</h2>
      <p className="leading-6 text-gray-600">
        1. De aanbiedingen van Dienstverlener zijn hooguit 30 dagen geldig, tenzij in het aanbod een andere termijn van aanvaarding is genoemd. Wordt het aanbod niet binnen die gestelde termijn aanvaard, dan vervalt het aanbod.
      </p>
      <p className="leading-6 text-gray-600">
        2. Levertijden in offertes zijn indicatief en geven de Opdrachtgever bij overschrijding daarvan geen recht op ontbinding of schadevergoeding, tenzij partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        3. Aanbiedingen en offertes gelden niet automatisch voor nabestellingen. Partijen moeten dit uitdrukkelijk én schriftelijk overeenkomen.
      </p>
      <p className="leading-6 text-gray-600">
        4. Toezending van opdrachtaanbiedingen en/of andere documentatie door een opdrachtgever verplicht Dienstverlener niet tot acceptatie van de concrete opdracht, ook niet indien een dergelijke toezending geschiedt binnen het kader van een eerder afgesloten overeenkomst tussen Dienstverlener en Opdrachtgever. Niet-acceptatie van de opdracht wordt door Dienstverlener zo spoedig mogelijk aan de opdrachtgever medegedeeld.
      </p>
      <p className="leading-6 text-gray-600">
        5. Ingeval van strijdigheid tussen de algemene voorwaarden en de Overeenkomst van Opdracht en de eventuele Aanvullende Voorwaarden prevaleren de Overeenkomst van Opdracht en Aanvullende Voorwaarden.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 5 - Prijzen</h2>
      <p className="leading-6 text-gray-600">
        1. De Diensten worden uitgevoerd tegen de in de Opdrachtbevestiging, Overeenkomst en/of Overeenkomst van Opdracht genoemde tarieven en/of honorarium.
      </p>
      <p className="leading-6 text-gray-600">
        2. De op aanbiedingen, offertes en facturen van Dienstverlener genoemde prijzen zijn exclusief btw en eventuele andere overheidsheffingen, tenzij uitdrukkelijk anders vermeld.
      </p>
      <p className="leading-6 text-gray-600">
        3. De prijzen van goederen zijn gebaseerd op de op dat moment bekend zijnde kostprijzen. Verhogingen hiervan, die door de Dienstverlener niet konden worden voorzien ten tijde van het doen van de aanbieding c.q. het tot stand komen van de overeenkomst, kunnen aanleiding geven tot prijsverhogingen.
      </p>
      <p className="leading-6 text-gray-600">
        4. Ten aanzien van de dienstverlening kunnen partijen bij de totstandkoming van de overeenkomst een vaste prijs overeenkomen.
      </p>
      <p className="leading-6 text-gray-600">
        5. Indien er geen vaste prijs is overeengekomen, kan het tarief met betrekking tot de dienstverlening worden vastgesteld op grond van de werkelijk bestede uren. Het tarief wordt berekend volgens de gebruikelijke uurtarieven van Dienstverlener, geldend voor de periode waarin hij de werkzaamheden verricht, tenzij een daarvan afwijkend uurtarief is overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        6. Indien geen tarief op grond van de werkelijk bestede uren is afgesproken, wordt voor de dienstverlening een richtprijs afgesproken, waarbij Dienstverlener gerechtigd is om hier tot 10% vanaf te afwijken. Indien de richtprijs meer dan 10% hoger uit gaat vallen, dient Dienstverlener Opdrachtgever tijdig te laten weten waarom een hogere prijs gerechtvaardigd is. Opdrachtgever heeft in dat geval het recht om een deel van de opdracht te laten vervallen, dat boven de richtprijs vermeerderd met 10% uitkomt.
      </p>
      <p className="leading-6 text-gray-600">
        7. Indien een honorarium van toepassing is, is het door Opdrachtgever verschuldigde honorarium niet afhankelijk van de uitkomst van de Diensten. Door Dienstverlener aan Opdrachtgever verstrekte kostenbegrotingen en geschatte uren zijn slechts indicatief en vrijblijvend. Het door Dienstverlener gefactureerde bedrag kan afwijken van eerdere begrotingen, schattingen en offertes.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 6 - Prijsindexering</h2>
      <p className="leading-6 text-gray-600">
        1. De bij het aangaan van de overeenkomst overeengekomen prijzen en uurlonen zijn gebaseerd op het op dat moment gehanteerde prijspeil. Dienstverlener heeft het recht de aan Opdrachtgever te berekenen vergoedingen die op uur in plaats van projectbasis zijn jaarlijks per 1 januari aan te passen aan het Consumentenprijsindex (CPI).
      </p>
      <p className="leading-6 text-gray-600">
        2. Aangepaste prijzen, tarieven en uurlonen worden zo spoedig mogelijk medegedeeld aan Opdrachtgever.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 7 - Verplichtingen Opdrachtgever</h2>
      <p className="leading-6 text-gray-600">
        1. Opdrachtgever stelt alle informatie die voor de uitvoering van de opdracht relevant is beschikbaar voor Dienstverlener.
      </p>
      <p className="leading-6 text-gray-600">
        2. Opdrachtgever is verplicht alle gegevens en bescheiden, die Dienstverlener meent nodig te hebben voor het correct uitvoeren van de opdracht, tijdig en in de gewenste vorm en op de gewenste wijze ter beschikking te stellen.
      </p>
      <p className="leading-6 text-gray-600">
        3. Opdrachtgever staat in voor de juistheid, volledigheid en betrouwbaarheid van de aan Dienstverlener ter beschikking gestelde gegevens en bescheiden, ook indien deze van derden afkomstig zijn, voor zover uit de aard van de opdracht niet anders voortvloeit.
      </p>
      <p className="leading-6 text-gray-600">
        4. Opdrachtgever vrijwaart Dienstverlener voor elke schade in welke vorm dan ook die voortvloeit uit het niet voldoen aan het in het eerste lid van dit artikel gestelde.
      </p>
      <p className="leading-6 text-gray-600">
        5. Indien en voor zover Opdrachtgever dit verzoekt, retourneert Dienstverlener de betreffende bescheiden.
      </p>
      <p className="leading-6 text-gray-600">
        6. Stelt Opdrachtgever niet, niet tijdig of niet behoorlijk de door Dienstverlener verlangde gegevens en bescheiden beschikbaar en loopt de uitvoering van de opdracht hierdoor vertraging op, dan komen de daaruit voortvloeiende extra kosten en extra honoraria voor rekening van Opdrachtgever.
      </p>
      <p className="leading-6 text-gray-600">
        7. Ingeval Dienstverlener bij Opdrachtgever op locatie Diensten verricht dan wel van de faciliteiten, computersystemen en/of telefoonnetwerken van Opdrachtgever gebruik maakt, dient Opdrachtgever voor eigen rekening zorg te dragen voor de benodigde toegang, beveiligingsprocedures, viruscontroles, faciliteiten, vergunningen en/of toestemmingen.
      </p>
      <p className="leading-6 text-gray-600">
        8. Ingeval Dienstverlener bij Opdrachtgever op locatie Diensten verricht, dient Opdrachtgever voor eigen rekening aan de medewerkers van Dienstverlener een adequate werkruimte en overige faciliteiten ter beschikking te stellen die nodig zijn om de Diensten uit te voeren en die voldoen aan alle daaraan te stellen (wettelijke) eisen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 8 - Intrekking opdracht</h2>
      <p className="leading-6 text-gray-600">
        1. Het staat Opdrachtgever vrij om de opdracht aan Dienstverlener op elk gewenst moment te beëindigen tenzij anders overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        2. Wanneer Opdrachtgever de opdracht intrekt, is Opdrachtgever verplicht het verschuldigde loon en de gemaakte onkosten van Dienstverlener te betalen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 9 - Uitvoering van de overeenkomst</h2>
      <p className="leading-6 text-gray-600">
        1. Dienstverlener voert de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uit.
      </p>
      <p className="leading-6 text-gray-600">
        2. De uitvoering geschiedt in onderling overleg en na schriftelijk akkoord en betaling van het eventueel afgesproken voorschot.
      </p>
      <p className="leading-6 text-gray-600">
        3. Het is de verantwoordelijkheid van Opdrachtgever dat Dienstverlener tijdig kan beginnen aan de opdracht.
      </p>
      <p className="leading-6 text-gray-600">
        4. Opgegeven termijnen voor het volbrengen van een opdracht hebben een indicatieve strekking tenzij schriftelijk uitdrukkelijk anders overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        5. Indien is overeengekomen dat de Diensten in fasen worden uitgevoerd, is Dienstverlener gerechtigd om de aanvang van de Diensten die tot een volgende fase behoren uit te stellen totdat de Opdrachtgever de resultaten van de daaraan voorafgaande fase schriftelijk heeft geaccepteerd en/of alle verschuldigde bedragen zijn voldaan.
      </p>
      <p className="leading-6 text-gray-600">
        6. Indien Dienstverlener op verzoek van de Opdrachtgever werkzaamheden of andere prestaties verricht die buiten de inhoud of de omvang van de Diensten vallen, zullen deze werkzaamheden of prestaties door Opdrachtgever aan Dienstverlener worden vergoed volgens dezelfde tarieven als waarvoor de Diensten worden uitgevoerd. Opdrachtgever aanvaardt dat door dergelijke werkzaamheden het overeengekomen tijdstip of het verwachte tijdstip van voltooiing van de Diensten en de wederzijdse verantwoordelijkheden van partijen kunnen worden beïnvloed.
      </p>
      <p className="leading-6 text-gray-600">
        7. Dienstverlener is gerechtigd om (onderdelen van) Diensten aan Opdrachtgever te weigeren, bijvoorbeeld als deze conflicteren met belangen van andere Opdrachtgevers. Indien Dienstverlener onderdelen van Dienstverlening weigert uit te voeren houdt dat niet in dat Opdrachtgever andere overeengekomen Diensten ook niet meer hoeft af te nemen. Het weigeren van Diensten door Dienstverlener kan nimmer leiden tot het niet verrichten van betalingen door Opdrachtgever voor reeds verrichtte werkzaamheden.
      </p>
      <p className="leading-6 text-gray-600">
        8. Dienstverlener is gerechtigd op elk moment een consultant te vervangen met een andere gelijkwaardige consultant.
      </p>
      <p className="leading-6 text-gray-600">
        9. De door Dienstverlener gegeven adviezen, conclusies, meningen, verwachtingen, voorspellingen en/of aanbevelingen kunnen onder geen voorwaarde of omstandigheid worden opgevat als een garantie ter zake van toekomstige gebeurtenissen of omstandigheden.
      </p>
      <p className="leading-6 text-gray-600">
        10. Opdrachtgever blijft zelf verantwoordelijk en aansprakelijk voor het bestuur en de bedrijfsvoering van zijn bedrijf of organisatie, de uitoefening van zijn bedrijfsactiviteiten en zijn zakelijke aangelegenheden waaronder het handelen conform toepasselijke (kwaliteits)normen en wet- en regelgeving. Opdrachtgever erkent dat Dienstverlener daar niet voor aansprakelijk kan worden gehouden en vrijwaart Dienstverlener voor enige vordering ter zake al dan niet van een toezichthouder of enige derde.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 10 - Contractduur opdracht</h2>
      <p className="leading-6 text-gray-600">
        1. De overeenkomst tussen Opdrachtgever en Dienstverlener wordt aangegaan voor onbepaalde tijd, tenzij uit de aard van de overeenkomst iets anders voortvloeit of partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen.
      </p>
      <p className="leading-6 text-gray-600">
        2. Zijn partijen binnen de looptijd van de overeenkomst voor de voltooiing van bepaalde werkzaamheden een termijn overeengekomen, dan is dit nooit een fatale termijn. Bij overschrijding van deze termijn moet Opdrachtgever Dienstverlener schriftelijk in gebreke stellen.
      </p>
      <p className="leading-6 text-gray-600">
        3. Indien de Overeenkomst voor onbepaalde duur is aangegaan, kan zowel door Dienstverlener als door Opdrachtgever schriftelijk worden opgezegd met inachtneming van een opzegtermijn van zestig (60) dagen. In geval Opdrachtgever aldus opzegt, is Opdrachtgever verplicht alle schade en kosten aan de zijde van Dienstverlener te vergoeden. Onder deze schade en kosten vallen in ieder geval, doch niet uitsluitend, al de door Dienstverlener in verband met de Overeenkomst en de (toekomstige) Diensten gemaakte kosten en gedane investeringen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 11 - Wijziging van de overeenkomst</h2>
      <p className="leading-6 text-gray-600">
        1. Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering van de opdracht noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan.
      </p>
      <p className="leading-6 text-gray-600">
        2. Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van de uitvoering daardoor worden beïnvloed. Dienstverlener stelt Opdrachtgever hiervan zo spoedig mogelijk op de hoogte.
      </p>
      <p className="leading-6 text-gray-600">
        3. Indien de wijziging van of aanvulling op de overeenkomst financiële en/of kwalitatieve consequenties heeft, licht Dienstverlener Opdrachtgever hierover zo spoedig mogelijk schriftelijk in.
      </p>
      <p className="leading-6 text-gray-600">
        4. Indien partijen een vast honorarium zijn overeengekomen, geeft Dienstverlener daarbij aan in hoeverre de wijziging of aanvulling van de overeenkomst een overschrijding van dit honorarium tot gevolg heeft.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 12 - Overmacht</h2>
      <p className="leading-6 text-gray-600">
        1. In aanvulling op het bepaalde in artikel 6:75 BW geldt dat een tekortkoming van Dienstverlener in de nakoming van enige verplichting jegens Opdrachtgever niet aan Dienstverlener kan worden toegerekend in geval van een van de wil van Dienstverlener onafhankelijke omstandigheid, waardoor de nakoming van zijn verplichtingen jegens Opdrachtgever geheel of gedeeltelijk wordt verhinderd of waardoor de nakoming van zijn verplichtingen in redelijkheid niet van Dienstverlener kan worden verlangd. Tot die omstandigheden worden mede gerekend wanprestaties van toeleveranciers of andere derden, stroomstoringen, computervirussen, stakingen, slechte weersomstandigheden en werkonderbrekingen.
      </p>
      <p className="leading-6 text-gray-600">
        2. Indien zich een situatie als hiervoor bedoeld voordoet als gevolg waarvan Dienstverlener niet aan zijn verplichtingen jegens Opdrachtgever kan voldoen, dan worden die verplichtingen opgeschort zolang Dienstverlener niet aan zijn verplichtingen kan voldoen. Indien de in de vorige zin bedoelde situatie 30 kalenderdagen heeft geduurd, hebben partijen het recht de overeenkomst schriftelijk geheel of gedeeltelijk te ontbinden.
      </p>
      <p className="leading-6 text-gray-600">
        3. Dienstverlener is in het geval als bedoeld in het tweede lid van dit artikel niet gehouden tot vergoeding van enige schade, ook niet als Dienstverlener als gevolg van de overmachtstoestand enig voordeel geniet.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 13 - Verrekening</h2>
      <p className="leading-6 text-gray-600">
        Opdrachtgever doet afstand van zijn recht om een schuld aan Dienstverlener te verrekenen met een vordering op Dienstverlener.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 14 - Opschorting</h2>
      <p className="leading-6 text-gray-600">
        Opdrachtgever doet afstand van het recht om de nakoming van enige uit deze overeenkomst voortvloeiende verbintenis op te schorten.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 15 - Overdracht van rechten</h2>
      <p className="leading-6 text-gray-600">
        Rechten van een partij uit deze overeenkomst kunnen niet worden overgedragen zonder de voorafgaande schriftelijke instemming van de andere partij. Deze bepaling geldt als een beding met goederenrechtelijke werking zoals bedoeld in artikel 3:83 lid 2 BW.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 16 - Verval van de vordering</h2>
      <p className="leading-6 text-gray-600">
        Elk recht op vergoeding van schade veroorzaakt door Dienstverlener vervalt in elk geval 12 maanden na de gebeurtenis waaruit de aansprakelijkheid direct of indirect voortvloeit. Hiermee wordt niet uitgesloten het bepaalde in artikel 6:89 BW.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 17 - Garantie</h2>
      <p className="leading-6 text-gray-600">
        Partijen zijn een overeenkomst met een dienstverlenend karakter aangegaan, welke voor De Kwant Consultants enkel een inspanningsverplichting bevat en dus geen resultaatsverplichting.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 18 - Verzekering</h2>
      <p className="leading-6 text-gray-600">
        1. Opdrachtgever verplicht zich geleverde zaken die noodzakelijk zijn voor de uitvoering van de onderliggende overeenkomst, alsook zaken van Dienstverlener die bij Opdrachtgever aanwezig zijn en zaken die onder eigendomsvoorbehoud zijn geleverd, adequaat te verzekeren en verzekerd te houden tegen onder andere brand, ontploffings- en waterschade evenals diefstal.
      </p>
      <p className="leading-6 text-gray-600">
        2. Opdrachtgever geeft op eerste verzoek de polis van deze verzekeringen ter inzage.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 19 - Aansprakelijkheid schade</h2>
      <p className="leading-6 text-gray-600">
        1. Opdrachtgever erkent dat de werkzaamheden van Dienstverlener een adviserend karakter hebben en dat interpretatieverschillen van toepasselijke wet- en regelgeving, zelfregulering en gevestigde en/of erkende normenkaders aan de orde kunnen zijn, hoewel Dienstverlener daarin steeds zorgvuldig zal zijn en zo nodig specialistisch advies zal inwinnen. Op grond van de werkzaamheden van Dienstverlener en de subjectieve beoordelingsaspecten die daarbij steeds een rol spelen, is Dienstverlener niet aansprakelijk voor schade die voortvloeit uit deze overeenkomst, tenzij Dienstverlener de schade opzettelijk of met grove schuld heeft veroorzaakt.
      </p>
      <p className="leading-6 text-gray-600">
        2. In het geval dat Dienstverlener een schadevergoeding verschuldigd is aan Opdrachtgever bedraagt de schade niet meer dan het honorarium.
      </p>
      <p className="leading-6 text-gray-600">
        3. Iedere aansprakelijkheid voor schade, voortvloeiende uit of verband houdende met de uitvoering van een overeenkomst, is steeds beperkt tot het bedrag dat in het desbetreffende geval door de gesloten (beroeps)aansprakelijkheidsverzekering(en) wordt uitbetaald. Dit bedrag wordt vermeerderd met het bedrag van het eigen risico volgens de desbetreffende polis.
      </p>
      <p className="leading-6 text-gray-600">
        4. Indien de verzekeraar in enig geval niet tot uitkering overgaat of schade niet door de verzekering wordt gedekt, is de aansprakelijkheid van Dienstverlener beperkt tot de factuurwaarde van de opdracht per jaar, althans dat gedeelte van de opdracht waarop de aansprakelijkheid betrekking heeft, met een maximum van vijfentwintigduizend (25.000) euro.
      </p>
      <p className="leading-6 text-gray-600">
        5. De aansprakelijkheidsbeperking geldt ook als Dienstverlener aansprakelijk wordt gesteld voor schade die direct of indirect voortvloeit uit het niet deugdelijk functioneren van de door Dienstverlener bij de uitvoering van de opdracht gebruikte apparatuur, software, gegevensbestanden, registers of andere zaken.
      </p>
      <p className="leading-6 text-gray-600">
        6. Niet uitgesloten is de aansprakelijkheid van Dienstverlener voor schade die het gevolg is van opzet of bewuste roekeloosheid van Dienstverlener, zijn leidinggevende of ondergeschikten.
      </p>
      <p className="leading-6 text-gray-600">
        7. Dienstverlener is nimmer aansprakelijk voor indirecte schade, waaronder begrepen maar niet beperkt tot gevolgschade, gederfde winst, gemiste besparingen of schade door bedrijfsstagnatie.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 20 - Aansprakelijkheid Opdrachtgever</h2>
      <p className="leading-6 text-gray-600">
        1. Ingeval een opdracht wordt verstrekt door meer dan één persoon, is ieder van hen hoofdelijk aansprakelijk voor de bedragen die uit hoofde van die opdracht aan Dienstverlener verschuldigd zijn.
      </p>
      <p className="leading-6 text-gray-600">
        2. Wordt een opdracht middellijk of onmiddellijk verstrekt door een natuurlijke persoon namens een rechtspersoon, dan kan deze natuurlijke persoon tevens in privé Opdrachtgever zijn. Daarvoor is vereist dat deze natuurlijke persoon beschouwd kan worden als de (mede)beleidsbepaler van de rechtspersoon. Bij wanbetaling door de rechtspersoon is de natuurlijke persoon dus persoonlijk aansprakelijk voor de betaling van de declaratie, onverschillig of deze, al dan niet op verzoek van Opdrachtgever, ten name van een rechtspersoon dan wel ten name van Opdrachtgever als natuurlijk persoon of hen beiden is gesteld.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 21 - Vrijwaring</h2>
      <p className="leading-6 text-gray-600">
        De Opdrachtgever vrijwaart Dienstverlener tegen alle aanspraken van derden, die verband houden met de door Dienstverlener geleverde goederen en/of diensten, tenzij Opdrachtgever aantoont dat Opdrachtgever schade heeft geleden wegens opzet of bewuste roekeloosheid van Dienstverlener.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 22 - Klachtplicht</h2>
      <p className="leading-6 text-gray-600">
        1. Opdrachtgever is verplicht klachten over de verrichte werkzaamheden direct schriftelijk te melden aan Dienstverlener. De klacht bevat een zo gedetailleerd mogelijke omschrijving van de tekortkoming, zodat Dienstverlener in staat is hierop adequaat te reageren.
      </p>
      <p className="leading-6 text-gray-600">
        2. Een klacht kan er in ieder geval niet toe leiden, dat Dienstverlener gehouden kan worden om andere werkzaamheden te verrichten dan zijn overeengekomen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 23 - Eigendomsvoorbehoud, opschortingsrecht en retentierecht</h2>
      <p className="leading-6 text-gray-600">
        1. De bij Opdrachtgever aanwezige zaken en geleverde zaken en onderdelen blijven eigendom van Dienstverlener totdat Opdrachtgever de gehele afgesproken prijs heeft betaald. Tot die tijd kan Dienstverlener zich beroepen op zijn eigendomsvoorbehoud en de zaken terugnemen.
      </p>
      <p className="leading-6 text-gray-600">
        2. Indien de overeengekomen vooruit te betalen bedragen niet of niet op tijd worden voldaan, heeft Dienstverlener het recht om de werkzaamheden op te schorten totdat het overeengekomen deel alsnog is voldaan. Er is dan sprake van schuldeisersverzuim. Een verlate levering kan in dat geval niet aan Dienstverlener worden tegengeworpen.
      </p>
      <p className="leading-6 text-gray-600">
        3. Dienstverlener is niet bevoegd de onder zijn eigendomsvoorbehoud vallende zaken te verpanden noch op enige andere wijze te bezwaren.
      </p>
      <p className="leading-6 text-gray-600">
        4. Indien zaken nog niet zijn geleverd, maar de overeengekomen voortuitbetaling of prijs niet conform afspraak is voldaan, heeft Dienstverlener het recht van retentie. De zaak wordt dan niet geleverd totdat Opdrachtgever volledig en conform afspraak heeft betaald.
      </p>
      <p className="leading-6 text-gray-600">
        5. In geval van liquidatie, insolventie of surseance van betaling van Opdrachtgever zijn de verplichtingen van Opdrachtgever onmiddellijk opeisbaar.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 24 - Intellectueel eigendom</h2>
      <p className="leading-6 text-gray-600">
        1. Tenzij partijen schriftelijk anders zijn overeengekomen, behoudt Dienstverlener alle intellectuele absolute rechten (waaronder auteursrecht, octrooirecht, merkenrecht, tekeningen- en modellen-recht, etc.) op alle ontwerpen, tekeningen, geschriften, dragers met gegevens of andere informatie, offertes, afbeeldingen, schetsen, modellen, maquettes, etc.
      </p>
      <p className="leading-6 text-gray-600">
        2. De genoemde intellectuele absolute rechten mogen niet zonder schriftelijke toestemming van Dienstverlener worden gekopieerd, aan derden getoond en/of ter beschikking gesteld of op andere wijze worden gebruikt.
      </p>
      <p className="leading-6 text-gray-600">
        3. Opdrachtgever verplicht zich tot geheimhouding van de door Dienstverlener aan hem ter beschikking gestelde vertrouwelijke informatie. Onder vertrouwelijke informatie wordt in ieder geval verstaan datgene waarop dit artikel betrekking heeft, alsmede de bedrijfsgegevens. Opdrachtgever verplicht zich zijn personeel en/of derden die betrokken zijn bij de uitvoering van deze overeenkomst, een schriftelijke geheimhoudingsplicht op te leggen van de strekking van deze bepaling.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 25 - Geheimhouding</h2>
      <p className="leading-6 text-gray-600">
        1. Ieder der partijen houdt de informatie die hij (in welke vorm dan ook) van de andere partij ontvangt en alle andere informatie betreffende de andere partij waarvan hij weet of redelijkerwijs kan vermoeden dat deze geheim of vertrouwelijk is, dan wel informatie waarvan hij kan verwachten dat de verspreiding daarvan de andere partij schade kan berokkenen, geheim en neemt alle nodige maatregelen om te waarborgen dat zijn personeel de genoemde informatie ook geheimhoudt.
      </p>
      <p className="leading-6 text-gray-600">
        2. De in het eerste lid van dit artikel genoemde geheimhoudingsplicht geldt niet voor informatie:
      </p>
      <p className="leading-6 text-gray-600">
        1. die op het moment dat de ontvanger deze informatie ontving al openbaar was of nadien openbaar is geworden zonder een schending door de ontvangende partij van een op hem rustende geheimhoudingsplicht.
      </p>
      <p className="leading-6 text-gray-600">
        2. waarvan de ontvangende partij kan bewijzen dat deze informatie al in zijn bezit was op het moment van verstrekken door de andere partij.
      </p>
      <p className="leading-6 text-gray-600">
        3. die de ontvangende partij van een derde heeft ontvangen waarbij deze derde gerechtigd was deze informatie aan de ontvangende partij te verstrekken.
      </p>
      <p className="leading-6 text-gray-600">
        4. die door de ontvangende partij openbaar wordt gemaakt op grond van een wettelijke plicht.
      </p>
      <p className="leading-6 text-gray-600">
        3. De in dit artikel omschreven geheimhoudingsplicht geldt voor de duur van deze overeenkomst en voor een periode van drie jaar na het eindigen daarvan.
      </p>
      <p className="leading-6 text-gray-600">
        4. Tenzij daartoe door Dienstverlener voorafgaande schriftelijke of elektronische toestemming is verleend, zal Opdrachtgever geen mededelingen doen of aan derden inlichtingen verstrekken over de aanpak en werkwijze van Dienstverlener, tenzij de mededeling of inlichting beperkt blijft tot algemene informatie over de aangeboden diensten van Dienstverlener.
      </p>
      <p className="leading-6 text-gray-600">
         5. Opdrachtgever zal de door Dienstverlener uitgebrachte offerte en de daarin vervatte kennis en ideeën uitsluitend gebruiken ter evaluatie van zijn belang bij de verlening van de opdracht.
      </p>
      <p className="leading-6 text-gray-600">
         6. Partijen zullen hun verplichtingen op grond van dit artikel opleggen aan door hen ingeschakelde derden.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 26 - Boete op overtreding geheimhoudingsplicht</h2>
      <p className="leading-6 text-gray-600">
        1. Overtreedt Opdrachtgever het artikel van deze algemene voorwaarden over geheimhouding, dan verbeurt Opdrachtgever ten behoeve van Dienstverlener een onmiddellijk opeisbare boete van €50.000 voor elke overtreding en daarnaast een bedrag van € 1000 voor elke dag dat die overtreding voortduurt. Dit is ongeacht of de overtreding aan Opdrachtgever kan worden toegerekend. Bovendien is er voor het verbeuren van deze boete geen voorafgaande ingebrekestelling of gerechtelijke procedure benodigd. Ook hoeft er geen sprake te zijn van enige vorm van schade.
      </p>
      <p className="leading-6 text-gray-600">
        2. Het verbeuren van de in het eerste lid van dit artikel bedoelde boete doet geen afbreuk aan de overige rechten van Dienstverlener waaronder zijn recht om naast de boete schadevergoeding te vorderen.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 27 - Niet-overname personeel</h2>
      <p className="leading-6 text-gray-600">
        Opdrachtgever neemt geen medewerkers van Dienstverlener (of van ondernemingen waarop Dienstverlener ter uitvoering van deze overeenkomst een beroep heeft gedaan en die betrokkenen zijn (geweest) bij de uitvoering van de overeenkomst) in dienst. Ook laat hij hen niet anderszins direct of indirect voor zich werken. Dit verbod geldt gedurende de looptijd van de overeenkomst tot een jaar na beëindiging daarvan. Op dit verbod geldt één uitzondering: partijen kunnen in goed zakelijk overleg met elkaar andere afspraken maken. Deze afspraken gelden voor zover die schriftelijk zijn vastgelegd.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 28 – Bescherming van persoonsgegevens</h2>
      <p className="leading-6 text-gray-600">
        1. Opdrachtgever erkent dat Dienstverlener in het kader van het sluiten van de Overeenkomst, in het kader van de Diensten, of in het kader van de nakoming van wettelijke verplichtingen rustende op Dienstverlener, persoonsgegevens betreffende de Opdrachtgever en/of personen verbonden aan of werkzaam voor dan wel ten behoeve van Opdrachtgever alsmede andere persoonsgegevens waarvoor Opdrachtgever verwerkingsverantwoordelijke of verwerker is in de zin van de Algemene Verordening Gegevensbescherming kan verwerken.
      </p>
      <p className="leading-6 text-gray-600">
        2. Dienstverlener zal verkregen persoonsgegevens door of vanwege Opdrachtgever zorgvuldig verwerken in overeenstemming met de Algemene Verordening Gegevensbescherming.
      </p>
      <p className="leading-6 text-gray-600">
        3. Opdrachtgever erkent dat Dienstverlener voor een beperkt aantal persoonsgegevens betreffende de Opdrachtgever en/of personen verbonden aan of werkzaam voor dan wel ten behoeve van Opdrachtgever, waaronder begrepen namen en contactgegevens welke Dienstverlener heeft verkregen in het kader van haar acquisitie of in het kader van de Overeenkomst, een verwerkingsverantwoordelijke is in de zin van de Algemene Verordening Gegevensbescherming. Opdrachtgever erkent dat hij ermee bekend is dat deze persoonsgegevens zijn opgenomen in de administratie van Dienstverlener en worden gebruikt ten behoeve van de door Dienstverlener aan Opdrachtgever verleende Diensten, de nakoming van wettelijke verplichtingen van Dienstverlener, alsmede voor andere legitieme en redelijke doeleinden van Dienstverlener of derden voor zover toegestaan op grond van de wet, waaronder begrepen de verstrekking van dergelijke persoonsgegevens aan derden.
      </p>
      <p className="leading-6 text-gray-600">
        4. Opdrachtgever gaat ermee akkoord dat Dienstverlener de persoonsgegevens als bedoeld in het derde lid tevens mag verwerken ten behoeve van het benaderen van Opdrachtgever met informatie, over de diensten van Dienstverlener en haar partners. Onder de doelstelling omschreven in de vorige volzin wordt mede begrepen het sturen van elektronische berichten in de zin van artikel 11.7 Telecommunicatiewet.
      </p>
      <p className="leading-6 text-gray-600">
        5. Dienstverlener zal de persoonsgegevens als bedoeld in het derde lid niet verkopen, verhuren, of anderszins tegen betaling ter beschikking stellen van derden.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 29 – Mogelijk conflicterende belangen</h2>
      <p className="leading-6 text-gray-600">
        1. Het staat Dienstverlener te allen tijde vrij om diensten te verlenen aan een andere partij die een belang heeft dat concurrerend of conflicterend is met de belangen van Opdrachtgever (hierna: een “Conflicterende Partij”).
      </p>
      <p className="leading-6 text-gray-600">
        2. Indien Opdrachtgever bekend is (geworden) met het feit en/of de omstandigheid dat Dienstverlener een Conflicterende Partij adviseert of voornemens is om dit te doen ter zake van een specifiek en direct met de belangen van Opdrachtgever concurrerend of conflicterend belang, informeert Opdrachtgever Dienstverlener daar terstond over en stelt Dienstverlener in de gelegenheid om binnen redelijke termijn passende maatregelen te nemen naar het oordeel van Dienstverlener.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 30 - Overig</h2>
      <p className="leading-6 text-gray-600">
        1. Voor zover in de Overeenkomst niet anders is bepaald, vervallen vorderingsrechten en andere bevoegdheden van Opdrachtgever jegens Dienstverlener, uit welke hoofde dan ook, in ieder geval één (1) jaar na het moment waarop Opdrachtgever bekend werd of redelijkerwijs bekend kon zijn met het bestaan van deze rechten en bevoegdheden.
      </p>
      <p className="leading-6 text-gray-600">
        2. Alle rechten en verplichtingen voortvloeiende uit de Overeenkomst die naar hun strekking bedoeld zijn om na beëindiging van de Overeenkomst te blijven gelden, blijven na de beëindiging onverminderd van kracht tussen Dienstverlener en Opdrachtgever, waaronder maar niet beperkt tot het bepaalde in Artikel 9, voor zover in de Overeenkomst of Aanvullende Voorwaarden niet anders is bepaald.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 31 - Wijziging algemene voorwaarden</h2>
      <p className="leading-6 text-gray-600">
        1. De Kwant Consultants is gerechtigd deze algemene voorwaarden te wijzigen of aan te vullen.
      </p>
      <p className="leading-6 text-gray-600">
        2. Wijzigingen van ondergeschikt belang kunnen te allen tijde worden doorgevoerd.
      </p>
      <p className="leading-6 text-gray-600">
        3. Grote inhoudelijke wijzigingen zal De Kwant Consultants zoveel mogelijk vooraf met de klant bespreken.
      </p>
      <p className="leading-6 text-gray-600">
        4. Indien enige bepaling van deze algemene voorwaarden wijzigt op grond van lid 1 of 2, nietig blijkt te zijn of vernietigd wordt, zullen de overige bepalingen van kracht blijven. De nietige of vernietigde bepaling zal worden vervangen door een geldige bepaling die zoveel mogelijk aansluit bij de bedoeling van de nietige of vernietigde bepaling.
      </p>

      <h2 className="text-blue-500 font-medium text-lg md:text-1xl pt-6">Artikel 32 - Toepasselijk recht en bevoegde rechter</h2>
      <p className="leading-6 text-gray-600">
        1. Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands recht van toepassing.
      </p>
      <p className="leading-6 text-gray-600">
        2. De Nederlandse rechter in het arrondissement waar De Kwant Consultants is gevestigd/ praktijk houdt/kantoor houdt is exclusief bevoegd om kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet dwingend anders voorschrijft.
      </p>
    </div>
    </div>
    </main>
  );
};

export default AlgemeneVoorwaarden;
