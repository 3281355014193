import React from 'react';
import PageIntro from '../PageIntro';

function CHUB() {
    return (
        <main>
            <PageIntro
                title="CHUB"
                subtitle="Diensten"
                breadcrumbs={[
                    { href: "/diensten", title: "Diensten" },
                    { href: "/c-hub", title: "C-HUB" }
                ]}
            />
        </main>
    );
}

export default CHUB;