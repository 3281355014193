import React from 'react';

function SingleZinOcean({ zin, background="slate", bold=false }) {
    return (
        <section className={`${background === 'ocean' ? 'bg-ocean-50' : background === 'white' ? 'bg-white' : 'bg-slate-50'} py-12 md:py-20`}>
        <div className="container">
          <div className="grid">
            <div className="flex flex-col gap-12 text-center">
            <h4 className={`${background === 'ocean' ? 'text-ocean-700' : 'text-gray-900'} ${bold ? 'font-bold' : 'font-medium'} text-xl md:text-2xl`}>{zin}</h4>
            </div>
          </div>
        </div>
      </section>
    );
}

export default SingleZinOcean;
