import React from "react";
import { Model } from "survey-core";
import "survey-core/i18n/dutch";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import enquette from "./enquette.js";
import PageIntro from "../PageIntro.js";

function SurveyPage() {
    const surveyJson = {
            pageNextText: "Volgende",
            pagePrevText: "Terug",
            completeText: "Verzenden"
      }


    function populateSecondaryDropdown(survey) {
        var primaryDropdown = survey.getQuestionByName("type_organisatie");
        var secondaryDropdown = survey.getQuestionByName("organisatie_naam");

        primaryDropdown.registerFunctionOnPropertyValueChanged("value", function () {
          secondaryDropdown.clearValue();
          var selectedCategory = primaryDropdown.value;
          var choices = [];

          if (selectedCategory === "Gemeente") {
            choices = [
              {text: " Amsterdam", value: " Amsterdam"},
{text: " Rotterdam", value: " Rotterdam"},
{text: " Den Haag", value: " Den Haag"},
{text: " Utrecht", value: " Utrecht"},
{text: " Eindhoven", value: " Eindhoven"},
{text: " Groningen", value: " Groningen"},
{text: " Tilburg", value: " Tilburg"},
{text: " Almere", value: " Almere"},
{text: " Breda", value: " Breda"},
{text: " Nijmegen", value: " Nijmegen"},
{text: " Apeldoorn", value: " Apeldoorn"},
{text: " Haarlem", value: " Haarlem"},
{text: " Arnhem", value: " Arnhem"},
{text: " Haarlemmermeer", value: " Haarlemmermeer"},
{text: " Amersfoort", value: " Amersfoort"},
{text: " Enschede", value: " Enschede"},
{text: " Zaanstad", value: " Zaanstad"},
{text: " 's-Hertogenbosch", value: " 's-Hertogenbosch"},
{text: " Zwolle", value: " Zwolle"},
{text: " Leiden", value: " Leiden"},
{text: " Leeuwarden", value: " Leeuwarden"},
{text: " Zoetermeer", value: " Zoetermeer"},
{text: " Maastricht", value: " Maastricht"},
{text: " Ede", value: " Ede"},
{text: " Dordrecht", value: " Dordrecht"},
{text: " Westland", value: " Westland"},
{text: " Alphen aan den Rijn", value: " Alphen aan den Rijn"},
{text: " Alkmaar", value: " Alkmaar"},
{text: " Delft", value: " Delft"},
{text: " Emmen", value: " Emmen"},
{text: " Venlo", value: " Venlo"},
{text: " Deventer", value: " Deventer"},
{text: " Helmond", value: " Helmond"},
{text: " Purmerend", value: " Purmerend"},
{text: " Amstelveen", value: " Amstelveen"},
{text: " Oss", value: " Oss"},
{text: " Hilversum", value: " Hilversum"},
{text: " Sittard-Geleen", value: " Sittard-Geleen"},
{text: " Land van Cuijk", value: " Land van Cuijk"},
{text: " Sudwest-Fryslan", value: " Sudwest-Fryslan"},
{text: " Hoeksche Waard", value: " Hoeksche Waard"},
{text: " Dijk en Waard", value: " Dijk en Waard"},
{text: " Nissewaard", value: " Nissewaard"},
{text: " Heerlen", value: " Heerlen"},
{text: " Meierijstad", value: " Meierijstad"},
{text: " Lelystad", value: " Lelystad"},
{text: " Hengelo", value: " Hengelo"},
{text: " Schiedam", value: " Schiedam"},
{text: " Leidschendam-Voorburg", value: " Leidschendam-Voorburg"},
{text: " Roosendaal", value: " Roosendaal"},
{text: " Vlaardingen", value: " Vlaardingen"},
{text: " Gouda", value: " Gouda"},
{text: " Hoorn", value: " Hoorn"},
{text: " Almelo", value: " Almelo"},
{text: " Voorne aan Zee", value: " Voorne aan Zee"},
{text: " Assen", value: " Assen"},
{text: " Bergen op Zoom", value: " Bergen op Zoom"},
{text: " Veenendaal", value: " Veenendaal"},
{text: " Velsen", value: " Velsen"},
{text: " Capelle aan den IJssel", value: " Capelle aan den IJssel"},
{text: " Katwijk", value: " Katwijk"},
{text: " Zeist", value: " Zeist"},
{text: " Nieuwegein", value: " Nieuwegein"},
{text: " Stichtse Vecht", value: " Stichtse Vecht"},
{text: " Lansingerland", value: " Lansingerland"},
{text: " Westerkwartier", value: " Westerkwartier"},
{text: " Hardenberg", value: " Hardenberg"},
{text: " Barneveld", value: " Barneveld"},
{text: " Vijfheerenlanden", value: " Vijfheerenlanden"},
{text: " Midden-Groningen", value: " Midden-Groningen"},
{text: " Roermond", value: " Roermond"},
{text: " Gooise Meren", value: " Gooise Meren"},
{text: " Rijswijk", value: " Rijswijk"},
{text: " Doetinchem", value: " Doetinchem"},
{text: " Maashorst", value: " Maashorst"},
{text: " Altena", value: " Altena"},
{text: " Pijnacker-Nootdorp", value: " Pijnacker-Nootdorp"},
{text: " Oosterhout", value: " Oosterhout"},
{text: " Krimpenerwaard", value: " Krimpenerwaard"},
{text: " Smallingerland", value: " Smallingerland"},
{text: " Hoogeveen", value: " Hoogeveen"},
{text: " Den Helder", value: " Den Helder"},
{text: " Kampen", value: " Kampen"},
{text: " Terneuzen", value: " Terneuzen"},
{text: " Woerden", value: " Woerden"},
{text: " West Betuwe", value: " West Betuwe"},
{text: " De Fryske Marren", value: " De Fryske Marren"},
{text: " Goeree-Overflakkee", value: " Goeree-Overflakkee"},
{text: " Heerenveen", value: " Heerenveen"},
{text: " Weert", value: " Weert"},
{text: " Houten", value: " Houten"},
{text: " Utrechtse Heuvelrug", value: " Utrechtse Heuvelrug"},
{text: " Waalwijk", value: " Waalwijk"},
{text: " Noordoostpolder", value: " Noordoostpolder"},
{text: " Middelburg", value: " Middelburg"},
{text: " Hollands Kroon", value: " Hollands Kroon"},
{text: " Harderwijk", value: " Harderwijk"},
{text: " Overbetuwe", value: " Overbetuwe"},
{text: " Zutphen", value: " Zutphen"},
{text: " Barendrecht", value: " Barendrecht"},
{text: " Het Hogeland", value: " Het Hogeland"},
{text: " Zuidplas", value: " Zuidplas"},
{text: " Schagen", value: " Schagen"},
{text: " Ridderkerk", value: " Ridderkerk"},
{text: " Soest", value: " Soest"},
{text: " Lingewaard", value: " Lingewaard"},
{text: " Waadhoeke", value: " Waadhoeke"},
{text: " Veldhoven", value: " Veldhoven"},
{text: " Heusden", value: " Heusden"},
{text: " Medemblik", value: " Medemblik"},
{text: " Noardeast-Fryslan", value: " Noardeast-Fryslan"},
{text: " De Ronde Venen", value: " De Ronde Venen"},
{text: " Noordwijk", value: " Noordwijk"},
{text: " Peel en Maas", value: " Peel en Maas"},
{text: " Steenwijkerland", value: " Steenwijkerland"},
{text: " Vlissingen", value: " Vlissingen"},
{text: " Nijkerk", value: " Nijkerk"},
{text: " Kerkrade", value: " Kerkrade"},
{text: " Molenlanden", value: " Molenlanden"},
{text: " Etten-Leur", value: " Etten-Leur"},
{text: " Eemsdelta", value: " Eemsdelta"},
{text: " Zevenaar", value: " Zevenaar"},
{text: " Zwijndrecht", value: " Zwijndrecht"},
{text: " Venray", value: " Venray"},
{text: " Dronten", value: " Dronten"},
{text: " Berkelland", value: " Berkelland"},
{text: " Horst aan de Maas", value: " Horst aan de Maas"},
{text: " De Bilt", value: " De Bilt"},
{text: " Rheden", value: " Rheden"},
{text: " Beverwijk", value: " Beverwijk"},
{text: " Wageningen", value: " Wageningen"},
{text: " Tiel", value: " Tiel"},
{text: " Wijchen", value: " Wijchen"},
{text: " Huizen", value: " Huizen"},
{text: " Geldrop-Mierlo", value: " Geldrop-Mierlo"},
{text: " Goes", value: " Goes"},
{text: " Heemskerk", value: " Heemskerk"},
{text: " Oldambt", value: " Oldambt"},
{text: " Oude IJsselstreek", value: " Oude IJsselstreek"},
{text: " Rijssen-Holten", value: " Rijssen-Holten"},
{text: " Gorinchem", value: " Gorinchem"},
{text: " Teylingen", value: " Teylingen"},
{text: " Raalte", value: " Raalte"},
{text: " Moerdijk", value: " Moerdijk"},
{text: " Landgraaf", value: " Landgraaf"},
{text: " Edam-Volendam", value: " Edam-Volendam"},
{text: " Montferland", value: " Montferland"},
{text: " Bodegraven-Reeuwijk", value: " Bodegraven-Reeuwijk"},
{text: " Castricum", value: " Castricum"},
{text: " Hellendoorn", value: " Hellendoorn"},
{text: " Bronckhorst", value: " Bronckhorst"},
{text: " Leudal", value: " Leudal"},
{text: " Beekdaelen", value: " Beekdaelen"},
{text: " Maassluis", value: " Maassluis"},
{text: " Meppel", value: " Meppel"},
{text: " Coevorden", value: " Coevorden"},
{text: " Berg en Dal", value: " Berg en Dal"},
{text: " Hof van Twente", value: " Hof van Twente"},
{text: " Tynaarlo", value: " Tynaarlo"},
{text: " Schouwen-Duiveland", value: " Schouwen-Duiveland"},
{text: " Lochem", value: " Lochem"},
{text: " Midden-Drenthe", value: " Midden-Drenthe"},
{text: " Twenterand", value: " Twenterand"},
{text: " Waddinxveen", value: " Waddinxveen"},
{text: " Boxtel", value: " Boxtel"},
{text: " IJsselstein", value: " IJsselstein"},
{text: " Aalsmeer", value: " Aalsmeer"},
{text: " Deurne", value: " Deurne"},
{text: " Epe", value: " Epe"},
{text: " Diemen", value: " Diemen"},
{text: " Oisterwijk", value: " Oisterwijk"},
{text: " Hendrik-Ido-Ambacht", value: " Hendrik-Ido-Ambacht"},
{text: " Tytsjerksteradiel", value: " Tytsjerksteradiel"},
{text: " Bernheze", value: " Bernheze"},
{text: " Vught", value: " Vught"},
{text: " Papendrecht", value: " Papendrecht"},
{text: " Echt-Susteren", value: " Echt-Susteren"},
{text: " Stadskanaal", value: " Stadskanaal"},
{text: " Oldenzaal", value: " Oldenzaal"},
{text: " Valkenswaard", value: " Valkenswaard"},
{text: " Noordenveld", value: " Noordenveld"},
{text: " Uithoorn", value: " Uithoorn"},
{text: " Leusden", value: " Leusden"},
{text: " Gemert-Bakel", value: " Gemert-Bakel"},
{text: " Renkum", value: " Renkum"},
{text: " Best", value: " Best"},
{text: " Halderberge", value: " Halderberge"},
{text: " Zaltbommel", value: " Zaltbommel"},
{text: " Sint-Michielsgestel", value: " Sint-Michielsgestel"},
{text: " Opsterland", value: " Opsterland"},
{text: " Oost Gelre", value: " Oost Gelre"},
{text: " Culemborg", value: " Culemborg"},
{text: " Bergen NH", value: " Bergen NH"},
{text: " Dalfsen", value: " Dalfsen"},
{text: " Krimpen aan den IJssel", value: " Krimpen aan den IJssel"},
{text: " Nieuwkoop", value: " Nieuwkoop"},
{text: " Winterswijk", value: " Winterswijk"},
{text: " Kaag en Braassem", value: " Kaag en Braassem"},
{text: " Nunspeet", value: " Nunspeet"},
{text: " Achtkarspelen", value: " Achtkarspelen"},
{text: " Drimmelen", value: " Drimmelen"},
{text: " Ermelo", value: " Ermelo"},
{text: " Buren", value: " Buren"},
{text: " Brunssum", value: " Brunssum"},
{text: " Leiderdorp", value: " Leiderdorp"},
{text: " Heemstede", value: " Heemstede"},
{text: " Veendam", value: " Veendam"},
{text: " Gilze en Rijen", value: " Gilze en Rijen"},
{text: " Hulst", value: " Hulst"},
{text: " Aalten", value: " Aalten"},
{text: " Wassenaar", value: " Wassenaar"},
{text: " Dongen", value: " Dongen"},
{text: " Tholen", value: " Tholen"},
{text: " Dinkelland", value: " Dinkelland"},
{text: " Beuningen", value: " Beuningen"},
{text: " Weststellingwerf", value: " Weststellingwerf"},
{text: " Westerwolde", value: " Westerwolde"},
{text: " Albrandswaard", value: " Albrandswaard"},
{text: " Sliedrecht", value: " Sliedrecht"},
{text: " Maasdriel", value: " Maasdriel"},
{text: " Eijsden-Margraten", value: " Eijsden-Margraten"},
{text: " Borger-Odoorn", value: " Borger-Odoorn"},
{text: " Oegstgeest", value: " Oegstgeest"},
{text: " Aa en Hunze", value: " Aa en Hunze"},
{text: " Ooststellingwerf", value: " Ooststellingwerf"},
{text: " Neder-Betuwe", value: " Neder-Betuwe"},
{text: " Voorschoten", value: " Voorschoten"},
{text: " Voorst", value: " Voorst"},
{text: " Baarn", value: " Baarn"},
{text: " Putten", value: " Putten"},
{text: " Wierden", value: " Wierden"},
{text: " Duiven", value: " Duiven"},
{text: " Stein", value: " Stein"},
{text: " Borne", value: " Borne"},
{text: " Wijdemeren", value: " Wijdemeren"},
{text: " De Wolden", value: " De Wolden"},
{text: " Steenbergen", value: " Steenbergen"},
{text: " Maasgouw", value: " Maasgouw"},
{text: " Haaksbergen", value: " Haaksbergen"},
{text: " Goirle", value: " Goirle"},
{text: " Heiloo", value: " Heiloo"},
{text: " Oldebroek", value: " Oldebroek"},
{text: " Nuenen, Gerwen en Nederwetten", value: " Nuenen, Gerwen en Nederwetten"},
{text: " Wijk bij Duurstede", value: " Wijk bij Duurstede"},
{text: " Rucphen", value: " Rucphen"},
{text: " Elburg", value: " Elburg"},
{text: " Zeewolde", value: " Zeewolde"},
{text: " Loon op Zand", value: " Loon op Zand"},
{text: " Bloemendaal", value: " Bloemendaal"},
{text: " Koggenland", value: " Koggenland"},
{text: " Zwartewaterland", value: " Zwartewaterland"},
{text: " Lisse", value: " Lisse"},
{text: " Losser", value: " Losser"},
{text: " Reimerswaal", value: " Reimerswaal"},
{text: " Laarbeek", value: " Laarbeek"},
{text: " Sluis", value: " Sluis"},
{text: " Borsele", value: " Borsele"},
{text: " Hillegom", value: " Hillegom"},
{text: " Bunschoten", value: " Bunschoten"},
{text: " Zundert", value: " Zundert"},
{text: " Stede Broec", value: " Stede Broec"},
{text: " Woensdrecht", value: " Woensdrecht"},
{text: " Veere", value: " Veere"},
{text: " Geertruidenberg", value: " Geertruidenberg"},
{text: " Urk", value: " Urk"},
{text: " Tubbergen", value: " Tubbergen"},
{text: " Brummen", value: " Brummen"},
{text: " Bladel", value: " Bladel"},
{text: " Cranendonck", value: " Cranendonck"},
{text: " Roerdalen", value: " Roerdalen"},
{text: " Drechterland", value: " Drechterland"},
{text: " West Maas en Waal", value: " West Maas en Waal"},
{text: " Alblasserdam", value: " Alblasserdam"},
{text: " Rhenen", value: " Rhenen"},
{text: " Eersel", value: " Eersel"},
{text: " Someren", value: " Someren"},
{text: " Westerveld", value: " Westerveld"},
{text: " Druten", value: " Druten"},
{text: " Midden-Delfland", value: " Midden-Delfland"},
{text: " Oirschot", value: " Oirschot"},
{text: " Heerde", value: " Heerde"},
{text: " Bergeijk", value: " Bergeijk"},
{text: " Dantumadiel", value: " Dantumadiel"},
{text: " Ommen", value: " Ommen"},
{text: " Enkhuizen", value: " Enkhuizen"},
{text: " Olst-Wijhe", value: " Olst-Wijhe"},
{text: " Hardinxveld-Giessendam", value: " Hardinxveld-Giessendam"},
{text: " Meerssen", value: " Meerssen"},
{text: " Waalre", value: " Waalre"},
{text: " Son en Breugel", value: " Son en Breugel"},
{text: " Gennep", value: " Gennep"},
{text: " Staphorst", value: " Staphorst"},
{text: " Waterland", value: " Waterland"},
{text: " Nederweert", value: " Nederweert"},
{text: " Zandvoort", value: " Zandvoort"},
{text: " Asten", value: " Asten"},
{text: " Heumen", value: " Heumen"},
{text: " Heeze-Leende", value: " Heeze-Leende"},
{text: " Wormerland", value: " Wormerland"},
{text: " Valkenburg aan de Geul", value: " Valkenburg aan de Geul"},
{text: " Harlingen", value: " Harlingen"},
{text: " Beek", value: " Beek"},
{text: " Bunnik", value: " Bunnik"},
{text: " Hilvarenbeek", value: " Hilvarenbeek"},
{text: " Westervoort", value: " Westervoort"},
{text: " Lopik", value: " Lopik"},
{text: " Woudenberg", value: " Woudenberg"},
{text: " Ouder-Amstel", value: " Ouder-Amstel"},
{text: " Gulpen-Wittem", value: " Gulpen-Wittem"},
{text: " Montfoort", value: " Montfoort"},
{text: " Texel", value: " Texel"},
{text: " Reusel-De Mierden", value: " Reusel-De Mierden"},
{text: " Uitgeest", value: " Uitgeest"},
{text: " Beesel", value: " Beesel"},
{text: " Bergen (L)", value: " Bergen (L)"},
{text: " Kapelle", value: " Kapelle"},
{text: " Blaricum", value: " Blaricum"},
{text: " Hattem", value: " Hattem"},
{text: " Pekela", value: " Pekela"},
{text: " Voerendaal", value: " Voerendaal"},
{text: " Opmeer", value: " Opmeer"},
{text: " Landsmeer", value: " Landsmeer"},
{text: " Laren", value: " Laren"},
{text: " Boekel", value: " Boekel"},
{text: " Doesburg", value: " Doesburg"},
{text: " Alphen-Chaam", value: " Alphen-Chaam"},
{text: " Scherpenzeel", value: " Scherpenzeel"},
{text: " Simpelveld", value: " Simpelveld"},
{text: " Oudewater", value: " Oudewater"},
{text: " Vaals", value: " Vaals"},
{text: " Eemnes", value: " Eemnes"},
{text: " Zoeterwoude", value: " Zoeterwoude"},
{text: " Oostzaan", value: " Oostzaan"},
{text: " Mook en Middelaar", value: " Mook en Middelaar"},
{text: " Noord-Beveland", value: " Noord-Beveland"},
{text: " Baarle-Nassau", value: " Baarle-Nassau"},
{text: " Renswoude", value: " Renswoude"},
{text: " Terschelling", value: " Terschelling"},
{text: " Ameland", value: " Ameland"},
{text: " Rozendaal", value: " Rozendaal"},
{text: " Vlieland", value: " Vlieland"},
{text: " Schiermonnikoog", value: " Schiermonnikoog"},
                          ];
          } else if (selectedCategory === "Provincie") {
            choices = [
              { value: "Drenthe", text: "Drenthe" },
              { value: "Flevoland", text: "Flevoland" },
              { value: "Fryslân", text: "Fryslân" },
              { value: "Gelderland", text: "Gelderland" },
              { value: "Groningen", text: "Groningen" },
              { value: "Limburg", text: "Limburg" },
              { value: "Noord-Brabant", text: "Noord-Brabant" },
              { value: "Noord-Holland", text: "Noord-Holland" },
              { value: "Overijssel", text: "Overijssel" },
              { value: "Utrecht", text: "Utrecht" },
              { value: "Zeeland", text: "Zeeland" },
              { value: "Zuid-Holland", text: "Zuid-Holland" },
            ];
          } else if (selectedCategory === "Regionaal samenwerkingsorgaan") {
            choices = [
              {text: '1Stroom', value: '1Stroom'},
              {text: 'ABG-organisatie', value: 'ABG-organisatie'},
              {text: 'Afeer', value: 'Afeer'},
              {text: 'Afvalbeheer Regio Centraal Groningen', value: 'Afvalbeheer Regio Centraal Groningen'},
              {text: 'Afvalbeheer Westfriesland', value: 'Afvalbeheer Westfriesland'},
              {text: 'Afvalverwijdering Utrecht', value: 'Afvalverwijdering Utrecht'},
              {text: 'Amstelland en Meerlanden Werkorganisatie', value: 'Amstelland en Meerlanden Werkorganisatie'},
              {text: 'Amstelland-en Meerlandenoverleg', value: 'Amstelland-en Meerlandenoverleg'},
              {text: 'Aqualysis', value: 'Aqualysis'},
              {text: 'AQUON', value: 'AQUON'},
              {text: 'Avalex', value: 'Avalex'},
              {text: 'Avres', value: 'Avres'},
              {text: 'Avri', value: 'Avri'},
              {text: 'Bedrijfsvoeringseenheid Bommelerwaard', value: 'Bedrijfsvoeringseenheid Bommelerwaard'},
              {text: 'Bedrijfsvoeringsorganisatie Delft-Rijswijk', value: 'Bedrijfsvoeringsorganisatie Delft-Rijswijk'},
              {text: 'Bedrijfsvoeringsorganisatie doelgroepenvervoer regio Arnhem Nijmegen', value: 'Bedrijfsvoeringsorganisatie doelgroepenvervoer regio Arnhem Nijmegen'},
              {text: 'Bedrijfsvoeringsorganisatie H2O', value: 'Bedrijfsvoeringsorganisatie H2O'},
              {text: 'Bedrijfsvoeringsorganisatie Rijn en Braassem', value: 'Bedrijfsvoeringsorganisatie Rijn en Braassem'},
              {text: 'Bedrijfsvoeringsorganisatie Valleihopper', value: 'Bedrijfsvoeringsorganisatie Valleihopper'},
              {text: 'Bedrijfsvoeringsorganisatie West Betuwe', value: 'Bedrijfsvoeringsorganisatie West Betuwe'},
              {text: 'Bedrijvenschap Harnaschpolder', value: 'Bedrijvenschap Harnaschpolder'},
              {text: 'Bedrijvenschap Hoefweg', value: 'Bedrijvenschap Hoefweg'},
              {text: 'BEL Combinatie', value: 'BEL Combinatie'},
              {text: 'Belastingsamenwerking gemeenten en hoogheemraadschap Utrecht', value: 'Belastingsamenwerking gemeenten en hoogheemraadschap Utrecht'},
              {text: 'Belastingsamenwerking Gemeenten en Waterschappen', value: 'Belastingsamenwerking Gemeenten en Waterschappen'},
              {text: 'Belastingsamenwerking Gouwe-Rijnland', value: 'Belastingsamenwerking Gouwe-Rijnland'},
              {text: 'Belastingsamenwerking Oost-Brabant', value: 'Belastingsamenwerking Oost-Brabant'},
              {text: 'Belastingsamenwerking Rivierenland', value: 'Belastingsamenwerking Rivierenland'},
              {text: 'Belastingsamenwerking West-Brabant', value: 'Belastingsamenwerking West-Brabant'},
              {text: 'Blink', value: 'Blink'},
              {text: 'Brabants Historisch Informatie Centrum', value: 'Brabants Historisch Informatie Centrum'},
              {text: 'Bureau Openbare Verlichting', value: 'Bureau Openbare Verlichting'},
              {text: 'Centraal Nautisch Beheer Noordzeekanaalgebied', value: 'Centraal Nautisch Beheer Noordzeekanaalgebied'},
              {text: 'CJG Drimmelen Geertruidenberg', value: 'CJG Drimmelen Geertruidenberg'},
              {text: 'Cocensus', value: 'Cocensus'},
              {text: 'DCMR Milieudienst Rijnmond', value: 'DCMR Milieudienst Rijnmond'},
              {text: 'De Betho', value: 'De Betho'},
              {text: 'de Noordelijke Rekenkamer', value: 'de Noordelijke Rekenkamer'},
              {text: 'De Waddeneilanden', value: 'De Waddeneilanden'},
              {text: 'De Bedrijfsvoeringspartner', value: 'De Bedrijfsvoeringspartner'},
              {text: 'Diamant-groep', value: 'Diamant-groep'},
              {text: 'Dienst Dommelvallei', value: 'Dienst Dommelvallei'},
              {text: 'Dienst Gezondheid & Jeugd Zuid-Holland Zuid', value: 'Dienst Gezondheid & Jeugd Zuid-Holland Zuid'},
              {text: 'Dienst Sociale Zaken en Werkgelegenheid Noardwest Frysl√¢n', value: 'Dienst Sociale Zaken en Werkgelegenheid Noardwest Frysl√¢n'},
              {text: 'Duo+', value: 'Duo+'},
              {text: 'EMCO-groep', value: 'EMCO-groep'},
              {text: 'Erfgoedcentrum Achterhoek en Liemers', value: 'Erfgoedcentrum Achterhoek en Liemers'},
              {text: 'Exploitatiemaatschappij Bedrijvenpark Drachten', value: 'Exploitatiemaatschappij Bedrijvenpark Drachten'},
              {text: 'Ferm Werk', value: 'Ferm Werk'},
              {text: 'Fijnder', value: 'Fijnder'},
              {text: 'Fryske Utfieringstsjinst Miljeu en Omjouwing', value: 'Fryske Utfieringstsjinst Miljeu en Omjouwing'},
              {text: 'Gasdistributie Zeist en Omstreken', value: 'Gasdistributie Zeist en Omstreken'},
              {text: 'GBLT', value: 'GBLT'},
              {text: 'Gelders Archief', value: 'Gelders Archief'},
              {text: 'Gemeenschappelijk Orgaan schoolverzuim en voortijdig schoolverlaten West-Kennemerland', value: 'Gemeenschappelijk Orgaan schoolverzuim en voortijdig schoolverlaten West-Kennemerland'},
              {text: 'Gemeenschappelijk orgaan siteholderschap werelderfgoed Hollandse Waterlinies', value: 'Gemeenschappelijk orgaan siteholderschap werelderfgoed Hollandse Waterlinies'},
              {text: 'Gemeenschappelijke Gezondheidsdienst Zaanstreek-Waterland', value: 'Gemeenschappelijke Gezondheidsdienst Zaanstreek-Waterland'},
              {text: 'Gemeenschappelijke Gezondheidsdienst Zeeland', value: 'Gemeenschappelijke Gezondheidsdienst Zeeland'},
              {text: 'Gemeenschappelijke Regeling Belastingsamenwerking SaBeWa Zeeland', value: 'Gemeenschappelijke Regeling Belastingsamenwerking SaBeWa Zeeland'},
              {text: 'Gemeenschappelijke regeling Cure', value: 'Gemeenschappelijke regeling Cure'},
              {text: 'Gemeenschappelijke Regeling Vidar Wsw', value: 'Gemeenschappelijke Regeling Vidar Wsw'},
              {text: 'Gemeenschappelijke Regeling Waterschapsbedrijf Limburg', value: 'Gemeenschappelijke Regeling Waterschapsbedrijf Limburg'},
              {text: 'Gemeenschappelijke Rekenkamer Metropool Amsterdam', value: 'Gemeenschappelijke Rekenkamer Metropool Amsterdam'},
              {text: 'Gemeenschappelijke Vuilverwerking Dordrecht en Omstreken', value: 'Gemeenschappelijke Vuilverwerking Dordrecht en Omstreken'},
              {text: 'Gemeenschappelijke Regeling Gemeentelijk Belastingkantoor Twente', value: 'Gemeenschappelijke Regeling Gemeentelijk Belastingkantoor Twente'},
              {text: 'Gemeentelijke gezondheidsdienst Amsterdam-Amstelland', value: 'Gemeentelijke gezondheidsdienst Amsterdam-Amstelland'},
              {text: 'Gemeentelijke Gezondheidsdienst en Veilig Thuis Haaglanden', value: 'Gemeentelijke Gezondheidsdienst en Veilig Thuis Haaglanden'},
              {text: 'Gemeentelijke Gezondheidsdienst Flevoland', value: 'Gemeentelijke Gezondheidsdienst Flevoland'},
              {text: 'Gemeentelijke Gezondheidsdienst Gelderland-Zuid', value: 'Gemeentelijke Gezondheidsdienst Gelderland-Zuid'},
              {text: 'Gemeentelijke Gezondheidsdienst Hollands Noorden', value: 'Gemeentelijke Gezondheidsdienst Hollands Noorden'},
              {text: 'Gemeentelijke Gezondheidsdienst Noord- en Oost-Gelderland', value: 'Gemeentelijke Gezondheidsdienst Noord- en Oost-Gelderland'},
              {text: 'Gemeentelijke Gezondheidsdienst Regio Utrecht', value: 'Gemeentelijke Gezondheidsdienst Regio Utrecht'},
              {text: 'Gemeentelijke Gezondheidsdienst Rotterdam-Rijnmond', value: 'Gemeentelijke Gezondheidsdienst Rotterdam-Rijnmond'},
              {text: 'Gemeentelijke Kredietbank Drenthe', value: 'Gemeentelijke Kredietbank Drenthe'},
              {text: 'Geneeskundige Gezondheidsdienst Zuid-Limburg', value: 'Geneeskundige Gezondheidsdienst Zuid-Limburg'},
              {text: 'Gemeenschappelijke regeling Geul en Maas', value: 'Gemeenschappelijke regeling Geul en Maas'},
              {text: 'GGD Brabant-Zuidoost', value: 'GGD Brabant-Zuidoost'},
              {text: 'GGD Drenthe', value: 'GGD Drenthe'},
              {text: 'GGD Groningen', value: 'GGD Groningen'},
              {text: 'GGD Hart voor Brabant', value: 'GGD Hart voor Brabant'},
              {text: 'GGD IJsselland', value: 'GGD IJsselland'},
              {text: 'GGD West-Brabant', value: 'GGD West-Brabant'},
              {text: 'GO Beschermd Wonen', value: 'GO Beschermd Wonen'},
              {text: 'GR Bleizo', value: 'GR Bleizo'},
              {text: 'GR Industrieschap Medel', value: 'GR Industrieschap Medel'},
              {text: 'GR Nieuw Reijerwaard', value: 'GR Nieuw Reijerwaard'},
              {text: 'Groenalliantie Midden-Holland en omstreken', value: 'Groenalliantie Midden-Holland en omstreken'},
              {text: 'Groene Metropoolregio Arnhem-Nijmegen', value: 'Groene Metropoolregio Arnhem-Nijmegen'},
              {text: 'Groengebied Amstelland', value: 'Groengebied Amstelland'},
              {text: 'Grondbank RZG Zuidplas', value: 'Grondbank RZG Zuidplas'},
              {text: 'Havenschap Groningen Seaports', value: 'Havenschap Groningen Seaports'},
              {text: 'Havenschap Moerdijk', value: 'Havenschap Moerdijk'},
              {text: 'Heesch West', value: 'Heesch West'},
              {text: 'Het Flevolands Archief', value: 'Het Flevolands Archief'},
              {text: 'Het Gegevenshuis', value: 'Het Gegevenshuis'},
              {text: 'Het Industrieschap De Plaspoelpolder', value: 'Het Industrieschap De Plaspoelpolder'},
              {text: 'het participatiebedrijf', value: 'het participatiebedrijf'},
              {text: 'Het Utrechts Archief', value: 'Het Utrechts Archief'},
              {text: 'Het Waterschapshuis', value: 'Het Waterschapshuis'},
              {text: 'het Werkgeversservicepunt Parkstad', value: 'het Werkgeversservicepunt Parkstad'},
              {text: 'Het werkvoorzieningschap Frysl√¢n-West', value: 'Het werkvoorzieningschap Frysl√¢n-West'},
              {text: 'het Werkvoorzieningschap Oostelijk Zuid Limburg', value: 'het Werkvoorzieningschap Oostelijk Zuid Limburg'},
              {text: 'Historisch Centrum Limburg', value: 'Historisch Centrum Limburg'},
              {text: 'Historisch Centrum Overijssel', value: 'Historisch Centrum Overijssel'},
              {text: 'ICT Noord- en Midden-Limburg', value: 'ICT Noord- en Midden-Limburg'},
              {text: 'ICT samenwerking West-Brabant-West', value: 'ICT samenwerking West-Brabant-West'},
              {text: 'IGUO', value: 'IGUO'},
              {text: 'IJmond Werkt!', value: 'IJmond Werkt!'},
              {text: 'IJSSELgemeenten', value: 'IJSSELgemeenten'},
              {text: 'Ingenieursbureau Krimpenerwaard', value: 'Ingenieursbureau Krimpenerwaard'},
              {text: 'Instituut voor Sociale Werkvoorziening Zuid-Oost Utrecht', value: 'Instituut voor Sociale Werkvoorziening Zuid-Oost Utrecht'},
              {text: 'Intergemeentelijke Sociale Dienst (ISD) Bollenstreek', value: 'Intergemeentelijke Sociale Dienst (ISD) Bollenstreek'},
              {text: 'Intergemeentelijke Sociale Dienst Brabantse Wal', value: 'Intergemeentelijke Sociale Dienst Brabantse Wal'},
              {text: 'ISD BOL', value: 'ISD BOL'},
              {text: 'Kredietbank Limburg', value: 'Kredietbank Limburg'},
              {text: 'Lucrato', value: 'Lucrato'},
              {text: 'Meerinzicht', value: 'Meerinzicht'},
              {text: 'Meerschap Paterswolde', value: 'Meerschap Paterswolde'},
              {text: 'Meldkamer Oost Nederland', value: 'Meldkamer Oost Nederland'},
              {text: 'Metropoolregio Eindhoven', value: 'Metropoolregio Eindhoven'},
              {text: 'Metropoolregio Rotterdam Den Haag', value: 'Metropoolregio Rotterdam Den Haag'},
              {text: 'MijnGemeenteDichtbij', value: 'MijnGemeenteDichtbij'},
              {text: 'Milieusamenwerking en Afvalverwerking Regio Nijmegen', value: 'Milieusamenwerking en Afvalverwerking Regio Nijmegen'},
              {text: 'Mobiliteitsbureau Noordoost Frysl√¢n', value: 'Mobiliteitsbureau Noordoost Frysl√¢n'},
              {text: 'Modulaire gemeenschappelijke regeling Rijk van Nijmegen', value: 'Modulaire gemeenschappelijke regeling Rijk van Nijmegen'},
              {text: 'Modulaire gemeenschappelijke regeling sociaal domein centraal Gelderland', value: 'Modulaire gemeenschappelijke regeling sociaal domein centraal Gelderland'},
              {text: 'Modulaire gemeenschappelijke regeling sociaal domein Limburg-Noord', value: 'Modulaire gemeenschappelijke regeling sociaal domein Limburg-Noord'},
              {text: 'Natuur- en recreatieschap IJsselmonde', value: 'Natuur- en recreatieschap IJsselmonde'},
              {text: 'Natuur- en Recreatieschap Lingegebied', value: 'Natuur- en Recreatieschap Lingegebied'},
              {text: 'Nazorg gesloten stortplaatsen Bavel-Dorst en Zevenbergen', value: 'Nazorg gesloten stortplaatsen Bavel-Dorst en Zevenbergen'},
              {text: 'Noaberkracht Dinkelland Tubbergen', value: 'Noaberkracht Dinkelland Tubbergen'},
              {text: 'Noord-Hollands Archief', value: 'Noord-Hollands Archief'},
              {text: 'Noordelijk Belastingkantoor', value: 'Noordelijk Belastingkantoor'},
              {text: 'Ombudsman Metropool Amsterdam', value: 'Ombudsman Metropool Amsterdam'},
              {text: 'Omgevingsdienst Achterhoek', value: 'Omgevingsdienst Achterhoek'},
              {text: 'Omgevingsdienst Brabant Noord', value: 'Omgevingsdienst Brabant Noord'},
              {text: 'Omgevingsdienst de Vallei', value: 'Omgevingsdienst de Vallei'},
              {text: 'Omgevingsdienst Flevoland & Gooi en Vechtstreek', value: 'Omgevingsdienst Flevoland & Gooi en Vechtstreek'},
              {text: 'Omgevingsdienst Groningen', value: 'Omgevingsdienst Groningen'},
              {text: 'Omgevingsdienst Haaglanden', value: 'Omgevingsdienst Haaglanden'},
              {text: 'Omgevingsdienst IJmond', value: 'Omgevingsdienst IJmond'},
              {text: 'Omgevingsdienst IJsselland', value: 'Omgevingsdienst IJsselland'},
              {text: 'Omgevingsdienst Midden- en West-Brabant', value: 'Omgevingsdienst Midden- en West-Brabant'},
              {text: 'Omgevingsdienst Midden-Holland', value: 'Omgevingsdienst Midden-Holland'},
              {text: 'Omgevingsdienst Noord-Holland Noord', value: 'Omgevingsdienst Noord-Holland Noord'},
              {text: 'Omgevingsdienst Noordzeekanaalgebied', value: 'Omgevingsdienst Noordzeekanaalgebied'},
              {text: 'Omgevingsdienst Regio Arnhem', value: 'Omgevingsdienst Regio Arnhem'},
              {text: 'Omgevingsdienst Regio Nijmegen', value: 'Omgevingsdienst Regio Nijmegen'},
              {text: 'Omgevingsdienst regio Utrecht', value: 'Omgevingsdienst regio Utrecht'},
              {text: 'Omgevingsdienst Rivierenland', value: 'Omgevingsdienst Rivierenland'},
              {text: 'Omgevingsdienst Twente', value: 'Omgevingsdienst Twente'},
              {text: 'Omgevingsdienst West-Holland', value: 'Omgevingsdienst West-Holland'},
              {text: 'Omgevingsdienst Zuid-Holland Zuid', value: 'Omgevingsdienst Zuid-Holland Zuid'},
              {text: 'Omgevingsdienst Zuid-Limburg', value: 'Omgevingsdienst Zuid-Limburg'},
              {text: 'Omgevingsdienst Zuidoost-Brabant', value: 'Omgevingsdienst Zuidoost-Brabant'},
              {text: 'Omnibuzz', value: 'Omnibuzz'},
              {text: 'Ontwikkelingsmaatschappij Bedrijvenparken A7 Heerenveen-Joure', value: 'Ontwikkelingsmaatschappij Bedrijvenparken A7 Heerenveen-Joure'},
              {text: 'Openbaar Lichaam Afvalstoffenverwijdering Zeeland', value: 'Openbaar Lichaam Afvalstoffenverwijdering Zeeland'},
              {text: 'Openbaar Lichaam Crematoria Twente', value: 'Openbaar Lichaam Crematoria Twente'},
              {text: 'Openbaar lichaam Gesubsidieerde Arbeid Kop van Noord-Holland', value: 'Openbaar lichaam Gesubsidieerde Arbeid Kop van Noord-Holland'},
              {text: 'Openbaar Lichaam Gezamenlijke Brandweer', value: 'Openbaar Lichaam Gezamenlijke Brandweer'},
              {text: 'Openbaar Lichaam Jeugdhulp Rijnmond', value: 'Openbaar Lichaam Jeugdhulp Rijnmond'},
              {text: 'Openbaar Lichaam OV-bureau van de gemeente Groningen en de provincies Groningen en Drenthe', value: 'Openbaar Lichaam OV-bureau van de gemeente Groningen en de provincies Groningen en Drenthe'},
              {text: 'Openbaar lichaam Slibverwerking', value: 'Openbaar lichaam Slibverwerking'},
              {text: 'Openbaar Lichaam Vuilverwerking Noord Oost Achterhoek', value: 'Openbaar Lichaam Vuilverwerking Noord Oost Achterhoek'},
              {text: 'Openbare gezondheidszorg Amstelland', value: 'Openbare gezondheidszorg Amstelland'},
              {text: 'Orgaan Bereikbaarheid Zuid-Kennemerland', value: 'Orgaan Bereikbaarheid Zuid-Kennemerland'},
              {text: 'Orionis Walcheren', value: 'Orionis Walcheren'},
              {text: 'OVER-gemeenten', value: 'OVER-gemeenten'},
              {text: 'Parkorganisatie', value: 'Parkorganisatie'},
              {text: 'Participatiebedrijf KempenPlus', value: 'Participatiebedrijf KempenPlus'},
              {text: 'Participatiebedrijf Zuid-Kennemerland', value: 'Participatiebedrijf Zuid-Kennemerland'},
              {text: 'Peelgemeenten', value: 'Peelgemeenten'},
              {text: 'Plassenschap Loosdrecht e.o.', value: 'Plassenschap Loosdrecht e.o.'},
            {text: 'Plusteam', value: 'Plusteam'},
            {text: 'Prolander', value: 'Prolander'},
            {text: 'Promen', value: 'Promen'},
            {text: 'Randstedelijke Rekenkamer', value: 'Randstedelijke Rekenkamer'},
            {text: 'Recreatieschap Alkmaarder- en Uitgeestermeer', value: 'Recreatieschap Alkmaarder- en Uitgeestermeer'},
            {text: 'Recreatieschap Drenthe', value: 'Recreatieschap Drenthe'},
            {text: 'Recreatieschap Geestmerambacht', value: 'Recreatieschap Geestmerambacht'},
            {text: 'Recreatieschap Hitland', value: 'Recreatieschap Hitland'},
            {text: 'Recreatieschap Over-Betuwe', value: 'Recreatieschap Over-Betuwe'},
            {text: 'Recreatieschap Rottemeren', value: 'Recreatieschap Rottemeren'},
            {text: 'Recreatieschap Spaarnwoude', value: 'Recreatieschap Spaarnwoude'},
            {text: 'Recreatieschap Stichtse Groenlanden', value: 'Recreatieschap Stichtse Groenlanden'},
            {text: 'Recreatieschap Twente', value: 'Recreatieschap Twente'},
            {text: 'Recreatieschap Twiske-Waterland', value: 'Recreatieschap Twiske-Waterland'},
            {text: 'Recreatieschap voor het Friese water en land de Marrekrite', value: 'Recreatieschap voor het Friese water en land de Marrekrite'},
            {text: 'Recreatieschap Voorne-Putten', value: 'Recreatieschap Voorne-Putten'},
            {text: 'Recreatieschap Westfriesland', value: 'Recreatieschap Westfriesland'},
            {text: 'Reestmond', value: 'Reestmond'},
            {text: 'Regio Achterhoek', value: 'Regio Achterhoek'},
            {text: 'Regio De Vallei', value: 'Regio De Vallei'},
            {text: 'Regio Foodvalley', value: 'Regio Foodvalley'},
            {text: 'Regio Gooi en Vechtstreek', value: 'Regio Gooi en Vechtstreek'},
            {text: 'Regio Hart van Brabant', value: 'Regio Hart van Brabant'},
            {text: 'Regio Midden-Holland', value: 'Regio Midden-Holland'},
            {text: 'Regio Rivierenland', value: 'Regio Rivierenland'},
            {text: 'regio Stedendriehoek', value: 'regio Stedendriehoek'},
            {text: 'Regio West-Brabant', value: 'Regio West-Brabant'},
            {text: 'Regio-AV', value: 'Regio-AV'},
            {text: 'Regionaal Archief Rivierenland', value: 'Regionaal Archief Rivierenland'},
            {text: 'Regionaal Archief Zuid-Utrecht', value: 'Regionaal Archief Zuid-Utrecht'},
            {text: 'Regionaal Bedrijventerrein Twente', value: 'Regionaal Bedrijventerrein Twente'},
            {text: 'Regionaal Historisch Centrum Alkmaar', value: 'Regionaal Historisch Centrum Alkmaar'},
            {text: 'Regionaal Historisch Centrum Rijnstreek en Lopikerwaard', value: 'Regionaal Historisch Centrum Rijnstreek en Lopikerwaard'},
            {text: 'Regionaal samenwerkingsorgaan openbaar basisonderwijs De Kempen', value: 'Regionaal samenwerkingsorgaan openbaar basisonderwijs De Kempen'},
            {text: 'Regionaal Serviceteam Jeugd IJsselland', value: 'Regionaal Serviceteam Jeugd IJsselland'},
            {text: 'Regionaal Sociaal Werkvoorzieningschap Amersfoort en omgeving RWA', value: 'Regionaal Sociaal Werkvoorzieningschap Amersfoort en omgeving RWA'},
            {text: 'Regionale Ambulancevoorziening Brabant Midden-West-Noord', value: 'Regionale Ambulancevoorziening Brabant Midden-West-Noord'},
            {text: 'Regionale Belasting Groep', value: 'Regionale Belasting Groep'},
            {text: 'Regionale dienst openbare gezondheidszorg Hollands Midden', value: 'Regionale dienst openbare gezondheidszorg Hollands Midden'},
            {text: 'Regionale Dienst Werk en Inkomen Kromme Rijn Heuvelrug', value: 'Regionale Dienst Werk en Inkomen Kromme Rijn Heuvelrug'},
            {text: 'Regionale Uitvoeringsdienst Drenthe', value: 'Regionale Uitvoeringsdienst Drenthe'},
            {text: 'Regionale Uitvoeringsdienst Limburg-Noord', value: 'Regionale Uitvoeringsdienst Limburg-Noord'},
            {text: 'Regionale uitvoeringsdienst Utrecht 2.0', value: 'Regionale uitvoeringsdienst Utrecht 2.0'},
            {text: 'Regionale uitvoeringsdienst Zeeland', value: 'Regionale uitvoeringsdienst Zeeland'},
            {text: 'Reinigingsbedrijf Midden Nederland', value: 'Reinigingsbedrijf Midden Nederland'},
            {text: 'Reinigingsdienst De Liemers', value: 'Reinigingsdienst De Liemers'},
            {text: 'Reinigingsdienst Maasland', value: 'Reinigingsdienst Maasland'},
            {text: 'Reinigingsdienst Waardlanden', value: 'Reinigingsdienst Waardlanden'},
            {text: 'Reinigingsdiensten Rd4', value: 'Reinigingsdiensten Rd4'},
            {text: 'ReinUnie', value: 'ReinUnie'},
            {text: 'Rekenkamer Oost-Nederland', value: 'Rekenkamer Oost-Nederland'},
            {text: 'RHC Drents Archief', value: 'RHC Drents Archief'},
            {text: 'RID', value: 'RID'},
            {text: 'Rogplus', value: 'Rogplus'},
            {text: 'Sallcon', value: 'Sallcon'},
            {text: 'SamenTwente', value: 'SamenTwente'},
            {text: 'Samenwerking A2-gemeenten', value: 'Samenwerking A2-gemeenten'},
            {text: 'Samenwerking De Bevelanden', value: 'Samenwerking De Bevelanden'},
            {text: 'Samenwerking De Liemers', value: 'Samenwerking De Liemers'},
            {text: 'Samenwerking Kempengemeenten', value: 'Samenwerking Kempengemeenten'},
            {text: 'Samenwerkingsorgaan Holland Rijnland', value: 'Samenwerkingsorgaan Holland Rijnland'},
            {text: 'Samenwerkingsorganisatie De Wolden Hoogeveen', value: 'Samenwerkingsorganisatie De Wolden Hoogeveen'},
            {text: 'Samenwerkingsorganisatie Publiek Vervoer Groningen Drenthe', value: 'Samenwerkingsorganisatie Publiek Vervoer Groningen Drenthe'},
            {text: 'Samenwerkingsverband afvalstoffenverwijdering Oost- en Zuidoost Groningen', value: 'Samenwerkingsverband afvalstoffenverwijdering Oost- en Zuidoost Groningen'},
            {text: 'Samenwerkingsverband Collectief Vervoer Zeeuws-Vlaanderen', value: 'Samenwerkingsverband Collectief Vervoer Zeeuws-Vlaanderen'},
            {text: 'Samenwerkingsverband kleinschalig collectief vervoer Brabant-Noordoost', value: 'Samenwerkingsverband kleinschalig collectief vervoer Brabant-Noordoost'},
            {text: 'Samenwerkingsverband Noord-Nederland', value: 'Samenwerkingsverband Noord-Nederland'},
            {text: 'Samenwerkingsverband Welzijnszorg Oosterschelderegio', value: 'Samenwerkingsverband Welzijnszorg Oosterschelderegio'},
            {text: 'SED organisatie', value: 'SED organisatie'},
            {text: 'Senzer', value: 'Senzer'},
            {text: 'Servicebureau Jeugdhulp Haaglanden', value: 'Servicebureau Jeugdhulp Haaglanden'},
            {text: 'Servicecentrum MER', value: 'Servicecentrum MER'},
            {text: 'Serviceorganisatie Zorg Holland Rijnland', value: 'Serviceorganisatie Zorg Holland Rijnland'},
            {text: 'Shared Service Center DeSom', value: 'Shared Service Center DeSom'},
            {text: 'Shared Service Centrum ONS', value: 'Shared Service Centrum ONS'},
            {text: 'Sociaal', value: 'Sociaal'},
            {text: 'Sociaal Werkvoorzieningsschap DETHON', value: 'Sociaal Werkvoorzieningsschap DETHON'},
            {text: 'Sociale werkvoorziening Frysl√¢n', value: 'Sociale werkvoorziening Frysl√¢n'},
            {text: 'SPOOR-raad', value: 'SPOOR-raad'},
            {text: 'Sportpark Souburgh', value: 'Sportpark Souburgh'},
            {text: 'Stadsbank Oost Nederland', value: 'Stadsbank Oost Nederland'},
            {text: 'Stadsgewest \'s-Hertogenbosch', value: 'Stadsgewest \'s-Hertogenbosch'},
            {text: 'Stadsgewestelijke brandweer Vlissingen-Middelburg', value: 'Stadsgewestelijke brandweer Vlissingen-Middelburg'},
            {text: 'Stadsregio Parkstad Limburg', value: 'Stadsregio Parkstad Limburg'},
            {text: 'Stark', value: 'Stark'},
            {text: 'Streekarchief Langstraat Heusden Altena', value: 'Streekarchief Langstraat Heusden Altena'},
            {text: 'Streekarchief Midden-Holland', value: 'Streekarchief Midden-Holland'},
            {text: 'Streekarchief Voorne-Putten', value: 'Streekarchief Voorne-Putten'},
            {text: 'Streekarchivariaat de Liemers en Doesburg', value: 'Streekarchivariaat de Liemers en Doesburg'},
            {text: 'Stroomopwaarts MVS', value: 'Stroomopwaarts MVS'},
            {text: 'SWB Midden Twente', value: 'SWB Midden Twente'},
            {text: 'Syntrophos', value: 'Syntrophos'},
            {text: 'Technology Base', value: 'Technology Base'},
            {text: 'Toezichthoudend orgaan openbaar primair onderwijs Olst-Wijhe en Raalte', value: 'Toezichthoudend orgaan openbaar primair onderwijs Olst-Wijhe en Raalte'},
            {text: 'Tresoar', value: 'Tresoar'},
            {text: 'Tribuut belastingsamenwerking', value: 'Tribuut belastingsamenwerking'},
            {text: 'Uitvoeringsorganisatie Baanbrekers', value: 'Uitvoeringsorganisatie Baanbrekers'},
            {text: 'Uitvoeringsorganisatie Breedbandnetwerk Rivierenland', value: 'Uitvoeringsorganisatie Breedbandnetwerk Rivierenland'},
            {text: 'Uitvoeringsorganisatie Laborijn', value: 'Uitvoeringsorganisatie Laborijn'},
            {text: 'Veiligheids- en Gezondheidsregio Gelderland-Midden', value: 'Veiligheids- en Gezondheidsregio Gelderland-Midden'},
            {text: 'Veiligheidsregio Amsterdam-Amstelland', value: 'Veiligheidsregio Amsterdam-Amstelland'},
            {text: 'Veiligheidsregio Brabant-Noord', value: 'Veiligheidsregio Brabant-Noord'},
            {text: 'Veiligheidsregio Brabant-Zuidoost', value: 'Veiligheidsregio Brabant-Zuidoost'},
            {text: 'Veiligheidsregio Drenthe', value: 'Veiligheidsregio Drenthe'},
            {text: 'Veiligheidsregio en Gemeentelijke Gezondheidsdienst Limburg-Noord', value: 'Veiligheidsregio en Gemeentelijke Gezondheidsdienst Limburg-Noord'},
            {text: 'Veiligheidsregio Flevoland', value: 'Veiligheidsregio Flevoland'},
            {text: 'Veiligheidsregio Frysl√¢n', value: 'Veiligheidsregio Frysl√¢n'},
            {text: 'Veiligheidsregio Gelderland-Zuid', value: 'Veiligheidsregio Gelderland-Zuid'},
            {text: 'Veiligheidsregio Gooi en Vechtstreek', value: 'Veiligheidsregio Gooi en Vechtstreek'},
            {text: 'Veiligheidsregio Groningen', value: 'Veiligheidsregio Groningen'},
            {text: 'Veiligheidsregio Haaglanden', value: 'Veiligheidsregio Haaglanden'},
            {text: 'Veiligheidsregio Hollands Midden', value: 'Veiligheidsregio Hollands Midden'},
            {text: 'Veiligheidsregio IJsselland', value: 'Veiligheidsregio IJsselland'},
            {text: 'Veiligheidsregio Kennemerland', value: 'Veiligheidsregio Kennemerland'},
            {text: 'Veiligheidsregio Midden- en West-Brabant', value: 'Veiligheidsregio Midden- en West-Brabant'},
            {text: 'Veiligheidsregio Noord- en Oost-Gelderland', value: 'Veiligheidsregio Noord- en Oost-Gelderland'},
            {text: 'Veiligheidsregio Noord-Holland Noord', value: 'Veiligheidsregio Noord-Holland Noord'},
            {text: 'Veiligheidsregio Rotterdam-Rijnmond', value: 'Veiligheidsregio Rotterdam-Rijnmond'},
            {text: 'Veiligheidsregio Twente', value: 'Veiligheidsregio Twente'},
            {text: 'Veiligheidsregio Utrecht', value: 'Veiligheidsregio Utrecht'},
            {text: 'Veiligheidsregio Zaanstreek-Waterland', value: 'Veiligheidsregio Zaanstreek-Waterland'},
            {text: 'Veiligheidsregio Zeeland', value: 'Veiligheidsregio Zeeland'},
            {text: 'Veiligheidsregio Zuid-Holland Zuid', value: 'Veiligheidsregio Zuid-Holland Zuid'},
            {text: 'Veiligheidsregio Zuid-Limburg', value: 'Veiligheidsregio Zuid-Limburg'},
            {text: 'Vervoerregio Amsterdam', value: 'Vervoerregio Amsterdam'},
            {text: 'Vervoerscentrale Stedendriehoek', value: 'Vervoerscentrale Stedendriehoek'},
            {text: 'Volkskredietbank Noord-Oost Groningen', value: 'Volkskredietbank Noord-Oost Groningen'},
            {text: 'Vuilafvoerbedrijf Duin- en Bollenstreek', value: 'Vuilafvoerbedrijf Duin- en Bollenstreek'},
            {text: 'Vuilverbrandingsinstallatie Alkmaar en Omstreken', value: 'Vuilverbrandingsinstallatie Alkmaar en Omstreken'},
            {text: 'Waddenfonds', value: 'Waddenfonds'},
            {text: 'Waterlands Archief', value: 'Waterlands Archief'},
            {text: 'Wedeka Bedrijven', value: 'Wedeka Bedrijven'},
            {text: 'Wegschap Tunnel Dordtse Kil', value: 'Wegschap Tunnel Dordtse Kil'},
            {text: 'Werk en Inkomen Lekstroom', value: 'Werk en Inkomen Lekstroom'},
            {text: 'Werk-/Leerbedrijf NoardEast Frysl√¢n', value: 'Werk-/Leerbedrijf NoardEast Frysl√¢n'},
            {text: 'Werkbedrijf Arbeid Voor Allen', value: 'Werkbedrijf Arbeid Voor Allen'},
            {text: 'Werkmaatschappij 8KTD', value: 'Werkmaatschappij 8KTD'},
            {text: 'Werkorganisatie BUCH', value: 'Werkorganisatie BUCH'},
            {text: 'Werkorganisatie Druten Wijchen', value: 'Werkorganisatie Druten Wijchen'},
            {text: 'Werkorganisatie HLT Samen', value: 'Werkorganisatie HLT Samen'},
            {text: 'Werkplein Drentsche Aa', value: 'Werkplein Drentsche Aa'},
            {text: 'Werkplein Hart van West-Brabant', value: 'Werkplein Hart van West-Brabant'},
            {text: 'WerkSaam Westfriesland', value: 'WerkSaam Westfriesland'},
            {text: 'Werkvoorzieningschap Kampen & Dronten', value: 'Werkvoorzieningschap Kampen & Dronten'},
            {text: 'Werkvoorzieningschap lJsselmeergroep', value: 'Werkvoorzieningschap lJsselmeergroep'},
            {text: 'Werkvoorzieningschap Noord-Limburg West', value: 'Werkvoorzieningschap Noord-Limburg West'},
            {text: 'Werkvoorzieningschap Noordoost-Brabant', value: 'Werkvoorzieningschap Noordoost-Brabant'},
            {text: 'Werkvoorzieningschap regio Eindhoven', value: 'Werkvoorzieningschap regio Eindhoven'},
            {text: 'Werkvoorzieningschap Risse Groep', value: 'Werkvoorzieningschap Risse Groep'},
            {text: 'Werkvoorzieningschap Venlo en omstreken', value: 'Werkvoorzieningschap Venlo en omstreken'},
            {text: 'Werkvoorzieningsschap Tomingroep', value: 'Werkvoorzieningsschap Tomingroep'},
            {text: 'Werkzaak Rivierenland', value: 'Werkzaak Rivierenland'},
            {text: 'West-Brabants Archief', value: 'West-Brabants Archief'},
            {text: 'Westfries Archief', value: 'Westfries Archief'},
            {text: 'Westrom', value: 'Westrom'},
            {text: 'WSD', value: 'WSD'},
            {text: 'WVS', value: 'WVS'},
            {text: 'Zaffier', value: 'Zaffier'},
            {text: 'Zeeuws archief', value: 'Zeeuws archief'},
            {text: 'Zorg- en Veiligheidshuis Parkstad', value: 'Zorg- en Veiligheidshuis Parkstad'},
            {text: 'Zuidelijke Rekenkamer Noord-Brabant en Limburg', value: 'Zuidelijke Rekenkamer Noord-Brabant en Limburg'},
            ];
          }
          else if (selectedCategory === "Waterschap") {
            choices = [
              { value: "Aa en Maas", text: "Aa en Maas" },
              { value: "Amstel, Gooi en Vecht", text: "Amstel, Gooi en Vecht" },
              { value: "Brabantse Delta", text: "Brabantse Delta" },
              { value: "De Dommel", text: "De Dommel" },
              { value: "Drents Overijsselse Delta", text: "Drents Overijsselse Delta" },
              { value: "Hollandse Delta", text: "Hollandse Delta" },
              { value: "Hoogheemraadschap De Stichtse Rijnlanden", text: "Hoogheemraadschap De Stichtse Rijnlanden" },
              { value: "Hoogheemraadschap Hollands Noorderkwartier", text: "Hoogheemraadschap Hollands Noorderkwartier" },
              { value: "Hoogheemraadschap van Delfland", text: "Hoogheemraadschap van Delfland" },
              { value: "Hoogheemraadschap van Rijnland", text: "Hoogheemraadschap van Rijnland" },
              { value: "Hoogheemraadschap van Schieland en de Krimpenerwaard", text: "Hoogheemraadschap van Schieland en de Krimpenerwaard" },
              { value: "Hunze en Aa's", text: "Hunze en Aa's" },
              { value: "Noorderzijlvest", text: "Noorderzijlvest" },
              { value: "Rijn en IJssel", text: "Rijn en IJssel" },
              { value: "Rivierenland", text: "Rivierenland" },
              { value: "Scheldestromen", text: "Scheldestromen" },
              { value: "Vallei en Veluwe", text: "Vallei en Veluwe" },
              { value: "Vechtstromen", text: "Vechtstromen" },
              { value: "Waterschap Limburg", text: "Waterschap Limburg" },
              { value: "Wetterskip Fryslân", text: "Wetterskip Fryslân" },
              { value: "Zuiderzeeland", text: "Zuiderzeeland" }
            ];
          }
          else if (selectedCategory === "Zelfstandig bestuursorgaan") {

            choices = [
              {text: 'Nederlandse Loodsencorporatie', value: 'Nederlandse Loodsencorporatie'},
              {text: 'Aangemelde instanties ex Regeling overige pyrotechnische artikelen', value: 'Aangemelde instanties ex Regeling overige pyrotechnische artikelen'},
              {text: 'Aangewezen examenorganisatie ex art. 1 lid 1 onder qqq en rrr Geneesmiddelenwet jo. Beleidsregels aanwijzing examenorganisaties drogisterijbranche jo. regeling.', value: 'Aangewezen examenorganisatie ex art. 1 lid 1 onder qqq en rrr Geneesmiddelenwet jo. Beleidsregels aanwijzing examenorganisaties drogisterijbranche jo. regeling.'},
              {text: 'Aangewezen instanties in het kader van Metrologiewet artikel 12', value: 'Aangewezen instanties in het kader van Metrologiewet artikel 12'},
              {text: 'Aangewezen/aangemelde instanties (dezelfde) ex art. 1a.5.1 Vuurwerkbesluit', value: 'Aangewezen/aangemelde instanties (dezelfde) ex art. 1a.5.1 Vuurwerkbesluit'},
              {text: 'Airport Coordination Netherlands', value: 'Airport Coordination Netherlands'},
              {text: 'Autoriteit Consument en Markt', value: 'Autoriteit Consument en Markt'},
              {text: 'Autoriteit Financi√´le Markten', value: 'Autoriteit Financi√´le Markten'},
              {text: 'Autoriteit Nucleaire Veiligheid en Stralingsbescherming', value: 'Autoriteit Nucleaire Veiligheid en Stralingsbescherming'},
              {text: 'Autoriteit online Terroristisch en Kinderpornografisch Materiaal', value: 'Autoriteit online Terroristisch en Kinderpornografisch Materiaal'},
              {text: 'Autoriteit Persoonsgegevens', value: 'Autoriteit Persoonsgegevens'},
              {text: 'Bedrijfstakpensioenfondsen', value: 'Bedrijfstakpensioenfondsen'},
              {text: 'Bevoegde autoriteiten Rijnvaart', value: 'Bevoegde autoriteiten Rijnvaart'},
              {text: 'Stichting Blik op Werk', value: 'Stichting Blik op Werk'},
              {text: 'Bureau Architectenregister', value: 'Bureau Architectenregister'},
              {text: 'Bureau Beheer Landbouwgronden', value: 'Bureau Beheer Landbouwgronden'},
              {text: 'Bureau Financieel Toezicht', value: 'Bureau Financieel Toezicht'},
              {text: 'CAK', value: 'CAK'},
              {text: 'Centraal Bureau Rijvaardigheidsbewijzen', value: 'Centraal Bureau Rijvaardigheidsbewijzen'},
              {text: 'Centraal Bureau voor de Statistiek', value: 'Centraal Bureau voor de Statistiek'},
              {text: 'Centraal Orgaan opvang asielzoekers', value: 'Centraal Orgaan opvang asielzoekers'},
              {text: 'Centrale Commissie Dierproeven', value: 'Centrale Commissie Dierproeven'},
              {text: 'Centrale Commissie Mensgebonden Onderzoek', value: 'Centrale Commissie Mensgebonden Onderzoek'},
              {text: 'Certificerende instellingen Arbeidsomstandighedenwet', value: 'Certificerende instellingen Arbeidsomstandighedenwet'},
              {text: 'CIZ', value: 'CIZ'},
              {text: 'College gerechtelijk deskundigen', value: 'College gerechtelijk deskundigen'},
              {text: 'College sanering zorginstellingen', value: 'College sanering zorginstellingen'},
              {text: 'College ter Beoordeling van Geneesmiddelen', value: 'College ter Beoordeling van Geneesmiddelen'},
              {text: 'College van deskundigen ex Besluit en regeling Bodemkwaliteit', value: 'College van deskundigen ex Besluit en regeling Bodemkwaliteit'},
              {text: 'College van toezicht collectieve beheersorganisaties auteurs- en naburige rechten', value: 'College van toezicht collectieve beheersorganisaties auteurs- en naburige rechten'},
              {text: 'College voor de Rechten van de Mens', value: 'College voor de Rechten van de Mens'},
              {text: 'College voor de toelating van gewasbeschermingsmiddelen en biociden', value: 'College voor de toelating van gewasbeschermingsmiddelen en biociden'},
              {text: 'College voor Toetsen en Examens', value: 'College voor Toetsen en Examens'},
              {text: 'Commissariaat voor de Media', value: 'Commissariaat voor de Media'},
              {text: 'Commissie Eindtermen Accountantsopleiding', value: 'Commissie Eindtermen Accountantsopleiding'},
              {text: 'Commissie schadefonds geweldsmisdrijven', value: 'Commissie schadefonds geweldsmisdrijven'},
              {text: 'Stichting Controle Orgaan Kwaliteits Zaken', value: 'Stichting Controle Orgaan Kwaliteits Zaken'},
              {text: 'De Nederlandsche Bank', value: 'De Nederlandsche Bank'},
              {text: 'Dienst voor het kadaster en de openbare registers', value: 'Dienst voor het kadaster en de openbare registers'},
              {text: 'Dopingautoriteit', value: 'Dopingautoriteit'},
              {text: 'Erkenninghouders Algemene Periodieke Keuring', value: 'Erkenninghouders Algemene Periodieke Keuring'},
              {text: 'FMMU Advies B.V', value: 'FMMU Advies B.V'},
              {text: 'Garantiewetcommissie', value: 'Garantiewetcommissie'},
              {text: 'Gecertificeerde instellingen ex Jeugdwet', value: 'Gecertificeerde instellingen ex Jeugdwet'},
              {text: 'Gerechtsdeurwaarders', value: 'Gerechtsdeurwaarders'},
              {text: 'Stichting Groene Erkenningen', value: 'Stichting Groene Erkenningen'},
              {text: 'Grondkamers', value: 'Grondkamers'},
              {text: 'Grondkamer Noord', value: 'Grondkamer Noord'},
              {text: 'Grondkamer Zuid', value: 'Grondkamer Zuid'},
              {text: 'Grondkamer Oost', value: 'Grondkamer Oost'},
              {text: 'Grondkamer Noordwest', value: 'Grondkamer Noordwest'},
              {text: 'Grondkamer Zuidwest', value: 'Grondkamer Zuidwest'},
              {text: 'Havenbeheerders', value: 'Havenbeheerders'},
              {text: 'Huis voor Klokkenluiders', value: 'Huis voor Klokkenluiders'},
              {text: 'Huurcommissie', value: 'Huurcommissie'},
              {text: 'Inrichtingen ex art. 3a Beginselenwet justiti√´le jeugdinrichtingen', value: 'Inrichtingen ex art. 3a Beginselenwet justiti√´le jeugdinrichtingen'},
              {text: 'Inrichtingen ex Beginselenwet verpleging ter beschikking gestelden', value: 'Inrichtingen ex Beginselenwet verpleging ter beschikking gestelden'},
              {text: 'Instellingen hoger onderwijs en academische ziekenhuizen', value: 'Instellingen hoger onderwijs en academische ziekenhuizen'},
              {text: 'Instituut Mijnbouwschade Groningen', value: 'Instituut Mijnbouwschade Groningen'},
              {text: 'Kamer van Koophandel', value: 'Kamer van Koophandel'},
              {text: 'Kamer voor de Binnenvisserij', value: 'Kamer voor de Binnenvisserij'},
              {text: 'Kansspelautoriteit', value: 'Kansspelautoriteit'},
              {text: 'Keuringsartsen voor de scheepvaart', value: 'Keuringsartsen voor de scheepvaart'},
              {text: 'Keuringsinstanties als bedoeld in artikel 10.9 Telecommunicatiewet', value: 'Keuringsinstanties als bedoeld in artikel 10.9 Telecommunicatiewet'},
              {text: 'Keuringsinstanties bouwproducten', value: 'Keuringsinstanties bouwproducten'},
              {text: 'Keuringsinstanties Certificerende instellingen ex Erfgoedwet', value: 'Keuringsinstanties Certificerende instellingen ex Erfgoedwet'},
              {text: 'Keuringsinstanties ex 7a Warenwet', value: 'Keuringsinstanties ex 7a Warenwet'},
              {text: 'Keuringsinstanties ex Besluit en Regeling Bodemkwaliteit', value: 'Keuringsinstanties ex Besluit en Regeling Bodemkwaliteit'},
              {text: 'Keuringsinstanties ex Regeling particuliere beveiligingsorganisaties en recherchebureaus', value: 'Keuringsinstanties ex Regeling particuliere beveiligingsorganisaties en recherchebureaus'},
              {text: 'Keuringsinstanties ex Regeling vervoerbare drukapparatuur 2011', value: 'Keuringsinstanties ex Regeling vervoerbare drukapparatuur 2011'},
              {text: 'Keuringsinstanties ex Wet medische hulpmiddelen', value: 'Keuringsinstanties ex Wet medische hulpmiddelen'},
              {text: 'Keuringsinstanties ex Wet milieubeheer en ex Regeling handel in emissierechten', value: 'Keuringsinstanties ex Wet milieubeheer en ex Regeling handel in emissierechten'},
              {text: 'Keuringsinstanties geluidshinder', value: 'Keuringsinstanties geluidshinder'},
              {text: 'Keuringsinstanties Infrastructuur & Milieu overig', value: 'Keuringsinstanties Infrastructuur & Milieu overig'},
              {text: 'Keuringsinstanties pleziervaartuigen 2016', value: 'Keuringsinstanties pleziervaartuigen 2016'},
              {text: 'Keuringsinstanties uitrusting zeeschepen', value: 'Keuringsinstanties uitrusting zeeschepen'},
              {text: 'Keuringsinstellingen ex art.4 av Wegenverkeerswet 1994', value: 'Keuringsinstellingen ex art.4 av Wegenverkeerswet 1994'},
              {text: 'Keuringsinstellingen ex Wet explosieven voor civiel gebruik', value: 'Keuringsinstellingen ex Wet explosieven voor civiel gebruik'},
              {text: 'Keurmerkinstituut jeugdzorg', value: 'Keurmerkinstituut jeugdzorg'},
              {text: 'Kiesraad', value: 'Kiesraad'},
              {text: 'Klassenbureaus Scheepvaart', value: 'Klassenbureaus Scheepvaart'},
              {text: 'Koninklijke Bibliotheek', value: 'Koninklijke Bibliotheek'},
              {text: 'Koninklijke Nederlandse Akademie van Wetenschappen', value: 'Koninklijke Nederlandse Akademie van Wetenschappen'},
              {text: 'Koninklijke Nederlandse Toeristenbond ANWB', value: 'Koninklijke Nederlandse Toeristenbond ANWB'},
              {text: 'Landelijk Bureau Inning Onderhoudsbijdragen', value: 'Landelijk Bureau Inning Onderhoudsbijdragen'},
              {text: 'Landelijke examencommissie ex art. 8 lid 5 Drank- en Horecawet', value: 'Landelijke examencommissie ex art. 8 lid 5 Drank- en Horecawet'},
              {text: 'Luchtverkeersleiding Nederland', value: 'Luchtverkeersleiding Nederland'},
              {text: 'Medisch-ethische toetsingscommissies', value: 'Medisch-ethische toetsingscommissies'},
              {text: 'Metacluster in het Schepenbesluit 2004 jo. Besluit erkende organisaties Schepenwet', value: 'Metacluster in het Schepenbesluit 2004 jo. Besluit erkende organisaties Schepenwet'},
              {text: 'Mondriaan Fonds', value: 'Mondriaan Fonds'},
              {text: 'Nationale en Internationale Wegvervoer Organisatie', value: 'Nationale en Internationale Wegvervoer Organisatie'},
              {text: 'Stichting Nederlands Fonds voor Podiumkunsten', value: 'Stichting Nederlands Fonds voor Podiumkunsten'},
              {text: 'Nederlands Instituut Publieke Veiligheid', value: 'Nederlands Instituut Publieke Veiligheid'},
              {text: 'Stichting Nederlands Jeugdinstituut', value: 'Stichting Nederlands Jeugdinstituut'},
              {text: 'Nederlands Meetinstituut ex Regeling meetmiddelen politie', value: 'Nederlands Meetinstituut ex Regeling meetmiddelen politie'},
              {text: 'Nederlands-Vlaamse Accreditatieorganisatie', value: 'Nederlands-Vlaamse Accreditatieorganisatie'},
              {text: 'Stichting Nederlandse Algemene Keuringsdienst voor zaaizaad en pootgoed van landbouwgewassen', value: 'Stichting Nederlandse Algemene Keuringsdienst voor zaaizaad en pootgoed van landbouwgewassen'},
              {text: 'Nederlandse organisatie voor toegepast-natuurwetenschappelijk onderzoek', value: 'Nederlandse organisatie voor toegepast-natuurwetenschappelijk onderzoek'},
              {text: 'Nederlandse organisatie voor Wetenschappelijk Onderzoek', value: 'Nederlandse organisatie voor Wetenschappelijk Onderzoek'},
              {text: 'Nederlandse Publieke Omroep', value: 'Nederlandse Publieke Omroep'},
              {text: 'Nederlandse Transplantatie Stichting', value: 'Nederlandse Transplantatie Stichting'},
              {text: 'Nederlandse Zorgautoriteit', value: 'Nederlandse Zorgautoriteit'},
              {text: 'Notarissen', value: 'Notarissen'},
              {text: 'Nuffic', value: 'Nuffic'},
              {text: 'Onderzoeksgerechtigden ex art.4 av Wegenverkeerswet 1994', value: 'Onderzoeksgerechtigden ex art.4 av Wegenverkeerswet 1994'},
              {text: 'Onderzoeksraad voor veiligheid', value: 'Onderzoeksraad voor veiligheid'},
              {text: 'Pensioen- en Uitkeringsraad', value: 'Pensioen- en Uitkeringsraad'},
              {text: 'Politieacademie', value: 'Politieacademie'},
              {text: 'Raad voor Accreditatie', value: 'Raad voor Accreditatie'},
              {text: 'Raad voor plantenrassen', value: 'Raad voor plantenrassen'},
              {text: 'Raad voor Rechtsbijstand', value: 'Raad voor Rechtsbijstand'},
              {text: 'RDW', value: 'RDW'},
              {text: 'Reclasseringsorganisaties ex Reclasseringsregeling 1995', value: 'Reclasseringsorganisaties ex Reclasseringsregeling 1995'},
              {text: 'Regionale Loodsencorporaties', value: 'Regionale Loodsencorporaties'},
              {text: 'Regionale Loodsencorporatie Amsterdam-IJmond', value: 'Regionale Loodsencorporatie Amsterdam-IJmond'},
              {text: 'Regionale Loodsencorporatie Noord', value: 'Regionale Loodsencorporatie Noord'},
              {text: 'Regionale Loodsencorporatie Rotterdam-Rijnmond', value: 'Regionale Loodsencorporatie Rotterdam-Rijnmond'},
              {text: 'Regionale Loodsencorporatie Scheldemonden', value: 'Regionale Loodsencorporatie Scheldemonden'},
              {text: 'Koninklijke Nederlandsche Maatschappij tot bevordering der Geneeskunst', value: 'Koninklijke Nederlandsche Maatschappij tot bevordering der Geneeskunst'},
              {text: 'Koninklijke Nederlandse Maatschappij tot bevordering der Tandheelkunde', value: 'Koninklijke Nederlandse Maatschappij tot bevordering der Tandheelkunde'},
              {text: 'Federatie van Gezondheidszorgpsychologen en Psychotherapeuten', value: 'Federatie van Gezondheidszorgpsychologen en Psychotherapeuten'},
              {text: 'Koninklijke Nederlandse Maatschappij ter bevordering der Pharmacie', value: 'Koninklijke Nederlandse Maatschappij ter bevordering der Pharmacie'},
              {text: 'Verpleegkundigen & Verzorgenden Nederland', value: 'Verpleegkundigen & Verzorgenden Nederland'},
              {text: 'Representatief rechtspersoon ex art. 15a Wet op de naburige rechten', value: 'Representatief rechtspersoon ex art. 15a Wet op de naburige rechten'},
              {text: 'Representatief rechtspersoon ex art. 15f Auteurswet', value: 'Representatief rechtspersoon ex art. 15f Auteurswet'},
              {text: 'Rijkshavenmeesters', value: 'Rijkshavenmeesters'},
              {text: 'Samenwerkingsorganisatie Beroepsonderwijs Bedrijfsleven', value: 'Samenwerkingsorganisatie Beroepsonderwijs Bedrijfsleven'},
              {text: 'Sociale Verzekeringsbank', value: 'Sociale Verzekeringsbank'},
              {text: 'Staatsbosbeheer', value: 'Staatsbosbeheer'},
              {text: 'Stichting Administratie Indonesische Pensioenen', value: 'Stichting Administratie Indonesische Pensioenen'},
              {text: 'Stichting Bloembollenkeuringsdienst', value: 'Stichting Bloembollenkeuringsdienst'},
              {text: 'Stichting Donorgegevens Kunstmatige Bevruchting', value: 'Stichting Donorgegevens Kunstmatige Bevruchting'},
              {text: 'Stichting Fonds voor Cultuurparticipatie', value: 'Stichting Fonds voor Cultuurparticipatie'},
              {text: 'Stichting Kwaliteits-Controle-Bureau', value: 'Stichting Kwaliteits-Controle-Bureau'},
              {text: 'Stichting Milieukeur', value: 'Stichting Milieukeur'},
              {text: 'Stichting Nederlands Fonds voor de Film', value: 'Stichting Nederlands Fonds voor de Film'},
              {text: 'Stichting Nederlands Letterenfonds', value: 'Stichting Nederlands Letterenfonds'},
              {text: 'Stichting Nederlandse Algemene Kwaliteitsdienst Tuinbouw', value: 'Stichting Nederlandse Algemene Kwaliteitsdienst Tuinbouw'},
              {text: 'Stichting Nidos', value: 'Stichting Nidos'},
              {text: 'Stichting Participatiefonds voor het Onderwijs', value: 'Stichting Participatiefonds voor het Onderwijs'},
              {text: 'Stichting Regionale Publieke Omroep', value: 'Stichting Regionale Publieke Omroep'},
              {text: 'Stichting Skal Biocontrole', value: 'Stichting Skal Biocontrole'},
              {text: 'Stichting Stimuleringsfonds Creatieve Industrie', value: 'Stichting Stimuleringsfonds Creatieve Industrie'},
              {text: 'Stichting Vakopleiding Automobiel- en Motorrijwielbedrijf', value: 'Stichting Vakopleiding Automobiel- en Motorrijwielbedrijf'},
              {text: 'Stichting Vervangingsfonds en Bedrijfsgezondheidszorg voor het Onderwijs', value: 'Stichting Vervangingsfonds en Bedrijfsgezondheidszorg voor het Onderwijs'},
              {text: 'Stichting Visitatie Woningcorporaties Nederland', value: 'Stichting Visitatie Woningcorporaties Nederland'},
              {text: 'Stimuleringsfonds voor de Journalistiek', value: 'Stimuleringsfonds voor de Journalistiek'},
              {text: 'Toelatingsorganisatie Kwaliteitsborging Bouw', value: 'Toelatingsorganisatie Kwaliteitsborging Bouw'},
              {text: 'Uitvoerders Wet langdurige zorg', value: 'Uitvoerders Wet langdurige zorg'},
              {text: 'Uitvoeringsinstituut Werknemersverzekeringen', value: 'Uitvoeringsinstituut Werknemersverzekeringen'},
              {text: 'VTS-operators', value: 'VTS-operators'},
              {text: 'Waarborginstellingen', value: 'Waarborginstellingen'},
              {text: 'Edelmetaal Waarborg Nederland B.V.', value: 'Edelmetaal Waarborg Nederland B.V.'},
              {text: 'WaarborgHolland B.V.', value: 'WaarborgHolland B.V.'},
              {text: 'Waarderingskamer', value: 'Waarderingskamer'},
              {text: 'Zorginstituut Nederland', value: 'Zorginstituut Nederland'},
              {text: 'Zorgkantoren Wet langdurige zorg', value: 'Zorgkantoren Wet langdurige zorg'},
              {text: 'ZorgOnderzoek Nederland / Medische Wetenschappen', value: 'ZorgOnderzoek Nederland / Medische Wetenschappen'},
              {text: 'GGDGHOR', value: 'GGDGHOR'},
            ]
          }

          secondaryDropdown.choices = choices.sort((a, b) => a.text.localeCompare(b.text));
          console.log(secondaryDropdown.choices);
        });
      }


    const survey = new Model({...surveyJson, ...enquette});
    survey.locale = "nl";
    populateSecondaryDropdown(survey);


    const themeJson = {
      "themeName": "flat",
      "colorPalette": "light",
      "isPanelless": false,
      "backgroundImage": "",
      "backgroundOpacity": 1,
      "backgroundImageAttachment": "scroll",
      "backgroundImageFit": "cover",
      "cssVariables": {
        "--sjs-font-family": "Avenir",
        "--sjs-corner-radius": "4px",
        "--sjs-base-unit": "8px",
        "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
        "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.12)",
        "--sjs-border-default": "rgba(0, 0, 0, 0.12)",
        "--sjs-border-light": "rgba(0, 0, 0, 0.12)",
        "--sjs-general-backcolor": "rgba(246, 246, 246, 1)",
        "--sjs-general-backcolor-dark": "rgba(235, 235, 235, 1)",
        "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
        "--sjs-general-backcolor-dim-dark": "rgba(235, 235, 235, 1)",
        "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
        "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
        "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
        "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
        "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
        "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
        "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
        "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
        "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
        "--sjs-shadow-medium": "0px 0px 0px 1px rgba(0, 0, 0, 0.1),0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
        "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
        "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
        "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
        "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-green": "rgba(25, 179, 148, 1)",
        "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
        "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-blue": "rgba(67, 127, 217, 1)",
        "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
        "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
        "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
        "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-article-font-xx-large-textDecoration": "none",
        "--sjs-article-font-xx-large-fontWeight": "700",
        "--sjs-article-font-xx-large-fontStyle": "normal",
        "--sjs-article-font-xx-large-fontStretch": "normal",
        "--sjs-article-font-xx-large-letterSpacing": "0",
        "--sjs-article-font-xx-large-lineHeight": "64px",
        "--sjs-article-font-xx-large-paragraphIndent": "0px",
        "--sjs-article-font-xx-large-textCase": "none",
        "--sjs-article-font-x-large-textDecoration": "none",
        "--sjs-article-font-x-large-fontWeight": "700",
        "--sjs-article-font-x-large-fontStyle": "normal",
        "--sjs-article-font-x-large-fontStretch": "normal",
        "--sjs-article-font-x-large-letterSpacing": "0",
        "--sjs-article-font-x-large-lineHeight": "56px",
        "--sjs-article-font-x-large-paragraphIndent": "0px",
        "--sjs-article-font-x-large-textCase": "none",
        "--sjs-article-font-large-textDecoration": "none",
        "--sjs-article-font-large-fontWeight": "700",
        "--sjs-article-font-large-fontStyle": "normal",
        "--sjs-article-font-large-fontStretch": "normal",
        "--sjs-article-font-large-letterSpacing": "0",
        "--sjs-article-font-large-lineHeight": "40px",
        "--sjs-article-font-large-paragraphIndent": "0px",
        "--sjs-article-font-large-textCase": "none",
        "--sjs-article-font-medium-textDecoration": "none",
        "--sjs-article-font-medium-fontWeight": "700",
        "--sjs-article-font-medium-fontStyle": "normal",
        "--sjs-article-font-medium-fontStretch": "normal",
        "--sjs-article-font-medium-letterSpacing": "0",
        "--sjs-article-font-medium-lineHeight": "32px",
        "--sjs-article-font-medium-paragraphIndent": "0px",
        "--sjs-article-font-medium-textCase": "none",
        "--sjs-article-font-default-textDecoration": "none",
        "--sjs-article-font-default-fontWeight": "400",
        "--sjs-article-font-default-fontStyle": "normal",
        "--sjs-article-font-default-fontStretch": "normal",
        "--sjs-article-font-default-letterSpacing": "0",
        "--sjs-article-font-default-lineHeight": "28px",
        "--sjs-article-font-default-paragraphIndent": "0px",
        "--sjs-article-font-default-textCase": "none",
        "--sjs-general-backcolor-dim": "rgba(255, 255, 255, 1)",
        "--sjs-primary-backcolor": "rgba(59, 130, 246, 1)",
        "--sjs-primary-backcolor-dark": "rgba(34, 63, 65, 1)",
        "--sjs-primary-backcolor-light": "rgba(46, 84, 87, 0.1)",
        "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
        "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
        "--sjs-special-red": "rgba(229, 10, 62, 1)",
        "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
      },
      "headerView": "basic",
    }

    survey.onComplete.add((sender) => {
      const surveyResults = sender.data;


      fetch("https://lkav3xlaoe.execute-api.eu-central-1.amazonaws.com/default/survey-result-saver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(surveyResults)
      })
        .then((response) => response.json())
        .then((data) => console.log("Survey results successfully sent:", data))
        .catch((error) =>
          console.error("Error sending survey results:", error)
        );
    });

    survey.applyTheme(themeJson);
    const customCss = {
      navigationButton: "tc-button", // Custom style for all navigation buttons
    };



    survey.css = customCss

    return (
      <main className="flex-grow">
          <PageIntro
            title="Onderzoek decentrale overheid 2024"
            subText={<>
             Informatiebeveiliging is een grote uitdaging voor decentrale overheden. Inzicht en van elkaar leren zijn waardevol. Daarom nodigen we je uit voor onze enquête, onderdeel van een landelijk onderzoek naar de uitdagingen en behoeften rondom informatiebeveiliging bij gemeenten, waterschappen en GGR’s.

            </>}
          />

      <section className="pb-12">
        <div className="container">
          <div className="-ml-6 break-survey:-ml-10"><Survey model={survey} /></div>
        </div>
        </section>
      </main>
    );
}

export default SurveyPage;
