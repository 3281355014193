import React from 'react';
import PageIntro from '../PageIntro';
import Layers from '../home/Layers';
import QuoteQuestionBlock from '../home/QuoteQuestionBlock';

function Vacature() {
    return (
        <main>
            <PageIntro
            title="Consultant bij Triple Control"
            subtitle="Vacature"
            breadcrumbs={[
                { href: "/vacatures", title: "Vacatures" },
                { href: "/vacatures/consultant", title: "Consultant" }
            ]} />
            <div className="container">
                <div className="mx-auto max-w-3xl lg:mx-0 pb-12">
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                    Bij Triple Control zijn we op zoek naar een Consultant op het gebied van informatiebeveiliging, compliance en risicomanagement. Ben jij iemand die zich graag vastbijt in complexe vraagstukken en altijd op zoek is naar de beste oplossing? Dan willen wij graag met je in gesprek!
                    </p>
                    <h2 class="text-blue-500 font-medium text-lg md:text-1xl pt-6">Over Triple Control</h2>
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                        Wij zijn een jong en dynamische organisatie met een schat aan ervaring in diverse sectoren die met onze unieke aanpak risk management, compliance en informatiebeveiliging wil laten werken voor onze opdrachtgevers, in plaats van andersom. We werken met een platte structuur en hanteren een no-nonsense mentaliteit. Snel schakelen zit in ons DNA, en we houden ervan om zaken praktisch en efficiënt aan te pakken. Bij ons ben je geen radertje in het grote geheel, maar een drijvende kracht die echt het verschil kan maken.
                    </p>
                    <h2 class="text-blue-500 font-medium text-lg md:text-1xl pt-6">Wat wij belangrijk vinden</h2>
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                    Bij Triple Control draait het om meer dan alleen technische kennis. Wij zoeken iemand die zich herkent in onze drie <a href="/kernwaarden" class="text-forest-500 hover:text-forest-600 font-medium">kernwaarden</a>.
                    </p>
                    <ol class="list-decimal list-inside text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg">
                        <li><em>Niet bang om te oordelen</em><p className='pt-2 pl-6 leading-7 pb-2'>Eerlijkheid staat voorop. We zoeken iemand die de kunst verstaat om eerlijk te zijn zonder bot te worden. Zeker in overheidsland, waar we vaak opereren, is het belangrijk om je boodschap scherp over te brengen, maar altijd met respect en begrip.</p></li>
                        <li><em>Bottom up in plaats van top down</em><p className='pt-2 pl-6 leading-7 pb-2'>We houden ervan om zaken praktisch en efficiënt aan te pakken. We willen dat onze opdrachtgevers zich voelen dat ze in goede handen zijn. Daarom houden we ons vooral bezig met de business, waarbij we onze specialisten belangrijke informatie geven om beter te begrijpen wat de business nodig heeft.</p></li>
                        <li><em>Begrijp het belang van de business (value)</em><p className='pt-2 pl-6 leading-7 pb-2'>We houden ervan om zaken praktisch en efficiënt aan te pakken. We willen dat onze opdrachtgevers zich voelen dat ze in goede handen zijn. Daarom houden we ons vooral bezig met de business, waarbij we onze specialisten belangrijke informatie geven om beter te begrijpen wat de business nodig heeft.</p></li>
                    </ol>
                    <h2 class="text-blue-500 font-medium text-lg md:text-1xl pt-6">Wie jij bent?</h2>
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                        Ervaring is bij ons niet het belangrijkste. Wat we wél zoeken, is iemand met karakter, die leergierig is, snel nieuwe dingen oppakt en niet bang is om verantwoordelijkheid te nemen. Je hebt durf, je wilt bouwen aan iets groots, en je hebt de drive om het elke dag beter te doen. Als jij ervaring hebt in de praktijk, leeren wij je de fijne kneepjes van het mooie vak van informatiebeveiliging. We moedigen je aan om jezelf continu te ontwikkelen met de juiste opleidingen en cursussen, volledig op onze kosten.
                    </p>
                    <h2 class="text-blue-500 font-medium text-lg md:text-1xl pt-6">Wat heb jij?</h2>
                    <ul class="list-disc list-inside text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg">
                        <li>Een afgeronde HBO of WO opleiding in Rechten, Bedrijfskunde of IT, of een schat aan relevante werkervaring;</li>
                        <li>Affiniteit met kwaliteitsmanagement, informatiebeveiliging en risico management; Je bent bekend met de PDCA cyclus, continue verbetering, en begrijpt de waarde van controls.</li>
                        <li>Uitstekende beheersing van de Nederlandse en Engelse taal;</li>
                        <li>Je kunt goed samenwerken, maar bent ook uitstekend in staat zelfstandig te functioneren.</li>
                    </ul>
                    <h2 class="text-blue-500 font-medium text-lg md:text-1xl pt-6">Wat we bieden</h2>
                    <ul class="list-disc list-inside text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg">
                        <li>Een aantrekkelijk salaris </li>
                        <li>Je eigen MacBook om je werk zo goed mogelijk te kunnen doen.</li>
                        <li>De kans om te groeien binnen een bedrijf dat volop in beweging is.</li>
                    </ul>
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                        Herken jij jezelf in deze omschrijving en ben je klaar om met ons mee te groeien? Stuur dan je sollicitatie inclusief motivatie met CV (of met een link naar je LinkedIn profiel, als deze naar jouw idee ook goed genoeg is) naar <a href="mailto:bas@triple-control.com" class="text-forest-500 hover:text-forest-600 font-medium">bas@triple-control.com</a> en vertel ons waarom jij de perfecte aanvulling bent voor ons team.
                    </p>
                    <p className='text-gray-600 pt-4 leading-6 md:leading-9 md:text-lg'>
                        Acquisitie op basis van deze advertentie wordt niet op prijs gesteld.
                    </p>
                </div>
            </div>
        <QuoteQuestionBlock
        title="Risico management toegankelijk maken"
        quote={"Het is eindeloos makkelijker om je collega's voldoende te leren over risico management, dan dat het is om zodanig veel begrip te krijgen over alles wat iedereen doet dat je in je eentje zinvol de risico's kunt beheersen."}
        author="Bas de Kwant"
        cta={false}
        authorImage={true}
        />
        </main>
    );
}

export default Vacature;
