import React from 'react';
import PageIntro from '../PageIntro';

function ISOimplementatie() {
    return (

        <main className="flex-grow">
        <PageIntro
            title="ISO 27001 implementatie"
            subtitle="Diensten"
            breadcrumbs={[
                { href: "/diensten", title: "Diensten" },
                { href: "/diensten/iso-implementatie", title: "ISO 27001 implementatie" }
            ]}
        />
       <div className="container">
            <div className="mx-auto max-w-3xl lg:mx-0 pb-8 md:pb-12">
            <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-8">
                ISO 27001: Aantoonbaar maken dat je risk based werkt, in control bent over informatie, assets en processen en continu verbetert
            </h3>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
            De stap naar certificering is een kans om je organisatie naar een volgend niveau te tillen. In de markt wordt in toenemende mate gevraagd naar hoe je omgaat met de informatie van klanten. Als ook voor jouw organisatie het moment gekomen is om hierover na te denken, komt een hoop op je af:
          </p>
            <ul className="list-decimal list-inside pl-4">
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                Hoe gaan we van nu(l) naar een certificeerbaar niveau?
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                Hoe zorgen we ervoor dat de certificering ook iets gaat betekenen? Je wilt geen papieren tijger, maar dat een implementatieproject je wereld ook echt beter en veiliger maakt.
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                Hoe zorgen we dat het innovatieve karakter van onze organisatie niet verandert?
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                Kunnen we het werken leuk houden, hoe zorgen we dat het werkplezier niet onder de nieuwe security maatregelen lijdt?
                </li>
                <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
                Je wilt niet dat “nee dat kan niet vanwege de compliance” een normale zin wordt in je organisatie. Hoe organiseren we ons ISMS zo dat het wendbaar en flexibel wordt? Hoe worden we de club “zo kan het wel én zo kan het verantwoord”?
                </li>
            </ul>
            <p className="leading-6 md:leading-9 md:text-lg text-blue-500 pb-4">
           Precies van deze vragen en dit soort ambitie worden wij blij!
          </p>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
          Het stuk “hoe halen we de certificering” is een kwestie van aan de norm voldoen.
          Wij helpen je graag verder dan alleen voldoen. Naar een zinvolle interpretatie die bij je past.
          Een solide security cultuur die breed draagvlak heeft van je medewerkers: de experts die dicht bij het vuur zitten.
          </p>
          <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-4">
                Waarom Triple Control?
            </h3>
          <ul className="list-decimal list-inside pl-4">
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
            We spreken de taal van de auditor.
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
            We helpen je de norm pragmatisch toepassen. (Te vaak zien we dat mensen het zichzelf te moeilijk maken.)
            </li>
            <li className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-2">
            We begrijpen ook je business. Van ontwikkelaars, tot gebruikers, tot legal en management.
            </li>
          </ul>
          <p className="leading-6 md:leading-9 md:text-lg text-gray-600 pb-4">
         Op het spanningsveld tussen "laissez-faire" en "alles dichtschroeven" zijn we gewend en comfortabel te navigeren. Alles verbieden is best makkelijk: risico management van iedereen maken onze uitdaging!

          </p>
          <h3 className="text-2xl font-medium text-blue-500 pb-4 pt-4">
               Meer weten over een implementatie project?
            </h3>
          <a href="/contact" className="py-3 sm:mt-4  px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700">
              Neem contact op!
            </a>
            </div>
        </div>
     </main>
    );

}

export default ISOimplementatie;