import React from 'react';
import PageIntro from './PageIntro';

function PrivacyPolicy() {
  return (
    <main className="flex-grow">
      <PageIntro title="Privacy Policy" />
    <div className="container">
    <div className="mx-auto max-w-4xl lg:mx-0 py-12 lg:pb-24">
        <p className='leading-6 text-gray-600'>Dit is de Privacy Policy van <em>Triple Control</em>, en <em>De Kwant Consultants B.V.</em> (hierna te noemen "<em>Triple Control</em>", "<em>wij</em>", "<em>ons</em>" of "<em>onze</em>"), een onderneming met adres Prins Bernardweg 16, Oranjewoud. Triple Control is ingeschreven bij de Kamer van Koophandel onder nummer 92060137. Deze Privacy Policy legt uit hoe wij gegevens verzamelen, gebruiken, delen en beschermen in relatie tot onze website <em>www.triple-control.com</em> (de "<em>Website</em>"). Wij verzamelen deze gegevens wanneer u onze Website bezoekt met uw computer, tablet, telefoon of smartwatch ("<em>Computer</em>"). Wij verwerken persoonsgegevens op een manier welke in overeenstemming is met de <em>Algemene Verordening Gegevensbescherming</em>, inclusief uitvoeringswet van deze verordening, of de voorafgaande wetgeving van de <em>Wet Bescherming Persoonsgegevens</em> en eventuele toekomstige wijzigingen (de "<em>AVG</em>"), de telecommunicatiewet en de andere op dit moment geldende privacywetgeving.</p>

        <p className=' pt-6 leading-6 text-gray-600'>Door het gebruiken van onze Website, begrijpt u en gaat u akkoord met het verzamelen en gebruiken van informatie in overeenstemming met de Privacy Policy. Onze Privacy Policy is van toepassing op alle bezoekers, gebruikers en alle anderen die de toegang hebben tot de Website ("<em>Gebruikers</em>").</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Toepasselijkheid</h2>

        <p className='leading-6 text-gray-600'>Dit Privacy Statement is in beginsel van toepassing op de verwerking van Persoonsgegevens door <em>Triple Control</em> als <em>Verantwoordelijke</em> voor de gegevensverwerking. In deze situaties beheert Triple Control een eigen database met Persoonsgegevens voor eigen doelen. Dit is het geval bij het verwerken van gegevens voor eigen CRM/marketing doelen. Triple Control verwerkt tevens gegevens als verwerker voor haar opdrachtgevers.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Wat voor gegevens verzamelen wij?</h2>

        <p className='leading-6 text-gray-600'>Wij verzamelen persoonsgegevens die u ons verstrekt. Een persoonsgegeven betreft informatie over een geïdentificeerde of identificeerbare natuurlijke persoon. Hierbij kunt u denken aan de volgende gegevens:</p>

        <ul className='list-disc list-inside text-gray-600'>
        <li>Communicatie tussen Triple Control en u (wij mogen u dienst-gerelateerde e-mails sturen)</li>
        <li>Triple Control verzamelt bedrijfs- en contactgegevens, bankgegevens, contact- en aankoophistorie.</li>
        <li>We sturen e-mails op het e-mailadres, of nemen telefonisch contact op met het telefoonnummer dat aan ons is doorgegeven door de betrokkene(n) voor noodzakelijke informatie over je producten en diensten, nieuws, ontwikkelingen en aanbiedingen van (nieuwe) producten en diensten, interessante aanbiedingen en acties, nieuws en informatie over uiteenlopende onderwerpen.</li>
        </ul>

        <p className='pt-6 leading-6 text-gray-600'>Triple Control verzamelt contactgegevens van (potentiële) Opdrachtgevers en belangstellenden om uitvoering te geven aan een overeenkomst en om hen via verschillende kanalen op de hoogte te houden van actualiteiten, activiteiten, producten en diensten van Triple Control. Gegevens worden verzameld bij het aangaan van een overeenkomst, meedoen aan onderzoeken, het abonneren op de e-mailnieuwsbrief, het stellen van een vraag aan één van onze medewerkers, het informeren naar activiteiten, reageren op de website of wanneer u op een andere manier met Triple Control in contact treedt.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Log file informatie</h2>

        <p className='leading-6 text-gray-600'>Wij verzamelen alleen informatie die uw browser stuurt als u onze Website bezoekt wanneer dit noodzakelijk is voor het goed functioneren van de Website. Onder het goed functioneren van de Website verstaan wij met name het beschermen van de Website tegen handelingen die de veiligheid van de Website en van uw Computer in gevaar kunnen brengen. Dit logbestand kan informatie bevatten zoals uw IP-adres, browser-type, browser-versie, de pagina's van onze Website die u bezoekt, de tijd en datum van uw bezoek, de tijd die u besteedt op deze pagina's en andere statistieken.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Doeleinden verwerking gegevens</h2>

        <p className='leading-6 text-gray-600'>Door het gebruik van onze diensten laat u bepaalde gegevens bij ons achter, bijvoorbeeld door het aanmaken van een account. Het kan dan gaan om gegevens zoals naam, e-mailadres, woonplaats, telefoonnummer en betaalgegevens. Triple Control verzamelt en verwerkt deze gegevens om onze diensten toegankelijk te maken. Wij verzamelen tevens informatie over uw Computer (zoals IP-adres, browsertype en besturingssysteem), zodat wij onze diensten kunnen verbeteren. De persoonsgegevens worden niet verstrekt aan derden, tenzij wij daartoe op grond van de wet- en regelgeving verplicht zijn.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Rechtsgrond verwerking gegevens</h2>

        <p className='leading-6 text-gray-600'>Er zijn meerdere grondslagen op basis waarvan Triple Control persoonsgegevens kan verwerken. Dit zijn: de uitvoering van een overeenkomst, het gerechtvaardigd belang, op grond van een wettelijke verplichting of op grond van jouw toestemming. We verwerken uw persoonsgegevens voor zover dat noodzakelijk is om het doel waarvoor we de persoonsgegevens verzamelen te behalen.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Hoe gebruiken wij deze informatie?</h2>

        <p className='leading-6 text-gray-600'>Alle informatie die wij verzamelen gebruiken wij om onze Website te ondersteunen en verbeteren.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Hoe wordt deze informatie gedeeld?</h2>

        <p className='leading-6 text-gray-600'>Wij zullen uw (persoons)gegevens niet verhuren of verkopen aan derden.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Zeggenschapswijziging</h2>

        <p className='leading-6 text-gray-600'>Wanneer Triple Control, of een deel daarvan, wordt verkocht, overgedragen of een onze activa bij een andere organisatie terecht komt (bijvoorbeeld als gevolg van een fusie, overname, faillissement, ontbinding of liquidatie), dan kunnen gegevens die via de Website zijn verzameld onder de verkochte of overgedragen zaken vallen. De koper of verkrijger zal de afspraken in deze Privacy Policy moeten opvolgen.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Wettelijk verzoek en voorkoming schade</h2>

        <p className='leading-6 text-gray-600'>Op grond van een wettelijk verzoek zijn wij gerechtigd om toegang te krijgen tot uw informatie en dit te bewaren en/of te delen in antwoord op een wettelijk verzoek (zoals een huiszoekingsbevel, gerechtelijk bevel of een dagvaarding). Wij zijn ook gerechtigd om uw informatie te bewaren en/of te delen wanneer wij geloven dat dit noodzakelijk is voor het opsporen, voorkomen en aankaarten van fraude of andere illegale activiteiten en om ons, u en anderen te beschermen. Informatie die wij ontvangen over u kan worden geopend, bewerkt en bewaard voor een langere periode wanneer dit noodzakelijk is vanwege een juridisch verzoek of verplichting, een onderzoek met betrekking tot onze voorwaarden, beleid of om anderszins schade te voorkomen.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Internationale overdraging</h2>

        <p className='leading-6 text-gray-600'>Uw informatie kan worden overgedragen en onderhouden op computers en/of servers die zich buiten Nederland en/of de EU bevinden en waar de wetten omtrent gegevensbescherming kunnen verschillen. Wij spannen ons in om ervoor te zorgen dat ook wanneer uw persoonsgegevens buiten de EU worden verwerkt, zij op een juridisch juiste en zorgvuldige wijze buiten de EU worden verwerkt.</p>

        <h2 className='pt-6 text-blue-500 fontemibold text-lg md:text-1xl'>Bewaartermijn</h2>

        <p className='leading-6 text-gray-600'>In overeenstemming met de AVG en de overige relevante wetgeving bewaart Triple Control persoonsgegevens niet langer dan noodzakelijk is voor de verwezenlijking van de doeleinden waarvoor zij verzameld of verwerkt worden, tenzij Triple Control hiertoe op grond van een wettelijke bepaling verplicht is. Indien u meer informatie wil over hoelang uw specifieke persoonsgegevens bewaard blijven, kunt u contact opnemen met <em>info@triple-control.com</em>.</p>

        <p className='leading-6 text-gray-600'>Triple Control bewaart persoonsgegevens die zijn geworven via contactformulieren, evenementaanmeldingen en aanvraagformulieren voor rapporten en whitepapers tot twaalf maanden na registratie of twaalf maanden tot na het laatste contactmoment.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Recht op inzage, correcties, recht op bezwaar en recht op dataportabiliteit</h2>

        <p className='leading-6 text-gray-600'>Wanneer u inzage wilt in uw persoonsgegevens, als u uw gegevens wilt wijzigen of verwijderen of als u uw persoonsgegevens geheel of gedeeltelijk wilt laten overdragen aan u of aan een derde dan kunt u contact opnemen met Triple Control door een e-mail te sturen naar <em>info@triple-control.com</em> of een brief sturen aan:</p>

        <p className='leading-6 text-gray-600'><em>De Kwant Consultants B.V.</em><br/>Prins Bernardweg 16<br/>8453XD, Oranjewoud</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Applicaties, websites en diensten van derden</h2>

        <p className='leading-6 text-gray-600'>Wij zijn niet verantwoordelijk voor de praktijken van applicaties, websites of services van derden die gelinkt zijn naar of van onze Website, waaronder de informatie or inhoud die bijgaand is. Onze Privacy Policy is niet van toepassing wanneer u een link gebruikt om van onze Website naar een andere applicatie, website of service te gaan. Uw gedragingen op een applicatie, website of dienst van een derde, waaronder deze die een link hebben op onze Website, zijn onderworpen aan de regels en het beleid van de derde.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Privacy van kinderen</h2>

        <p className='leading-6 text-gray-600'>Onze Website vraagt niet specifiek en bewust om gegevens van personen jonger dan 16 jaar ("<em>Kinderen</em>"). Deze leeftijd kan variëren in elke Lidstaat tussen de leeftijd van 13 en 16 jaar. Indien wij erachter komen dat wij persoonlijke gegevens hebben verzameld van Kinderen zonder toestemming van hun ouder of voogd, dan zullen wij stappen ondernemen om deze gegevens te verwijderen van onze servers. Als u vermoedens heeft dat uw kind zonder uw toestemming persoonsgegevens heeft verstrekt aan ons, dan kunt u contact met ons opnemen via <em>info@triple-control.com</em>. Indien u als kind persoonsgegevens achter hebt gelaten op onze site en u deze wil verwijderen, dan zullen wij ons inspannen om deze gegevens te verwijderen. Als u het vermoeden heeft dat u als kind persoonsgegevens aan ons heeft verstrekt, kunt u contact met ons opnemen via <em>info@triple-control.com</em>.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Cookiebeleid</h2>

        <p className='leading-6 text-gray-600'>Om u beter en persoonlijker te kunnen helpen maakt Triple Control gebruik van cookies en vergelijkbare technieken op haar websites; hierna '<em>cookies</em>'. Hiermee volgt Triple Control uw gedrag op onze site(s). Op basis hiervan kunnen wij diensten aanbieden die aansluiten bij uw behoeften of interesses en onze websites optimaliseren. We vertellen in deze verklaring welke cookies wij kunnen plaatsen en welke functie ze hebben.</p>

        <p className='leading-6 text-gray-600'>Het plaatsen van cookies en het verwerken van persoonsgegevens gebeurt op een wijze welke in overeenstemming is met de <em>Algemene Verordening Gegevensbescherming</em>, inclusief uitvoeringswet van deze verordening, of de voorafgaande wetgeving van de <em>Wet Bescherming Persoonsgegevens</em> en eventuele toekomstige wijzigingen (de "<em>AVG</em>"), de telecommunicatiewet en alle overige privacywetgeving zoals die op dit moment geldt.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Wat zijn cookies?</h2>

        <p className='leading-6 text-gray-600'>Cookies zijn eenvoudige tekstbestanden die op uw computer, tablet, telefoon of smartwatch worden opgeslagen, wanneer u onze Website bezoekt. Cookies kunnen uw computer, tablet of telefoon niet beschadigen. De opgeslagen informatie kan bij een volgend bezoek weer naar onze servers worden teruggestuurd. De cookies zijn noodzakelijk om u een prettige ervaring op de Website te geven.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Functionele cookies</h2>

        <p className='leading-6 text-gray-600'>Functionele cookies zijn cookies die noodzakelijk zijn om onze Website goed en veilig te laten werken. Zonder deze cookies kan onze Website niet goed functioneren. U kunt deze cookies dan ook niet uitzetten. Er bestaat een onderscheid tussen permanente functionele cookies en sessie cookies. De sessie cookies worden direct na uw bezoek van onze site verwijderd. De permanente cookies worden bewaard om te voorkomen dat u telkens opnieuw dezelfde actie moet uitvoeren. Denk hierbij bijvoorbeeld aan het automatisch inloggen op websites of aankopen die in een winkelwagen van een webshop voor u bewaard worden. Wij delen de gegevens die wij inwinnen via deze cookies dan ook niet met derden.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Analytische cookies</h2>

        <p className='leading-6 text-gray-600'>Analytische cookies zijn cookies die ervoor zorgen dat de site goed kan functioneren. Door analytische cookies weten wij bijvoorbeeld hoe vaak onze Website wordt bezocht, waar op onze Website naar wordt gezocht, hoe lang het duurt voordat pagina's geladen zijn en waarop op onze Website door onze bezoekers wordt geklikt. Analytische cookies mogen soms worden geplaatst zonder uw toestemming. Alle andere analytische cookies worden alleen met uw toestemming geplaatst.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Onvoorziene cookies</h2>

        <p className='leading-6 text-gray-600'>Wij proberen een zo goed mogelijk overzicht te houden over de cookies op onze Website. Het kan voorkomen dat derden cookies plaatsen op onze Website. In dat geval vindt u deze cookies niet terug in dit overzicht. Het gaat dan met name om embedded elementen. Hier is bijvoorbeeld sprake van als een video die op YouTube is opgeslagen op onze Website wordt gedeeld. Komt u op onze Website een cookie tegen die niet in dit overzicht staat? Dan verzoeken wij u contact op te nemen met ons door een mail te sturen naar <em>info@triple-control.com</em>.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Hoe wordt de verzamelde informatie beveiligd?</h2>

        <p className='leading-6 text-gray-600'>Triple Control heeft passende technische en organisatorische maatregelen genomen om uw gegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking. Wij gebruiken veiligheidsmaatregelen om te zorgen dat informatie via de Website wordt beveiligd (zoals het aanvragen van een uniek wachtwoord). Echter, Triple Control kan niet verzekeren dat de informatie op de Website niet wordt geopend, onthuld, veranderd of vernietigd. U bent te allen tijde verantwoordelijk voor het handhaven van uw unieke wachtwoord en account-informatie en voor het beheren van de e-mails tussen u en Triple Control. Triple Control is niet verantwoordelijk of aansprakelijk voor het cookiegebruik van derden waarnaar de gebruiker via onze Website naar kan doorlinken waaronder - maar niet uitsluitend - YouTube, Facebook, Twitter en LinkedIn.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Hoe wordt de verzamelde informatie gedeeld?</h2>

        <p className='leading-6 text-gray-600'>Informatie verzameld met functionele cookies delen wij niet met derden. Informatie verkregen met analytische cookies delen wij zonder uw toestemming alleen met derden nadat wij deze gegevens hebben geanonimiseerd of nadat wij voor het delen van uw gegevens uw toestemming hebben gekregen. Informatie verkregen met tracking cookies delen wij alleen met derden indien wij daartoe uw toestemming hebben gekregen.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Kinderen</h2>

        <p className='leading-6 text-gray-600'>Onze Website plaatst niet bewust cookies wanneer de computer, tablet of mobiel wordt gebruikt door iemand die jonger is dan 16 jaar ("<em>Kinderen</em>"). Indien wij erachter komen dat wij persoonlijke gegevens hebben verzameld van Kinderen zonder toestemming van hun ouder of voogd, dan zullen wij stappen ondernemen om deze gegevens te verwijderen van onze servers. Als u vermoedens heeft dat uw kind zonder uw toestemming persoonsgegevens heeft verstrekt aan ons, dan kunt u contact met ons opnemen via <em>info@triple-control.com</em>. Indien wij van u persoonsgegevens hebben verkregen toen u een kind was, dan zullen wij ons inspannen op uw verzoek deze persoonsgegevens verwijderen. Als u het vermoeden heeft dat u als kind persoonsgegevens aan ons heeft verstrekt, dan kunt u contact met ons opnemen via <em>info@triple-control.com</em>.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Zeggenschapswijziging</h2>

        <p className='leading-6 text-gray-600'>Wanneer Triple Control, of een deel daarvan, wordt verkocht, overgedragen of een onze activa bij een andere organisatie terecht komt (bijvoorbeeld als gevolg van een fusie, overname, faillissement, ontbinding of liquidatie), dan kunnen gegevens die via de Website zijn verzameld onder de verkochte of overgedragen zaken vallen. De koper of verkrijger zal de afspraken in dit Cookiebeleid moeten opvolgen.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Wijzigingen</h2>

        <p className='leading-6 text-gray-600'>Triple Control kan van tijd tot tijd deze Privacy Policy aanpassen of updaten. U wordt daarom geadviseerd om deze Privacy Policy regelmatig te raadplegen. Aanpassingen van deze Privacy Policy treden in werking op het moment dat deze zijn gepubliceerd op deze pagina.</p>

        <h2 className='text-blue-500 fontemibold text-lg md:text-1xl pt-6'>Contact</h2>

        <p className='leading-6 text-gray-600'>Wanneer u vragen heeft over deze Privacy Policy, dan kunt u contact opnemen met Triple Control door een e-mail te sturen naar <em>info@triple-control.com</em>.</p>

        <p className='leading-6 text-gray-600'><em>Versie 1.0</em><br/>Oranjewoud, 24 augustus 2024</p>
        </div>
      </div>
    </main>
  )
};

export default PrivacyPolicy;
