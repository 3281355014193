import React, { useState, useRef, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

function Testimonials() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    const [showPhotos] = useState(false);
    const scrollRef = useRef(null);

    const testimonials = [
        {
            logo: "/images/testimonials/fonky.svg",
            quote: "",
            author: "Jordy Oosterveld",
            role: "Directeur Fonky",
            image: ""
        },
        {
            logo: "/images/testimonials/tqis.svg",
            quote: "",
            author: "Arjen van Eeuwen",
            role: "Directeur TQIS",
            image: ""
        },
        {
            logo: "/images/testimonials/pag.svg",
            quote: "",
            author: "Jan Willem Gmelig Meyling",
            role: "CTO / CFO Pallas Athena Group",
            image: ""
        },
        // {
        //     logo: "https://tailwindui.com/img/logos/transistor-logo-gray-900.svg",
        //     quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        //     author: "Michael Brown",
        //     role: "Founder of Transistor",
        //     image: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        // },
        // {
        //     logo: "https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg",
        //     quote: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        //     author: "Emma Wilson",
        //     role: "CTO of SavvyCal",
        //     image: "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        // },
        // {
        //     logo: "https://tailwindui.com/img/logos/transistor-logo-gray-900.svg",
        //     quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        //     author: "Michael Brown",
        //     role: "Founder of Transistor",
        //     image: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        // }
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMediumScreen(window.innerWidth >= 1024); // 768px is typically the 'md' breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToTestimonial = (index) => {
        setCurrentIndex(index);
        scrollRef.current.scrollTo({
            left: index * scrollRef.current.offsetWidth,
            behavior: 'smooth'
        });
    };

    const testimonialsPerPage = isMediumScreen ? 2 : 1;
    const pageCount = Math.ceil(testimonials.length / testimonialsPerPage);

    const handlers = useSwipeable({
        onSwipedLeft: () => scrollToTestimonial(Math.min(currentIndex + 1, pageCount - 1)),
        onSwipedRight: () => scrollToTestimonial(Math.max(currentIndex - 1, 0)),
    });

    return (
        <section className="bg-ocean-100 pt-24 pb-10 sm:pb-20 px-10 container rounded-xl mb-10 lg:mb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="flex justify-between items-center mb-8">
                    <h3 className="title-with-break text-4xl md:text-5xl font-medium text-ocean-700 pb-8 md:pb-12">
                        Gewaardeerde klanten<br/> op <strong>the road to control</strong>
                    </h3>

                </div>
                <div className="relative">
                    <div {...handlers} ref={scrollRef} className="flex overflow-x-hidden snap-x snap-mandatory touch-pan-x">
                        {[...Array(pageCount)].map((_, pageIndex) => (
                            <div key={pageIndex} className="flex-shrink-0 w-full snap-center">
                                <div className={`grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none ${isMediumScreen ? 'md:grid-cols-2' : ''}`}>
                                    {testimonials.slice(pageIndex * testimonialsPerPage, pageIndex * testimonialsPerPage + testimonialsPerPage).map((testimonial, index) => (
                                        <div key={index} className="flex flex-col">
                                            <img className="h-10 self-start" src={testimonial.logo} alt="" />
                                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                                <blockquote className="text-base leading-5 text-gray-600">
                                                    <p>{testimonial.quote}</p>
                                                </blockquote>
                                                <figcaption className="mt-10 flex items-center gap-x-6">
                                                    {showPhotos && (
                                                        <img className="h-14 w-14 rounded-full bg-gray-50" src={testimonial.image} alt="" />
                                                    )}
                                                    <div className="text-base">
                                                        <div className="font-semibold text-gray-900">{testimonial.author}</div>
                                                        <div className="mt-1 text-gray-500">{testimonial.role}</div>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-10 lg:mt-20 flex justify-center space-x-3">
                        {[...Array(pageCount)].map((_, index) => (
                            <button
                                key={index}
                                className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-ocean-600' : 'bg-gray-300'}`}
                                onClick={() => scrollToTestimonial(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;