const introHtml = (
  <section>
  <div id="survey-intro">
    <h3 className="text-base font-normal text-blue-600">Waarom doen we dit onderzoek?</h3>
    <p className="leading-9 text-gray-600">
    Het doel van dit onderzoek is om een beter begrip te krijgen van de specifieke problemen en obstakels waar CISO's tegenaan lopen in hun dagelijkse werkzaamheden. We willen inzicht krijgen in hoe informatiebeveiliging wordt benaderd, welke normen en procedures worden gevolgd, en hoe effectief deze zijn in de praktijk. Door deze informatie te verzamelen, hopen we een gedetailleerder beeld te schetsen van de huidige staat van informatiebeveiliging binnen Nederlandse decentrale overheden.
    </p>
    <h3 className="text-base font-normal text-blue-600">Voor wie is deze enquête bedoeld?</h3>
    <p className=" leading-9 text-gray-600">
    Voor alle medewerkers werkzaam in gemeenten, waterschappen en GGR's die betrokken zijn bij het informatiebeveiligingsbeleid, zoals (C)ISO's, FG's, Directieleden, privacy medewerkers.
    </p>
    <h3 className="text-base font-normal text-blue-600">Wat gaat Triple Control met de onderzoeksresultaten doen?</h3>
    <p className=" leading-9 text-gray-600">
    De resultaten van deze enquête zullen anoniem worden geanalyseerd en samengevat in een uitgebreid rapport dat zal worden gedeeld met alle deelnemende organisaties. Dit rapport zal waardevolle inzichten en best practices bieden die je kunt gebruiken om de informatiebeveiliging binnen jouw organisatie te verbeteren. Het onderzoeksrapport wordt beschikbaar gesteld aan alle decentrale overheden. Bovendien zullen de bevindingen worden gepresenteerd aan beleidsmakers en relevante stakeholders om bewustwording te vergroten. Zo levert dit onderzoek directe impact op de versterking van de informatiebeveiliging van decentrale overheden.
    </p>
    <p className=" leading-9 text-gray-600">Het invullen van de enquête duurt slechts enkele minuten.    </p>
    <p className="leading-9 text-gray-600">Vragen of opmerkingen, neem contact op met Maurice Hartsinck via <a href="mailto:maurice.hartsinck@triple-control.com" className="text-forest-500 hover:text-forest-600">maurice.hartsinck@triple-control.com</a></p>
    <h3 className="text-base font-normal text-blue-600">Alvast hartelijk dank voor je tijd en waardevolle bijdrage!</h3>
    </div>
    <div className="md:mt-32 mt-16">
    <h2 className="survey-disclaimer">Onderzoek</h2>
    <h3 className="survey-disclaimer">Informatie over uw organisatie</h3>
    <p className="leading-9 text-gray-600">
Om je gerust te stellen dat je gegevens anoniem en vertrouwelijk worden behandeld, lichten we graag toe waarom we bepaalde informatie vragen. We verzamelen informatie over het type organisatie en je functie om de resultaten van het onderzoek beter te kunnen analyseren en om specifieke trends en inzichten te identificeren binnen verschillende (typen) organisaties. Alle verstrekte gegevens worden strikt vertrouwelijk behandeld en anoniem gepubliceerd. Individuele organisaties of personen zullen niet herleidbaar zijn in de rapportage.
</p>
    </div>
  </section>
);


const enquette = {
    "completedHtml": '<div class="container"><div id="survey-intro"><h3> Bedankt voor het invullen van dit onderzoek</h3><p> We houden je op de hoogte en delen de resultaten zodra we die hebben verwerkt!</p></div></div>',
    "pages": [
      {
        "name": "intro",
        "elements": [
          {
            "type": "html",
            "html": require('react-dom/server').renderToString(introHtml),
          },
          {
            "type": "dropdown",
            "name": "type_organisatie",
            "title": "Bij welk type organisatie werk je?",
            "choices": [
              "Gemeente",
              "Provincie",
              "Regionaal samenwerkingsorgaan",
              "Waterschap",
              "Zelfstandig bestuursorgaan",
            ],
            "showOtherItem": true,
            "otherText": "Anders...",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          },
          {
            "type": "dropdown",
            "name": "organisatie_naam",
            "title": "Selecteer jouw organisatie",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "choices": ["Maak een keuze..."]
          },

          {
            "type": "dropdown",
            "name": "functie",
            "title": "Wat is je functie?",
            "choices": [
              "Functionaris gegevensbescherming of privacy officer",
              "(C)ISO",
              "Directeur",
              "Medewerker informatiebeveiliging",
              "Medewerker privacy"
            ],
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "showOtherItem": true,
            "otherText": "Anders..."
          },
        ]
      },
      {
        "name": "risicobeoordeling",
        "elements": [
          {
            "type": "radiogroup",
            "name": "in_hoeverre_worden_er_informatie_beveiliging_risicobeoordelingen_uitgevoerd_binnen_jouw_gemeente",
            "title": "In hoeverre worden er (informatie beveiliging) risicobeoordelingen uitgevoerd binnen jouw gemeente?",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "choices": [
              "Nooit",
              "1x per jaar",
              "Een aantal keer per jaar",
              "Bij elk project"
            ]
          },
          {
            "type": "rating",
            "name": "hoe_effectief_zijn_de_beveiligingsmaatregelen_die_momenteel_zijn_geimplementeerd_in_jouw_organisatie",
            "title": "Hoe effectief zijn de beveiligingsmaatregelen die momenteel zijn geïmplementeerd in jouw organisatie?",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "autoGenerate": false,
            "rateValues": [
              {
                "value": 1,
                "text": "Zeer ineffectief"
              },
              {
                "value": 2,
                "text": "Ineffectief"
              },
              {
                "value": 3,
                "text": "Neutraal"
              },
              {
                "value": 4,
                "text": "Effectief"
              },
              {
                "value": 5,
                "text": "Zeer effectief"
              }
            ]
          },
          {
            "type": "rating",
            "name": "zijn_er_naar_jouw_mening_over_het_algemeen_voldoende_maatregelen_geimplementeerd",
            "title": "Zijn er naar jouw mening (over het algemeen) voldoende maatregelen geïmplementeerd?",
            "autoGenerate": false,
            "rateCount": 4,
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "rateValues": [
              {
                "value": 1,
                "text": "Ruim onvoldoende"
              },
              {
                "value": 2,
                "text": "Net onvoldoende"
              },
              {
                "value": 3,
                "text": "Voldoende"
              },
              {
                "value": 4,
                "text": "Ruim voldoende"
              }
            ]
          },
          {
            "type": "comment",
            "name": "kun_je_effectiviteit_en_compleetheid_van_de_maatregelen_toelichten",
            "title": "Kun je effectiviteit en compleetheid van de maatregelen toelichten? (optioneel)",
            "isRequired": false,
            "requiredErrorText": "Dit veld is verplicht."
          }
        ]
      },
      {
        "name": "strategische_agenda",
        "elements": [
          {
            "type": "radiogroup",
            "name": "maakt_informatiebeveiliging_onderdeel_uit_van_de_strategische_agenda_in_jouw_organisatie",
            "title": "Maakt informatiebeveiliging onderdeel uit van de strategische agenda in jouw organisatie?",
            "choices": [
              "Ja",
              "Nee"
            ],
            "showOtherItem": true,
            "otherText": "Anders (omschrijf)",
            "otherErrorText": "Vul een toelichting in.",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          },
          {
            "type": "radiogroup",
            "name": "hoe_worden_medewerkers_van_jouw_organisatie_getraind_op_het_gebied_van_informatiebeveiliging",
            "title": "Hoe worden medewerkers van jouw organisatie getraind op het gebied van informatiebeveiliging?",
            "choices": [
              "Incidenteel",
              "Structureel",
              "Nooit"
            ],
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          },
          {
            "type": "comment",
            "name": "kun_je_toelichten_hoe_de_training_plaatsvind",
            "visibleIf": "{hoe_worden_medewerkers_van_jouw_organisatie_getraind_op_het_gebied_van_informatiebeveiliging} <> 'Nooit'",
            "title": "Kun je toelichten hoe de training plaatsvindt? (optioneel)",
          }
        ]
      },

      {
        "name": "stellingen_informatiebeveiliging",
        "elements": [
          {
            "type": "matrix",
            "isAllRowRequired": true,
            "requiredErrorText": "Maak voor elke stelling een keuze.",
            "name": "stellingen_1",
            "title": "Geef aan in hoeverre de volgende stellingen van toepassing zijn op uw organisatie in relatie tot informatiebeveiliging (Deel 1)",
            "columns": [
              {
                "value": "helemaal_oneens",
                "text": "Helemaal oneens"
              },
              {
                "value": "oneens",
                "text": "Oneens"
              },
              {
                "value": "neutraal",
                "text": "Neutraal"
              },
              {
                "value": "eens",
                "text": "Eens"
              },
              {
                "value": "helemaal_eens",
                "text": "Helemaal eens"
              }
            ],
            "rows": [
              {
                "value": "afstemming_afdelingen",
                "text": "Er is voldoende afstemming tussen verschillende afdelingen binnen mijn organisatie op het gebied van informatiebeveiliging.",
              },
              {
                "value": "ciso_betrokkenheid",
                "text": "Bij het doen van projecten worden CISO en/of FG op tijd betrokken bij te maken keuzes.",
              },
              {
                "value": "risico_eigenaren",
                "text": "Risico-eigenaren binnen mijn organisatie zijn goed in staat om de risico's waar zij de owner van zijn te beoordelen.",
              },
              {
                "value": "risicoeigenaarschap",
                "text": "Het risico-eigenaarschap in mijn organisatie is belegd bij proceseigenaren binnen afdelingen.",
              },
              {
                "value": "management_serieus",
                "text": "Informatiebeveiliging wordt serieus genomen door alle medewerkers van mijn organisatie.",
              },
              {
                "value": "politiek_serieus",
                "text": "Informatiebeveiliging wordt serieus genomen door de politiek binnen mijn organisatie.",
              }
            ]
          }
        ]
      },
      {
        "name": "page1",
        "elements": [
          {
            "type": "matrix",
            "name": "question7",
            "isAllRowRequired": true,
            "title": "Geef aan in hoeverre de volgende stellingen van toepassing zijn op uw organisatie in relatie tot informatiebeveiliging (Deel 2)",
            "columns": [
              {
                "value": "helemaal_oneens",
                "text": "Helemaal oneens"
              },
              {
                "value": "oneens",
                "text": "Oneens"
              },
              {
                "value": "neutraal",
                "text": "Neutraal"
              },
              {
                "value": "eens",
                "text": "Eens"
              },
              {
                "value": "helemaal_eens",
                "text": "Helemaal eens"
              }
            ],
            "rows": [
              {
                "value": "budget_voldoende",
                "text": "In mijn organisatie is voldoende budget beschikbaar voor informatiebeveiliging."
              },
              {
                "value": "technische_infrastructuur",
                "text": "De technische infrastructuur van mijn organisatie is voldoende beveiligd tegen cyberaanvallen."
              },
              {
                "value": "directie_begrijpt_risicos",
                "text": "De directie/hoger management begrijpt de risico's op het gebied van informatiebeveiliging en nemen passende maatregelen."
              },
              {
                "value": "verantwoordelijkheid_duidelijk",
                "text": "Het is duidelijk wie binnen mijn organisatie verantwoordelijk is voor welke aspecten van informatiebeveiliging."
              },
              {
                "value": "incidenten_aanpak",
                "text": "Security incidenten (zoals datalekken en aanvallen) worden binnen mijn organisatie adequaat en snel aangepakt."
              },
              {
                "value": "leren_van_incidenten",
                "text": "Onze organisatie leert te weinig van incidenten en daardoor gebeuren vergelijkbare incidenten opnieuw."
              }
            ]
          }
        ]
      },
      {
        "name": "page2",
        "elements": [
          {
            "type": "matrix",
            "name": "question9",
            "isAllRowRequired": true,
            "title": "Geef aan in hoeverre de volgende stellingen van toepassing zijn op uw organisatie in relatie tot informatiebeveiliging (Deel 3)",
            "columns": [
              {
                "value": "helemaal_oneens",
                "text": "Helemaal oneens"
              },
              {
                "value": "oneens",
                "text": "Oneens"
              },
              {
                "value": "neutraal",
                "text": "Neutraal"
              },
              {
                "value": "eens",
                "text": "Eens"
              },
              {
                "value": "helemaal_eens",
                "text": "Helemaal eens"
              }
            ],
            "rows": [
              {
                "value": "behoud_gekwalificeerd_personeel",
                "text": "Het niet kunnen aantrekken en behouden van gekwalificeerd personeel vormt een bedreiging voor onze informatiebeveiliging."
              },
              {
                "value": "risico_analyse_methodiek",
                "text": "Risico analyse methodiek is zodanig dat de uitkomsten echt iets zeggen over hoe we ervoor staan en bij periodieke herhaling consistent zijn."
              },
              {
                "value": "externe_inhuur",
                "text": "Zonder externe inhuur zouden we onze doelstellingen op het gebied van informatiebeveiliging niet kunnen halen."
              },
              // {
              //   "value": "informatiebeveiliging_onder_druk",
              //   "text": "\"Onder druk wordt alles vloeibaar,\" is regelmatig waarom informatiebeveiliging maar even aan de kant geschoven wordt in projecten."
              // },
              {
                "value": "gedrag_medewerkers",
                "text": "Medewerkers gedragen zich zoals op basis van het beleid van hen wordt verwacht."
              }
            ]
          }
        ]
      },
      {
        "name": "verouderde_software_hardware",
        "elements": [
          {
            "type": "radiogroup",
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht.",
            "name": "wat_is_de_status_van_het_informatiebeveiligingsbeleid_in_jouw_organisatie",
            "title": "Wat is de status van het informatiebeveiligingsbeleid in jouw organisatie?",
            "choices": [
              {
                "value": "Dit is actueel en representatief voor wat er gebeurt",
                "text": "Dit is actueel en representatief voor hoe iedereen werkt."
              },
              {
                "value": "Uit het beleid spreken goede intenties maar er is onvoldoende navolging",
                "text": "Uit het beleid spreken goede intenties maar er is onvoldoende navolging."
              },
              {
                "value": "Het beleid is verouderd (of onpraktisch) en wordt beperkt gevolgd",
                "text": "Het beleid is verouderd (of onpraktisch) en wordt beperkt gevolgd."
              },
              {
                "value": "Het beleid dat gevolgd wordt is inhoudelijk ontoereikend",
                "text": "Het beleid dat goed gevolgd wordt is inhoudelijk ontoereikend."
              }
            ]
          },
          {
            "type": "radiogroup",
            "name": "er_zijn_regels_in_mijn_organisatie_die_werken_zo_lastig_maken_dat_medewerkers_in_de_verleiding_komen_ze_te_omzeilen",
            "title": "Er zijn veel regels in mijn organisatie die werken zo lastig maken, dat medewerkers in de verleiding komen ze te omzeilen.",
            "choices": [
              "Eens",
              "Oneens"
            ],
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          },
          {
            "type": "comment",
            "name": "toelichting_regels_omzeilen",
            "visibleIf": "{er_zijn_regels_in_mijn_organisatie_die_werken_zo_lastig_maken_dat_medewerkers_in_de_verleiding_komen_ze_te_omzeilen} = 'Eens'",
            "title": "Wil je dit toelichten?"
          }
        ]
      },
      // {
      //   "name": "nis2_bio",
      //   "elements": [
      //     {
      //       "type": "radiogroup",
      //       "name": "welke_zin_is_het_meest_van_toepassing_op_uw_organisatie_informatiebeveiliging_is_in_onze_organisatie",
      //       "title": "Welke zin is het meest van toepassing op uw organisatie. Informatiebeveiliging is in onze organisatie....",
      //       "choices": [
      //         "Volledig geïntegreerd in de manier waarop we werken",
      //         "Een noodzakelijk kwaad"
      //       ]
      //     },
      //     {
      //       "type": "comment",
      //       "name": "toelichting_noodzakelijk_kwaad",
      //       "title": "Toelichting...",
      //       "isRequired": true,
      //       "requiredErrorText": "Dit veld is verplicht."
      //     }
      //   ]
      // },
      {
        "name": "regels_omzeilen",
        "elements": [
          {
            "type": "radiogroup",
            "name": "voorbereiding_nis2",
            "title": "In hoeverre is jouw organisatie voorbereid op de implementatie van de NIS2?",
            "choices": [
              "We zijn ons niet bewust van NIS2",
              "We hebben kennisgenomen van NIS2 maar nog geen actie ondernomen",
              "We zijn begonnen met de voorbereidingen",
              "We hebben een implementatieplan en zijn actief bezig",
              "We zijn volledig voorbereid en voldoen aan NIS2"
            ],
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          }
        ]
      },
      {
        "name": "contactgegevens",
        "elements": [
          {
            "type": "radiogroup",
            "name": "consent",
            "title": "\nWilt u dat wij u op de hoogte houden van de uitkomsten van het onderzoek? Vul dan uw gegevens hieronder in:",
            "description": "Triple Control zal de verstrekte persoonsgegevens alleen gebruiken voor de doeleinden van dit onderzoek en om u op de hoogte te houden van relevante informatie en updates met betrekking tot het onderzoek en de opvolging (in het kader van vervolgonderzoek",
            "choices": [
              {
                "value": "Ja",
                "text": "Ja"
              },
              {
                value: "Nee",
                text: "Nee"
              }
            ]
          },
          {
            "type": "text",
            "name": "naam",
            "visibleIf": "{consent} allof ['Ja']",
            "title": "Naam:"
          },

          {
            "type": "text",
            "name": "telefoonnummer",
            "visibleIf": "{consent} allof ['Ja']",
            "title": "Telefoonnummer:",
          },
          {
            "type": "text",
            "name": "emailadres",
            "visibleIf": "{consent} allof ['Ja']",
            "title": "Emailadres:",
            "validators": [
              {
                "type": "email",
                "text": "Voer een geldig emailadres in."
              }
            ],
            "isRequired": true,
            "requiredErrorText": "Dit veld is verplicht."
          }
        ]
      }
    ]
  };

export default enquette;