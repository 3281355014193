import React from 'react';
import PageIntro from './PageIntro';
import Layers from './home/Layers';
import SingleZinOcean from './SingleZinOcean';
import PodcastComponent from './PodcastComponent';
export const LinkedInLogo = () => (
  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
    <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
  </svg>
);

const teamMembers = [
  {
    personName: "Iris van der Wel",
    role: "Information security analyst",
    image: "images/photo-iris.jpeg",
    linkedIn: false,
    linkedInHref: ""
  },
  {
    personName: "Bodine van Leeuwen",
    role: "Senior information security analyst",
    image: "images/photo-bodine.jpeg",
    linkedIn: false,
    linkedInHref: ""
  },
  {
    personName: "Nanook Harten",
    role: "Information security analyst",
    image: "images/photo-nanook.jpg",
    linkedIn: false,
    linkedInHref: ""
  },
  {
    personName: "Rem van Oers",
    role: "Information security analyst",
    image: "images/photo-rem.jpeg",
    linkedIn: false,
    linkedInHref: ""
  }
];

function WieWeZijn() {
  return (
    <main className="flex-grow">
      <PageIntro title="Triple Control team"
      subtitle="Een team van gedreven experts die het verschil maken"/>
      <div className="bg-white py-0">
      <div className="mx-auto max-w-8xl  pb-8 container ">
        <ul role="list" className="mx-auto mt-20 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <li>
              <img className="aspect-[4/3] w-full rounded-2xl object-cover" src="images/photo-bas-de-kwant.jpg" alt="Bas de Kwant" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Bas de Kwant</h3>
              <p className="text-base leading-7 text-gray-600 pb-6">Founder</p>
              <p className="text-base md:text-lg leading-6 md:leading-9 text-gray-600">
              Ik ben de oprichter van Triple Control en de bedenker van het Triple Control framework. Ondernemer in hart en nieren, voormalig startup-CTO, software engineer, software engineering / infra lead.
              Ik heb een grote passie voor het adviseren van een verscheidenheid aan organisaties (van ministeries tot startups) en ben altijd gedreven door de vraag: Wat is nou de business value? En hoe kunnen we de beste beslissing maken?
              </p>
              <p className="pt-4 text-base md:text-lg leading-6 md:leading-9 text-gray-600">
                Na jarenlang met risk, security en management systemen bezig te zijn geweest en met veel dankbaarheid aan alle klanten waar ik naast hun problemen oplossen ook mijn veldstudie heb kunnen uitvoeren, raak ik nog steeds dagelijks meer overtuigd dat:
                <ul className="pt-4">
                  <li>
                    <i class="fa-solid fa-check"></i>
                    <span className="ml-2">
                    Je meer bereikt als je het met elkaar doet. Iedereen in je organisatie is een expert en heeft waardevolle expertise voor effectief risico management.
                    </span>
                  </li>
                  <li className="pt-4">
                    <i class="fa-solid fa-check"></i>
                    <span className="ml-2">
                    Uitleggen waarom iets zo moet de allerkrachtigste tool is om mensen mee te krijgen.
                    </span>
                  </li>
                </ul>
              </p>
              <p className="pt-4 text-base md:text-lg leading-6 md:leading-9 text-gray-600">
              Behalve werk ben ik dol op klassieke muziek & (buiten) koken.
              </p>
              <ul role="list" className="mt-6 flex gap-x-6">
                <li>
                  <a href="https://www.linkedin.com/in/basdekwant/" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">LinkedIn</span>
                    <LinkedInLogo />
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <img className="aspect-[4/3] w-full rounded-2xl object-cover" src="images/photo-maurice-hartsinck.jpg" alt="Maurice Hartsinck" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Maurice Hartsinck, CIPP/E</h3>
              <p className="text-base leading-7 text-gray-600 pb-6">Partner</p>

              <p className="text-base md:text-lg leading-6 md:leading-9 text-gray-600">
              Maurice is een gedreven professional met een rijke achtergrond als Compliance Officer, CISO, en manager, zowel in de overheid als de commerciële sector. Zo heeft hij veel gemeenten en organisaties in de energiesector geholpen om hun afdelingen te optimaliseren. Met zijn enthousiasme weet hij van werkvloer tot board room iedereen te inspireren. Binnen Triple Control is Maurice dé man voor Business Development, waarin hij zijn passie voor het ontwikkelen van organisaties volledig kwijt kan. Verder is hij gemeenteraadslid en als hij niet vertoeft in een goed boek, kun je hem waarschijnlijk vinden in een Chinees restaurant.
              </p>
              <ul role="list" className="mt-6 flex gap-x-6">
                <li>
                  <a href="https://www.linkedin.com/in/maurice-hartsinck-22aa5a7b/" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">LinkedIn</span>
                    <LinkedInLogo />
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <ul role="list" className="mx-auto mt-20 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {[...teamMembers].sort(() => Math.random() - 0.5).map((member, index) => (
              <li key={index}>
                <img className="aspect-[4/3] w-full rounded-2xl object-cover" src={member.image} alt={member.personName} />
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{member.personName}</h3>
                <p className="text-base leading-7 text-gray-600 pb-6">{member.role}</p>
                {member.linkedIn && (
                  <ul role="list" className="mt-6 flex gap-x-6">
                    <li>
                      <a href={member.linkedInHref} className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <LinkedInLogo />
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <SingleZinOcean
          zin="We werken met partners voor auditing (Q-Resultancy), certificering, pen tests en een flexibele schil van ervaren risk & information security analisten (via Cleverbase) om een passende oplossing te bieden voor onze klanten."
          background="slate"
        />
        <div className="pt-12 md:pt-20">
        <PodcastComponent />
        </div>
      </div>
      <div className="pb-12 md:pb-20">
      <Layers/>
      </div>
    </main>
  )
};

export default WieWeZijn;

