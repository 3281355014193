import React from 'react';
import PageIntro from './PageIntro';

const ResponsibleDisclosure = () => {
  return (
    <main className="flex-grow">
      <PageIntro title="Responsible disclosure" subtitle="Wat te doen als je ziet dat wij iets verkeerd hebben gedaan" />
    <div className="container">
    <div className="mx-auto max-w-4xl lg:mx-0 py-12 lg:pb-24">
      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        Bij Triple Control hechten we veel waarde aan de veiligheid van onze systemen en gegevens. Desondanks kunnen er kwetsbaarheden voorkomen. Mocht je een beveiligingsprobleem of kwetsbaarheid ontdekken, vragen we je vriendelijk dit volgens onderstaande richtlijnen te melden:
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        1. <em>Vermijd schade</em>: Voorkom dat je schade veroorzaakt aan onze systemen, gegevens of gebruikersinformatie. Probeer je bevindingen in een veilige testomgeving te verifiëren.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        2. <em>Vertrouwelijkheid</em>: Deel de bevindingen niet met derden totdat het probleem is opgelost, om de veiligheid van onze gebruikers en systemen te waarborgen.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        3. <em>Geen misbruik</em>: Maak geen gebruik van de kwetsbaarheid voor persoonlijk gewin, zoals toegang tot gegevens, wijzigen van systemen, of het uitvoeren van aanvallen.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        4. <em>Beperkt testen</em>: Test uitsluitend op kwetsbaarheden die je hebt geconstateerd. Voorkom dat je ongeautoriseerde toegang verkrijgt tot systemen, gegevens wijzigt of productieomgevingen verstoort.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        5. <em>Documentatie</em>: Geef een duidelijke en volledige beschrijving van de kwetsbaarheid, inclusief de stappen om deze te reproduceren, zodat we het probleem effectief kunnen onderzoeken en oplossen.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        6. <em>Snel melden</em>: Meld de kwetsbaarheid zo snel mogelijk via info@triple-control.com, met een CC naar bas@triple-control.com met als onderwerp "Responsible disclosure" zodra deze is ontdekt.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        7. <em>Legitimiteit</em>: Houd je aan de wet en voer geen acties uit die in strijd zijn met wet- en regelgeving tijdens je onderzoek.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        8. <em>Oplossing</em>: Geef ons voldoende tijd om de kwetsbaarheid te verhelpen voordat je openbaar maakt dat je de kwetsbaarheid hebt ontdekt.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        9. <em>Geen compensatie</em>: We bieden geen geldelijke beloningen, maar waarderen je hulp. Waar mogelijk, kunnen we je naam vermelden als dank voor je bijdrage.
      </p>

      <p className="leading-6 text-gray-600 pb-4 md:pb-8">
        10. <em>Vertrouwen en veiligheid</em>: Wij beloven geen juridische stappen te ondernemen tegen mensen die kwetsbaarheden melden volgens deze Responsible Disclosure richtlijnen en die te goeder trouw handelen.
      </p>

    </div>
    </div>
    </main>
  );
};

export default ResponsibleDisclosure;
