import React from 'react';

const PodcastComponent = ({ photo }) => {
    return (
        <section className="pb-12 md:pb-20">
            <div className="container">
                <div className="bg-ocean-100 p-10 md:p-20 rounded-xl">
                    <h2 className="text-3xl font-medium with-break text-ocean-800 mb-6">
                        In deze podcast legt Bas uit hoe de Triple Control methode werkt</h2>
                    <div className="aspect-w-16 aspect-h-9">
                        <div className="flex flex-col md:flex-row items-center md:items-start gap-8 mt-8">
                            {photo && (
                                <div className="w-full md:w-1/3">
                                    <img
                                        className="rounded-full w-48 md:w-full max-w-[240px] mx-auto"
                                        src={photo}
                                        alt="Bas de Kwant"
                                    />
                                </div>
                            )}
                            <div className={`w-full ${photo ? 'md:w-2/3' : ''}`}>
                                <iframe
                                    src="https://open.spotify.com/embed/episode/6Zk0zH5zv2timIThooBKmI?utm_source=generator"
                                    width="100%"
                                    height="232"
                                    frameBorder="0"
                                    allowtransparency="true"
                                    allow="encrypted-media"
                                    title="Spotify Podcast Player"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PodcastComponent;