import React from 'react';
import Leader from './home/Leader';
import VisonBlocks from './home/VisionBlocks';
import Testimonials from './home/Testimonials';
import ControlCanvas from './home/ControlCanvas';
import QuoteQuestionBlock from './home/QuoteQuestionBlock';
import Layers from './home/Layers';
import SingleZinOcean from './SingleZinOcean';
import { ServicesComponent } from './diensten/ServicesComponent';
import InteractiveSlider from './InteractiveSlider';

function Home() {
    return(
        <main className="flex-grow">
            <Leader/>
            <QuoteQuestionBlock bg="white" title="Wij helpen ambiteuze organisaties met deze vragen" questions={[
                "We moeten aan allerlei eisen voldoen",
                "(BIO, NIS2, DORA, AVG, ISO 27001), maar hoe houd ik het praktisch?",
                "Hoe is onze informatiebeveiliging georganiseerd?",
                "Nog geen datalek, is dat geluk of wijsheid?",
                "Het is altijd spannend als de auditor komt.",
                "Ik vind het wel belangrijk, naar hoe krijg ik de afdelingen en de directie mee?"
            ] }
            cta={false}/>
            <SingleZinOcean zin="Van deze vragen krijgen wij energie, omdat we geloven in deze drie dingen:" bold={true} background="ocean"/>
            <VisonBlocks/>
            {/* <InteractiveSlider/> */}
            <ControlCanvas/>
            <div id="services">
                <div className="container pb-8 md:pb-12">
                <h2 className="font-medium text-2xl md:text-3xl">Wij zijn partner op jouw <strong>Road to Control&trade;</strong></h2>
                </div>
                <ServicesComponent/>
            </div>
            <Testimonials/>
            <div className="pb-12 md:pb-20">
                <Layers cta={true}/>
            </div>
            {/* <QuoteQuestionBlock title="Zijn dit lastige vragen?" questions={["Weet jij hoe je waardevolle datastromen lopen?", "Hoe werken de processen en systemen?", "Is er een compleet, actueel overzicht van alle bewegende onderdelen?", "Vandaag geen IT-incidenten, is dat geluk of wijsheid?", "Bij groei hoort groeipijn, hoe blijf je jouw IT-landschap de baas?"]} quote={'"Beter hoofdpijn vooraf dan buikpijn achteraf"'} author="Bas de Kwant" authorImage="/images/photo-bas-de-kwant.jpg"/> */}
        </main>
    );
}

export default Home;