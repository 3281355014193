import React from 'react';
const QuoteQuestionBlock = ({
  title,
  questions,
  quote,
  author,
  authorImage = true,
  cta = true,
  bg = 'slate',
  ctaText = "Maak een afspraak"
}) => {
  return (
    <section className={`${bg === 'ocean' ? 'bg-ocean-50' : bg === 'white' ? 'bg-white' : 'bg-slate-50'} py-12 md:py-20`}>
      <div className="container">
        <div className={`grid md:grid-cols-3 gap-6 md:gap-16 items-center ${bg === 'white' ? 'px-0' : 'px-2 md:px-20'}`}>
            {authorImage !== false && (

              <div><img className={`hidden md:block rounded-full w-1/2 md:w-10/12 ${bg !== 'white' ? 'mx-auto' : ''}`} src="/images/photo-bas-de-kwant.jpg" alt="Bas de Kwant: The man - The myth - The legend" /></div>
            )}

          <div className={`${authorImage === false ? 'md:col-span-3' : 'md:col-span-2'} flex flex-col gap-5 text-left`}>
            {title && <h4 className={`font-black text-gray-900 text-2xl md:text-3xl  ${bg === 'ocean' ? 'text-blue-500' : ''}`}>{title}</h4>}
            <p className={`leading-6 md:leading-9 md:text-xl ${bg === 'ocean' ? 'text-blue-500' : 'text-gray-600'}`}>
              {questions && questions.map((question, index) => (
                <React.Fragment key={index}>
                  {question}
                  {index !== questions.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>


            {quote && author && (
              <blockquote className="text-xl font-medium text-gray-900">
                <svg className="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                </svg>
                <p>{quote} <br/>- {author}</p>
              </blockquote>
            )}

            {cta && (
              <div className="flex gap-4 pt-4 lg:pt-8">
                <a href="/contact" className="py-3 px-6 inline-block font-bold rounded hover:shadow text-white bg-ocean-800 hover:bg-ocean-900">{ctaText || "Maak een afspraak"}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuoteQuestionBlock;
