import React from 'react';
import PageIntro from './PageIntro';
import QuoteQuestionBlock from './home/QuoteQuestionBlock';

function Kernwaarden() {
  return (
    <main className="flex-grow">
      <PageIntro
        title="Kernwaarden"
        subtitle="Zeggen waar het op staat, risk management is van iedereen en business value first"
      />

      <div className="container">
        <div className="grid lg:grid-cols-2 gap-8">
          <div className="relative">
            <div className='flex justify-center items-center h-full'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='lg:w-[400px] w-[250px]'>
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" style={{stopColor: "#73b5b4", stopOpacity: 1}} />
                  <stop offset="100%" style={{stopColor: "#253a3c", stopOpacity: 1}} />
                </linearGradient>
              </defs>
            <path fill="url(#gradient)"  d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z"/></svg>
            </div>
          </div>
          <section className="px-6 py-12 lg:py-16">
            <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
              <h2 className="text-base font-medium leading-8 text-blue-500 text-xl">We zijn</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Niet bang om te oordelen</p>
              <p className="mt-6 text-base md:text-lg leading-6 md:leading-9 text-gray-600">Bij Triple Control geloven we dat helderheid de sleutel is tot succes. We zijn open en duidelijk in alles wat we doen, in klare taal vanuit een no-nonsense mentaliteit. Afspraken die we maken zijn begrijpelijk en transparant, en we zorgen er altijd voor dat we ze nakomen. We koppelen wat we doen altijd aan een duidelijk en praktisch doel, zodat iedereen weet waarom iets gedaan moet worden. Dit versterkt niet alleen ons vertrouwen in elkaar, maar maakt ons ook efficiënter en effectiever in ons werk. Transparantie is de sleutel tot samenwerking en succes. </p>

            </div>
          </section>
        </div>
      </div>
      <div className="bg-slate-100 py-12 md:py-12 mb-10 lg:mb-20">
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-8">
          <section className="px-6 py-12 lg:py-16 order-2 lg:order-1">
            <div className="mx-auto max-w-2xl lg:ml-0 lg:max-w-lg">
              <h2 className="text-base font-medium leading-8 text-blue-500 text-xl">We geloven in</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">De kracht van je eigen experts</p>
              <p className="mt-6 text-base md:text-lg leading-6 md:leading-9 text-gray-600">We geloven dat de kracht van Triple Control ligt in de betrokkenheid van onze mensen. Door verantwoordelijkheid te nemen en eigenaarschap te tonen, maken we samen onze organisatie sterker en wendbaarder. Wij zijn ervan overtuigd dat goede compliance lekker moet werken: Keep the fun, work securily. Daarom kijken we altijd eerst naar hoe dan wel, in plaats van hoe niet. Waar elders men het lastig vindt om een oordeel uit te spreken, verwachten we bij ons dat dat natuurlijk gaat. Daarom waarderen en stimuleren we elkaars feedback, zodat we samen een cultuur van betrokkenheid en groei cre&euml;ren.</p>

            </div>
          </section>
          <div className="relative order-1 lg:order-2">
            <div className='flex justify-center items-center h-full'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="w-[250px] lg:w-[400px]">
                <defs>
                  <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{stopColor: "#0077be", stopOpacity: 1}} />
                    <stop offset="100%" style={{stopColor: "#005a8c", stopOpacity: 1}} />
                  </linearGradient>
                </defs>
                <path fill="url(#gradient2)" d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3l0-84.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5l0 21.5c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-26.8C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112l32 0c24 0 46.2 7.5 64.4 20.3zM448 416l0-21.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176l32 0c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2l0 26.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3l0-84.7c-10 11.3-16 26.1-16 42.3zm144-42.3l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2l0 42.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-42.8c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112l32 0c61.9 0 112 50.1 112 112z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="container">
        <div className="grid lg:grid-cols-2 gap-8">
          <div>
          <div className='flex justify-center items-center h-full'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className='w-[250px] lg:w-[400px]'>
          <defs>
                  <linearGradient id="gradient3" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{stopColor: "#d4af37", stopOpacity: 1}} />
                    <stop offset="100%" style={{stopColor: "#b8860b", stopOpacity: 1}} />
                  </linearGradient>
                </defs>
                <path fill="url(#gradient3)" d="M184 48l144 0c4.4 0 8 3.6 8 8l0 40L176 96l0-40c0-4.4 3.6-8 8-8zm-56 8l0 40L64 96C28.7 96 0 124.7 0 160l0 96 192 0 160 0 8.2 0c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7l0-32.7c0-35.3-28.7-64-64-64l-64 0 0-40c0-30.9-25.1-56-56-56L184 0c-30.9 0-56 25.1-56 56zM320 352l-96 0c-17.7 0-32-14.3-32-32l0-32L0 288 0 416c0 35.3 28.7 64 64 64l296.2 0C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16l0 48 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0c-8.8 0-16-7.2-16-16l0-64c0-8.8 7.2-16 16-16z"/></svg>
          </div>
          </div>
          <section className="px-6 py-12 lg:py-16">
            <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
              <h2 className="text-base font-medium leading-8 text-blue-500 text-xl">We snappen</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Dat je organisatie niet om security draait</p>
              <p className="mt-6 text-base md:text-lg leading-6 md:leading-9 text-gray-600">Bij Triple Control streven we ernaar om elke euro die we in risicomanagement en informatiebeveiliging investeren, optimaal te laten renderen. We richten ons op efficiënte en effectieve oplossingen die niet alleen aan de normen voldoen, maar ook echte waarde toevoegen aan onze organisatie. Door slimme keuzes te maken en onze middelen effectief in te zetten, zorgen we ervoor dat we niet alleen compliant zijn, maar ook continu vooruitgang boeken en groeien.</p>

            </div>
          </section>
        </div>
      </div>
      <QuoteQuestionBlock title="We maken het niet ingewikkelder dan het is"  quote={'"80% van in control zijn, is opschrijven wat je doet en doen wat je opschrijft."'} author="Bas de Kwant" authorImage="/images/photo-bas-de-kwant.jpg" bg="ocean" />
    </main>
  );
};

export default Kernwaarden;
