import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home.js';
import Footer from './components/Footer.js';
import Nav from './components/Nav.js';
import SurveyPage from './components/survey/SurveyPage.js';
import Contact from './components/Contact.js'
import Kernwaarden from './components/Kernwaarden.js';
import WieWeZijn from './components/WieWeZijn.js';
import Diensten from './components/Diensten.js';
import WerkenBij from './components/WerkenBij.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import AlgemeneVoorwaarden from './components/AlgemeneVoorwaarden.js';
import ResponsibleDisclosure from './components/ResponsibleDisclosure.js';
import Consultant from './components/vacatures/Consultant.js';
import Advies from './components/diensten/Advies.js';
import CHUB from './components/diensten/CHUB.js';
import SecurityPrivacyOfficer from './components/diensten/SecurityPrivacyOfficer.js';
import ISOimplementatie from './components/diensten/ISOimplementatie.js';
import BioNis2 from './components/diensten/BioNis2.js';
import Workshops from './components/diensten/Workshops.js';
import CookieBot from 'react-cookiebot';

const cookiebotDomainGroupId = 'f4c00c35-80bf-4ea2-a1f3-4099f0e41199';

function App() {
    return (
        <Router>
            <Nav/>
            <div className="flex flex-col min-h-screen">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/onderzoek" element={<SurveyPage />} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="/kernwaarden" element={<Kernwaarden/>} />
                    <Route path="/wie-we-zijn" element={<WieWeZijn/>} />
                    <Route path="/diensten" element={<Diensten/>} />
                    <Route path="/diensten/compliance-risk-governance" element={<Advies/>} />
                    <Route path="/diensten/security-privacy-officer" element={<SecurityPrivacyOfficer/>} />
                    <Route path="/diensten/advies" element={<Advies/>} />
                    <Route path="/vacatures" element={<WerkenBij/>} />
                    <Route path="/privacy" element={<PrivacyPolicy/>} />
                    <Route path="/algemene-voorwaarden" element={<AlgemeneVoorwaarden/>} />
                    <Route path="/responsible-disclosure" element={<ResponsibleDisclosure/>} />
                    <Route path="/c-hub" element={<CHUB/>} />
                    <Route path="/diensten/security-compliance-officer" element={<SecurityPrivacyOfficer/>} />
                    <Route path="/diensten/iso-implementatie" element={<ISOimplementatie/>} />
                    <Route path="/diensten/bio-nis2" element={<BioNis2/>} />
                    <Route path="/diensten/workshops" element={<Workshops/>} />
                    <Route path="/vacatures/consultant" element={<Consultant/>} />
                </Routes>
            </div>
            <Footer/>
            <CookieBot domainGroupId={cookiebotDomainGroupId} />
        </Router>
    );
}

export default App;