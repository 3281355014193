import React from 'react';
import { Link } from 'react-router-dom';

function Leader() {
    return (
        <section className="page-intro overflow-hidden relative py-12 bg-ocean-50 min-h-[25vh] lg:min-h-[40vh] max-h-[420px] flex items-center">
            <div className="relative z-10 w-full">
                <div className="container">
                    <div className="flex flex-col gap-16">
                        <div className="flex flex-col gap-2 text-center md:text-left">
                            <h1 className="text-3xl md:text-5xl font-black text-ocean-700">Van gedoe naar grip</h1>
                            <h2 className="pt-4 text-xl md:text-2xl"><strong>Echte</strong> beheersing met minder moeite, het kan.</h2>
                        </div>
                        <div className="flex gap-4 justify-center md:justify-start">
                            <a href="#services" className="py-3 px-4 md:px-6 inline-block font-medium rounded hover:shadow text-white bg-ocean-600 hover:bg-ocean-700 hidden md:block">Hoe we je kunnen helpen</a>
                            <Link to="/contact" className="py-3 px-4 md:px-6 inline-block font-medium rounded hover:shadow text-black bg-white hover:bg-slate-200">Neem contact op</Link>

                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute z-0 right-0 bottom-0">
                <img className="w-[90vw] lg:w-[60vw] translate-x-1/2 translate-y-1/2" src="/images/graphic-banner.svg" alt="Triple Control"/>
            </div>
        </section>

    );
}

export default Leader;
