import React from 'react';

function PageIntro ({ title, subtitle, subText, breadcrumbs }) {

  if (breadcrumbs) {
    breadcrumbs = [
      { href: "/", title: <i class="fa-solid fa-house"></i> },
      ...breadcrumbs
    ];
  }
  return (
    <section className="page-intro relative py-6 bg-ocean-50 min-h-[160px] md:min-h-[220px] flex items-center">
      <div className="w-full">
        <div className="container">
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-2">
              <h1 className={`text-3xl md:text-4xl text-ocean-700 font-black ${subText || breadcrumbs ? 'mt-[0.5em]' : ''}`}>{title}</h1>
              {subtitle && (
                <h2 className="text-lg md:text-xl text-gray-800 font-medium">{subtitle}</h2>
              )}
            </div>
            {subText && (
              <div className="flex flex-col md:max-w-[66.67%]">
                <p className="text-lg leading-9 text-left text-gray-600">{subText}</p>
              </div>
            )}

            {breadcrumbs && (
              <div className="flex flex-wrap items-center hoi text-sm text-gray-600">
                {breadcrumbs.map((item) => (
                  <React.Fragment key={item.href}>
                    {breadcrumbs.indexOf(item) !== 0 && <i className="fa-solid fa-caret-right pr-1"></i>}
                    <a href={item.href} className="hover:text-forest-500 pr-2">{item.title}</a>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageIntro;
